import React from "react";
import { darkOrLightColor } from "../../../src/helpers/darkOrLightColor";
// import { lightOrDark } from "../../../src/helpers/lightOrBlackColor";
import { table_card_color_background } from "./styles.module.css";
import useCopyToClipboard from "../../../src/hooks/useCopyToClipboard";
import LinkToDetailsColor from "../../../src/components/LinkToDetailsColor";

function TableCardColorBackground({ color, locale }) {
  const [message, showMessage, handleCopy] = useCopyToClipboard(color);

  const textColor = darkOrLightColor(color);

  return (
    <>
      {showMessage ? (
        <div
          style={{
            position: "fixed",
            bottom: "6rem",
            left: "1rem",
            backgroundColor: color,
            padding: "1rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "4px",
          }}
        >
          <strong style={{ color: textColor }}>{message}</strong>
        </div>
      ) : null}
      <td
        className={table_card_color_background}
        style={{ backgroundColor: color }}
      >
        <button
          style={{ color: textColor, border: `1px solid ${textColor}` }}
          onClick={handleCopy}
        >
          {locale === "es"
            ? "Copiar"
            : locale === "en"
            ? "Copy"
            : locale === "pt"
            ? "Copiar"
            : locale === "fr" // Agrega esta línea
            ? "Copier" // Agrega el valor para el idioma francés
            : ""}
        </button>
        <LinkToDetailsColor color={color} />
      </td>
    </>
  );
}

export default TableCardColorBackground;
