import React from "react";
import {
  mini_color_card_wrapper,
  info,
  color_box,
} from "../../styles/modules/mini_color_card.module.css";

function MinColorCard({ color }) {
  return (
    <div className={mini_color_card_wrapper}>
      <div className={color_box} style={{ backgroundColor: color }}></div>
      <div className={info}>{color}</div>
    </div>
  );
}

export default MinColorCard;
