export const esWarnPalettes = [
  {
    palette: ["#FF9F1C", "#FFB238", "#FFC55C", "#FFE3A1", "#FFF1D0"],
    paletteName: "Cálido atardecer",
    paletteDescription:
      "Tonos amarillos, naranjas y rosados inspirados en un atardecer cálido.",
  },

  {
    palette: ["#F94144", "#F3722C", "#F8961E", "#FDC500", "#FFF8E6"],
    paletteName: "Otoño",
    paletteDescription:
      "Tonos naranjas y amarillos con toques de rojo y marrón, inspirados en la estación del otoño.",
  },

  {
    palette: ["#D7BBA8", "#BA8B6E", "#8E563B", "#553E2D", "#2E1E0F"],
    paletteName: "Rústico",
    paletteDescription:
      "Tonos marrones y rojos con toques de gris, inspirados en un ambiente rústico.",
  },

  {
    palette: ["#FF4500", "#FFA07A", "#FFDAB9", "#FFEFD5", "#FFF5EE"],
    paletteName: "Fuego",
    paletteDescription:
      "Tonos anaranjados y rojos que evocan la intensidad del fuego.",
  },

  {
    palette: ["#F4A261", "#E9C46A", "#F4D35E", "#EE964B", "#F95738"],
    paletteName: "Desierto",
    paletteDescription:
      "Tonos amarillos, naranjas y rojos inspirados en el paisaje desértico.",
  },

  {
    palette: ["#A55C3E", "#C98A6F", "#DDBEA9", "#B7A39E", "#8E7D7D"],
    paletteName: "Terracota",
    paletteDescription:
      "Tonos marrones y rojos con toques de beige, inspirados en la arcilla de terracota.",
  },

  {
    palette: ["#ECC8AF", "#D1A67F", "#B08A61", "#7D5A3F", "#443D36"],
    paletteName: "Safari",
    paletteDescription:
      "Tonos marrones y verdes inspirados en el paisaje de la sabana africana.",
  },

  {
    palette: ["#F8B195", "#F67280", "#C06C84", "#6C5B7B", "#355C7D"],
    paletteName: "Tropical",
    paletteDescription:
      "Tonos rosados, rojos y azules inspirados en la flora y fauna tropical.",
  },

  {
    palette: ["#E9C46A", "#F4D35E", "#E5A845", "#D18F3F", "#5E503F"],
    paletteName: "Dorado",
    paletteDescription:
      "Tonos dorados y marrones inspiradosen el brillo del metal precioso.",
  },
];

export const frWarnPalettes = [
  {
    palette: ["#FF9F1C", "#FFB238", "#FFC55C", "#FFE3A1", "#FFF1D0"],
    paletteName: "Coucher de soleil chaud",
    paletteDescription:
      "Nuances de jaune, d'orange et de rose inspirées d'un coucher de soleil chaleureux.",
  },

  {
    palette: ["#F94144", "#F3722C", "#F8961E", "#FDC500", "#FFF8E6"],
    paletteName: "Automne",
    paletteDescription:
      "Nuances d'orange et de jaune avec des touches de rouge et de marron, inspirées de la saison automnale.",
  },

  {
    palette: ["#D7BBA8", "#BA8B6E", "#8E563B", "#553E2D", "#2E1E0F"],
    paletteName: "Rustique",
    paletteDescription:
      "Nuances de marron et de rouge avec des touches de gris, inspirées d'un environnement rustique.",
  },

  {
    palette: ["#FF4500", "#FFA07A", "#FFDAB9", "#FFEFD5", "#FFF5EE"],
    paletteName: "Feu",
    paletteDescription:
      "Nuances d'orange et de rouge évoquant l'intensité du feu.",
  },

  {
    palette: ["#F4A261", "#E9C46A", "#F4D35E", "#EE964B", "#F95738"],
    paletteName: "Désert",
    paletteDescription:
      "Nuances de jaune, d'orange et de rouge inspirées du paysage désertique.",
  },

  {
    palette: ["#A55C3E", "#C98A6F", "#DDBEA9", "#B7A39E", "#8E7D7D"],
    paletteName: "Terre cuite",
    paletteDescription:
      "Nuances de marron et de rouge avec des touches de beige, inspirées de l'argile de terre cuite.",
  },

  {
    palette: ["#ECC8AF", "#D1A67F", "#B08A61", "#7D5A3F", "#443D36"],
    paletteName: "Safari",
    paletteDescription:
      "Nuances de marron et de vert inspirées du paysage de la savane africaine.",
  },

  {
    palette: ["#F8B195", "#F67280", "#C06C84", "#6C5B7B", "#355C7D"],
    paletteName: "Tropical",
    paletteDescription:
      "Nuances de rose, de rouge et de bleu inspirées de la flore et de la faune tropicales.",
  },

  {
    palette: ["#E9C46A", "#F4D35E", "#E5A845", "#D18F3F", "#5E503F"],
    paletteName: "Doré",
    paletteDescription:
      "Nuances de doré et de marron inspirées de l'éclat du métal précieux.",
  },
];
