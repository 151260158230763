import React, { useEffect } from "react";
import chroma from "chroma-js";
import Toolbar from "./Toolbar";
import { palette_container } from "../../styles/modules/utilities.module.css";
// import { convertRenderColor } from "../../helpers/convertRenderColor";
// import { ContextFormatColor } from "../../context/ContextFormatColor";
import Color from "./Color";
import { useState } from "react";
// const maxHslValues = (min, max) => Math.ceil(Math.random() * (max - min) + min);
const colorModes = ["lch", "lab", "hsl", "hsv", "rgb"];

const generateRandomPalette = () => {
  const mode = () => colorModes[Math.floor(Math.random() * colorModes.length)];
  const color1 = chroma.random().alpha(1).hex(mode());
  const color2 = chroma.random().alpha(1).hex(mode());
  const color3 = chroma.random().alpha(1).hex(mode());
  const color4 = chroma.random().alpha(1).hex(mode());
  const color5 = chroma.random().alpha(1).hex(mode());

  return [color1, color2, color3, color4, color5];
};

// const generateHarmonicPalette = () => {
//   const baseColor = chroma.random().alpha(1);
//   const scale = chroma
//     .scale([baseColor.darken(2), baseColor, "white"])
//     .mode("lab");
//   return scale.colors(5).map(c => chroma(c).alpha(1).hex());
// };

export const randomPalette = () => {
  return generateRandomPalette();
};

// const reducer = (palette, action) => {
//   if (action.type === "NEW_PALETTE") {
//     return randomPalette();
//   }

//   return palette;
// };

function GeneratePalette({ path }) {
  // const initialPalette = localStorage.getItem("generatePalette")
  //   ? JSON.parse(localStorage.getItem("generatePalette"))
  //   : [`#7052ff`, `#d06aff`, `#ff81ff`, `#ff7360`, `#ffff51`];

  // const [palette, dispatch] = useReducer(reducer, initialPalette);

  // const { format } = useContext(ContextFormatColor);

  // const colors = palette.map(color =>
  //   convertRenderColor({ color: color }, format)
  // );

  // const newPalette = useCallback(() => {
  //   console.log(initialPalette);
  //   dispatch({ type: "NEW_PALETTE" });
  // }, [dispatch]);
  // const initialPalette = () => {
  //   if (window) {
  //     return JSON.parse(localStorage.getItem("generatePalette"));
  //   } else return [`#7052ff`, `#d06aff`, `#ff81ff`, `#ff7360`, `#ffff51`];
  // };

  const initialPalette = localStorage.getItem("generatePalette")
    ? JSON.parse(localStorage.getItem("generatePalette"))
    : [`#7052ff`, `#d06aff`, `#ff81ff`, `#ff7360`, `#ffff51`];

  const [colors, SetColors] = useState(initialPalette);

  useEffect(() => {
    window.localStorage.setItem("generatePalette", JSON.stringify(colors));
    // SetColors(
    //   JSON.parse(
    //     window.localStorage.setItem("generatePalette", JSON.stringify(colors))
    //   )
    // );
    // console.log(JSON.parse(localStorage.getItem("generatePalette")));
  }, [colors]);

  // useEffect(() => {
  //   SetColors(JSON.parse(localStorage.getItem("generatePalette")));
  //   console.log(colors);
  // }, []);

  const newPalette = () => {
    SetColors(randomPalette());
  };

  // useEffect(() => {
  //   setInitialPalette(JSON.parse(localStorage.getItem("generatePalette")));
  // }, [palette]);
  // console.log([...colors]);

  return (
    <>
      <Toolbar colorPalette={colors} newPalette={newPalette} />

      <div className={palette_container}>
        {colors.map((color, i) => (
          <Color path={path} key={i} color={color} withLinkColor={true} />
        ))}
      </div>
    </>
  );
}

export default GeneratePalette;
