module.exports = [
  {
    id: "509e6280-f97b-5b90-8de5-8908e2b83c5e",
    colorName: "rouge",
    colorHex: "#ff0000",
    slug: "/rouge/",
    imageName: "color-rojo",
  },
  {
    id: "c2d62eed-9ce6-5169-ab4e-3d5a92e3c1b9",
    colorName: "bleu",
    colorHex: "#0000ff",
    slug: "/bleu/",
    imageName: "color-azul",
  },
  {
    id: "fd531d72-acd1-4865-827c-ad8e6dc429a1",
    colorName: "Bleu France",
    colorHex: "#28036a",
    slug: "/bleu/bleu-france/",
    imageName: "color-azul-francia",
  },
  {
    id: "7b1e75a7-ac67-55f8-8c9b-3428e5b2fef7",
    colorName: "vert",
    colorHex: "#00ff00",
    slug: "/vert/",
    imageName: "color-verde",
  },
  {
    id: "d35c196d-cc05-4d11-9947-f7070aec0654",
    colorName: "vert fluo",
    colorHex: "#00ef81",
    slug: "/vert/vert-fluo/",
    imageName: "color-verde-fosforito",
  },
  {
    id: "4fb44c0b-2b46-52a2-bb25-0aec1ddf18fc",
    colorName: "orange",
    colorHex: "#ffa500",
    slug: "/orange/",
    imageName: "color-naranja",
  },
  {
    id: "1a6637bb-4180-50b8-8d50-44ce8c1f5152",
    colorName: "jaune",
    colorHex: "#ffff00",
    slug: "/jaune/",
    imageName: "color-amarillo",
  },
  {
    id: "48dccc58-d5f7-5180-88ee-5e34e7943edb",
    colorName: "violet",
    colorHex: "#a349a4",
    slug: "/violet/",
    imageName: "color-morado",
  },
  {
    id: "735381ba-68c7-4d59-bc77-30c63596991c",
    colorName: "violet pastel",
    colorHex: "#a18594",
    slug: "/violet/violet-pastel/",
    imageName: "color-morado-pastel",
  },
  {
    id: "3361d7b5-f251-5797-8f41-509cfa269a78",
    colorName: "rose",
    secondName: "rose",
    colorHex: "#ffc0cb",
    slug: "/rose/",
    imageName: "color-rosado",
  },
  {
    id: "3d7fd066-9e31-5b3e-8a56-3868e90a44bd",
    colorName: "magenta",
    colorHex: "#ff00ff",
    slug: "/magenta/",
    imageName: "color-magenta",
  },
  {
    id: "737f63f6-30b7-5f01-91b3-b8ce774df79d",
    colorName: "grenat",
    colorHex: "#b03648",
    slug: "/grenat/",
    imageName: "color-granate",
  },
  {
    id: "550d3741-4b50-5396-81eb-a2b97f3768de",
    colorName: "turquoise",
    colorHex: "#40e0d0",
    slug: "/turquoise/",
    imageName: "color-turquesa",
  },
  {
    id: "1d2d0da4-0b47-57e7-9fed-c91f5db1e20c",
    colorName: "cyan",
    colorHex: "#00ffff",
    slug: "/cyan/",
    imageName: "color-cian",
  },
  {
    id: "6292f811-bd04-5ee2-8c07-e81356de602c",
    colorName: "lilas",
    colorHex: "#b695c0",
    slug: "/lilas/",
    imageName: "color-lila",
  },
  {
    id: "43bc2b35-1421-5d82-9f72-8d512e1b87b1",
    colorName: "marron",
    secondName: "café",
    colorHex: "#8d4925",
    slug: "/marron/",
    imageName: "color-marron",
  },
  {
    id: "546bf6ff-4ebc-5280-900e-d779d996e2e0",
    colorName: "bronze",
    colorHex: "#cd7f32",
    slug: "/bronze/",
    imageName: "color-bronce",
  },
  {
    id: "fde886d5-c681-56a8-bc94-6252192cb619",
    colorName: "cuivre",
    colorHex: "#cb6d51",
    slug: "/cuivre/",
    imageName: "color-cobre",
  },
  {
    id: "4d290bce-8b2a-59fe-839f-cd855fb42a27",
    colorName: "violet",
    colorHex: "#78288c",
    slug: "/violet/",
    imageName: "color-violeta",
  },
  {
    id: "cb0afa41-f205-5e98-9daf-2581985c19c2",
    colorName: "ocre",
    colorHex: "#b9935a",
    slug: "/ocre/",
    imageName: "color-ocre",
  },
  {
    id: "c9195510-89f8-50ae-8694-ef9f5aa5bf96",
    colorName: "carmin",
    colorHex: "#960018",
    slug: "/rouge/carmin/",
    imageName: "color-carmin",
  },
  {
    id: "97f68db2-404f-599d-9507-6b02763d2a55",
    colorName: "amarante",
    colorHex: "#e52b50",
    slug: "/rouge/amarante/",
    imageName: "color-amaranto",
  },
  {
    id: "850d99f9-a3f4-58ac-ba74-0e2c5196d590",
    colorName: "bourgogne",
    colorHex: "#96305a",
    slug: "/rouge/bourgogne/",
    imageName: "color-burdeos",
  },
  {
    id: "8e14ee5d-6f3a-5c08-af3f-1402deea8874",
    colorName: "écarlate",
    colorHex: "#e30032",
    slug: "/rouge/ecarlate/",
    imageName: "color-escarlata",
  },
  {
    id: "0a02b860-bc37-5b88-8924-b62b6e28ea6a",
    colorName: "gris",
    colorHex: "#808080",
    slug: "/gris/",
    imageName: "color-gris",
  },
  {
    id: "f6f928a8-48ed-56ff-a025-f7b7f531f864",
    colorName: "rouge foncé",
    colorHex: "#8b0000",
    slug: "/rouge/rouge-fonce/",
    imageName: "color-rojo-oscuro",
  },
  {
    id: "7323dfa3-c446-5970-b36a-bdcbbc565d78",
    colorName: "cramoisi",
    secondName: "crimson",
    colorHex: "#dc143c",
    slug: "/rouge/cramoisi/",
    imageName: "color-carmesi",
  },
  {
    id: "25ead9e8-28df-5e8f-8956-8b0f7a9f1801",
    colorName: "vin",
    secondName: "vin rouge",
    colorHex: "#56070c",
    slug: "/rouge/vin/",
    imageName: "color-vino",
  },
  {
    id: "792048a4-82a9-5710-8c49-87153bf573e0",
    colorName: "corail",
    colorHex: "#e4717a",
    slug: "/rouge/corail/",
    imageName: "color-coral",
  },
  {
    id: "03105137-c55a-5f74-a9e0-f5ebe6ecb394",
    colorName: "bourgogne",
    colorHex: "#673147",
    slug: "/rouge/bourgogne/",
    imageName: "color-borgoña",
  },
  {
    id: "34fffb2d-eff3-5ad9-8206-d73b588d3d74",
    colorName: "caoba",
    colorHex: "#a44850",
    slug: "/rouge/caoba/",
    imageName: "color-caoba",
  },
  {
    id: "a071d6f4-370d-5d9f-813e-78bc1c6b803c",
    colorName: "céleste",
    secondName: "bleu ciel",
    colorHex: "#0cb7f2",
    slug: "/bleu/celeste/",
    imageName: "color-celeste",
  },
  {
    id: "8c37bcdf-40ba-54e8-9082-dbe39dbf18e4",
    colorName: "bleu pétrole",
    colorHex: "#012e46",
    slug: "/bleu/bleu-petrole/",
    imageName: "color-petroleo",
  },
  {
    id: "2001a1d6-6ef0-5af9-992d-08c98974e25b",
    colorName: "bleu marine",
    colorHex: "#252440",
    slug: "/bleu/bleu-marine/",
    imageName: "color-azul-marino",
  },
  {
    id: "8a29c62c-02ed-56f8-94c5-9edc8196dadd",
    colorName: "bleu grisâtre",
    colorHex: "#44556f",
    slug: "/bleu/bleu-grisatre/",
    imageName: "color-azul-grisaceo",
  },
  {
    id: "d06b4fb4-a875-58b0-938c-004a74b51398",
    colorName: "bleu vert",
    secondName: "vert bleuté",
    colorHex: "#009b94",
    slug: "/bleu/bleu-vert/",
    imageName: "color-azul-verde",
  },
  {
    id: "6e846e7f-3481-5990-b5a1-e64103a11542",
    colorName: "bleu pastel",
    colorHex: "#b2dafa",
    slug: "/bleu/bleu-pastel/",
    imageName: "color-azul-pastel",
  },
  {
    id: "e869e1a7-eaeb-596e-99ae-750935edc0e5",
    colorName: "bleu turquoise",
    colorHex: "#03a7bb",
    slug: "/bleu/bleu-turquoise/",
    imageName: "color-azul-aguamarina",
  },
  {
    id: "0fe1f4c4-ca29-57e0-b791-5915c5c8cc2f",
    colorName: "bleu électrique",
    colorHex: "#014ba0",
    slug: "/bleu/bleu-electrique/",
    imageName: "color-azul-electrico",
  },
  {
    id: "c26bb6f9-9168-54fd-a6f9-462c0da674ed",
    colorName: "bleu cobalt",
    colorHex: "#263065",
    slug: "/bleu/cobalt/",
    imageName: "color-azul-cobalto",
  },
  {
    id: "6adae103-ea8d-5d01-bb7a-39991c839337",
    colorName: "vert pistache",
    colorHex: "#93c572",
    slug: "/vert/pistache/",
    imageName: "color-verde-pistacho",
  },
  {
    id: "451a7af1-c698-5897-8ac8-71ef9e9b4963",
    colorName: "vert pastel",
    colorHex: "#b0f2c2",
    slug: "/vert/pastel/",
    imageName: "color-verde-pastel",
  },
  {
    id: "b670123b-9c9e-5d0f-bff9-f2a7b61acc93",
    colorName: "menthe",
    colorHex: "#9bfab0",
    slug: "/vert/menthe/",
    imageName: "color-menta",
  },
  {
    id: "5cf08d5c-403f-5d99-be6c-ba99138505f6",
    colorName: "vert d'eau",
    colorHex: "#03bb85",
    slug: "/vert/vert-d'eau/",
    imageName: "color-verde-agua",
  },
  {
    id: "4441e504-0c00-587d-a716-26aa33164276",
    colorName: "vert émeraude",
    colorHex: "#287233",
    slug: "/vert/vert-emeraude/",
    imageName: "color-verde-esmeralda",
  },
  {
    id: "81f1a16d-dd6c-5d2c-ab9c-624e50984b38",
    colorName: "jade",
    secondName: "vert jade",
    colorHex: "#00a86b",
    slug: "/vert/jade/",
    imageName: "color-verde-jade",
  },
  {
    id: "f6e2a2d2-d472-5611-adfd-eb5dd5415d0a",
    colorName: "vert pomme",
    colorHex: "#8db600",
    slug: "/vert/vert-pomme/",
    imageName: "color-verde-manzana",
  },
  {
    id: "d5680747-d901-5814-8f2f-770d5eaf6afb",
    colorName: "vert olive",
    colorHex: "#86895d",
    slug: "/vert/vert-olive/",
    imageName: "color-verde-oliva",
  },
  {
    id: "f8bde82e-8e73-55ab-a203-0c22bb33e9c5",
    colorName: "vert citron",
    colorHex: "#bfff00",
    slug: "/vert/vert-citron/",
    imageName: "color-verde-limon",
  },
  {
    id: "704c5562-0181-5bb5-b472-81c160b8ba58",
    colorName: "orange pastel",
    colorHex: "#ffb347",
    slug: "/orange/orange-pastel/",
    imageName: "color-naranja-pastel",
  },
  {
    id: "1a715649-f819-5ef0-98ca-801182a98f7b",
    colorName: "orange fluorescent",
    secondName: "orange néon",
    colorHex: "#fc4b08",
    slug: "/orange/orange-fluorescent/",
    imageName: "color-naranja-fluorescente",
  },
  {
    id: "068c59d9-5f31-5681-8dea-ec9e38a487bc",
    colorName: "mandarine",
    colorHex: "#f28500",
    slug: "/orange/mandarine/",
    imageName: "color-mandarina",
  },
  {
    id: "b621fc4e-3ee7-5fd6-a57d-e241b638cd9d",
    colorName: "orange Pantone",
    colorHex: "#fe5000",
    slug: "/orange/orange-pantone/",
    imageName: "color-naranja-pantone",
  },
  {
    id: "e18f0601-2884-5bfe-89c3-8f38b469db3f",
    colorName: "mangue",
    colorHex: "#ff8243",
    slug: "/orange/mangue/",
    imageName: "color-mango",
  },
  {
    id: "b58141c4-f5c1-5d55-b016-13df7b22407d",
    colorName: "orange clair",
    colorHex: "#ffb552",
    slug: "/orange/orange-clair/",
    imageName: "color-naranja-claro",
  },
  {
    id: "b79c99b0-4e5b-5e3d-86a4-8f9961ee372e",
    colorName: "cannelle",
    colorHex: "#d2691e",
    slug: "/orange/cannelle/",
    imageName: "color-canela",
  },
  {
    id: "38da12bc-a1a9-542f-8e51-642a428bac13",
    colorName: "cerise",
    colorHex: "#952f57",
    slug: "/rouge/cerise/",
    imageName: "color-guinda",
  },
  {
    id: "c9518671-2bbb-5eff-aca2-b8e9ba47b537",
    colorName: "bleu royal",
    colorHex: "#0833a2",
    slug: "/bleu/bleu-royal/",
    imageName: "color-azul-rey",
  },
  {
    id: "725064d3-604c-567d-a27f-aaf0a0711875",
    colorName: "doré",
    secondName: "or",
    colorHex: "#efb810",
    slug: "/jaune/dore/",
    imageName: "color-dorado",
  },
  {
    id: "c829791f-af0e-5f42-9a07-8f15c928b8c3",
    colorName: "orange foncé",
    colorHex: "#ca6212",
    slug: "/orange/orange-fonce/",
    imageName: "color-naranja-oscuro",
  },
  {
    id: "1f1da459-cc0e-5dff-8c89-c0a6cc28e31c",
    colorName: "saumon",
    colorHex: "#fa8072",
    slug: "/orange/saumon/",
    imageName: "color-salmon",
  },
  {
    id: "6522155b-fdee-5050-9a3b-e7e11f1fa70a",
    colorName: "pêche",
    colorHex: "#f7c59f",
    slug: "/orange/peche/",
    imageName: "color-melocoton",
  },
  {
    id: "e8180db0-70e3-578d-9f97-b0f8abb0ae5e",
    colorName: "kaki",
    colorHex: "#dfc38a",
    slug: "/jaune/kaki/",
    imageName: "color-caqui",
  },
  {
    id: "c07dcc4f-e943-51b5-ae96-a098d71c0c68",
    colorName: "jaune pastel",
    colorHex: "#fdfd96",
    slug: "/jaune/pastel/",
    imageName: "color-amarillo-pastel",
  },
  {
    id: "6d7e63d7-d8fc-51b4-b4d5-386946347a91",
    colorName: "jaune canari",
    colorHex: "#f7e91e",
    slug: "/jaune/canari/",
    imageName: "color-canario",
  },
  {
    id: "259c79c0-7956-52cf-accf-73a1fe1f845b",
    colorName: "moutarde",
    colorHex: "#f6a700",
    slug: "/jaune/moutarde/",
    imageName: "color-mostaza",
  },
  {
    id: "6d7d3dac-cbac-5825-81c7-71f6c6b1a038",
    colorName: "crème",
    colorHex: "#fff0c9",
    slug: "/jaune/creme/",
    imageName: "color-crema",
  },
  {
    id: "8f1d0fea-2c55-5d58-bf07-95b3e4e1312b",
    colorName: "ambre",
    colorHex: "#ffbf00",
    slug: "/jaune/ambre/",
    imageName: "color-ambar",
  },
  {
    id: "8f6492ad-8e10-5b28-9ed4-aaeddd3982c3",
    colorName: "rose pâle",
    colorHex: "#ffe3e8",
    slug: "/rose/rose-pale/",
    imageName: "color-rosa-palo",
  },
  {
    id: "7c4757ff-28f3-5900-a95b-84aeac479a65",
    colorName: "vanille",
    colorHex: "#f3e5ab",
    slug: "/jaune/vanille/",
    imageName: "color-vainilla",
  },
  {
    id: "c821e1d9-c2c4-5c1b-81b1-f1a5be0bf688",
    colorName: "rose pâle",
    colorHex: "#f7ddda",
    slug: "/rose/rose-pale/",
  },
  {
    id: "121f9748-8c64-563c-967d-2751b8471e4b",
    colorName: "rose clair",
    colorHex: "#f7cbf7",
    slug: "/rose/rose-clair/",
  },
  {
    id: "a78b5d49-6964-5c76-9304-e4c7dae8dd86",
    colorName: "rose pastel",
    colorHex: "#fdcae1",
    slug: "/rose/rose-pastel/",
  },
  {
    id: "a398e52a-1643-59cf-bdc7-f8e3c336c68a",
    colorName: "fuchsia",
    colorHex: "#FF0080",
    slug: "/magenta/fuchsia/",
  },
  {
    id: "c0c1da9b-5968-5b27-8c4e-a015075e513b",
    colorName: "wengé",
    colorHex: "#3D2E2C",
    slug: "/marron/wenge/",
  },
  {
    id: "95d087ce-d23b-5df6-9166-cae5d43a3eb3",
    colorName: "terracotta",
    colorHex: "#c89c7d",
    slug: "/marron/terracotta/",
  },
  {
    id: "d0b84c8c-4014-5449-80ae-16cc3b993a72",
    colorName: "beige",
    secondName: "crème",
    colorHex: "#E8C39E",
    slug: "/marron/beige/",
  },
  {
    id: "173869f2-0883-4a46-9f85-a49433a5357d",
    colorName: "caramel",
    colorHex: "#AE6938",
    slug: "/marron/caramel/",
  },
  {
    id: "11affb40-a4c8-5ed9-b13b-2ad0c58e9fc0",
    colorName: "raisin",
    colorHex: "#6F2DA8",
    slug: "/violet/raisin/",
  },
  {
    id: "0f1ac964-749c-53fa-9896-ec408fdf2463",
    colorName: "pourpre",
    colorHex: "#7D2181",
    slug: "/violet/pourpre/",
  },
  {
    id: "50c294cd-f610-57f6-b699-5091af7dfa36",
    colorName: "mauve",
    colorHex: "#E0B0FF",
    slug: "/violet/mauve/",
  },
  {
    id: "1fdce09e-1dfd-507b-86e0-0e97a9f829a3",
    colorName: "lavande",
    colorHex: "#B57EDC",
    slug: "/violet/lavande/",
  },
  {
    id: "1f9f7c00-e856-5fae-97cb-34fe51c48374",
    colorName: "prune",
    colorHex: "#57354d",
    slug: "/violet/prune/",
  },
  {
    id: "82bac666-4e44-5569-9859-bab630ba6c4f",
    colorName: "gris argenté",
    colorHex: "#C0C0C0",
    slug: "/gris/argente/",
  },
  {
    id: "22e38eb2-c4ab-5256-a375-0dca01fe3a6a",
    colorName: "orchidée",
    colorHex: "#57354d",
    slug: "/violet/orchidee/",
  },
  {
    id: "cd6765cb-01bb-50fd-bcf4-a7619bc1413c",
    colorName: "bleu indigo",
    colorHex: "#1C4C96",
    slug: "/bleu/indigo/",
  },
  {
    id: "c3f4db1c-aa2f-5a46-b45d-f18cf7fb3163",
    colorName: "bleu ciel pastel",
    secondName: "ciel pastel",
    colorHex: "#c7f5f5",
    slug: "/bleu/celeste/ciel-pastel/",
  },
  {
    id: "e87f3471-6802-5e73-b9b1-d683dac4c0a6",
    colorName: "jasmin",
    colorHex: "#F8DE7E",
    slug: "/jaune/jasmin/",
  },
  {
    id: "5ea24a98-b369-4489-a19a-579e365873d0",
    colorName: "vert caraïbe",
    colorHex: "#14dcb4",
    slug: "/vert/vert-caraibe/",
  },
  {
    id: "1278aa33-aaf2-4c22-bc62-96d800be69d0",
    colorName: "kiwi",
    colorHex: "#ABBA3B",
    slug: "/vert/kiwi/",
  },
  {
    id: "e776ec14-b470-44fd-98ba-370b1563d389",
    colorName: "noir",
    colorHex: "#000000",
    slug: "/noir/",
  },
  {
    id: "27bc7943-0296-47e1-a3ac-5311bd8582ea",
    colorName: "blanc",
    colorHex: "#ffffff",
    slug: "/blanc/",
  },
  {
    id: "683db77f-318f-489f-b806-261ba1cb67b3",
    colorName: "blanc nacré",
    colorHex: "#eae6ca",
    slug: "/blanc/blanc-nacre/",
  },
  {
    id: "9a54d77c-8997-43bb-bf3c-08721aa90e91",
    colorName: "gris ciment",
    colorHex: "#7d8471",
    slug: "/gris/gris-ciment/",
  },
  {
    id: "95aba880-ac80-4aab-bb48-cc7b9297dc47",
    colorName: "gris clair",
    colorHex: "#D3D3D3",
    slug: "/gris/gris-clair/",
  },
  {
    id: "dec85eb3-202a-4f05-98be-4021594fc5d2",
    colorName: "gris foncé",
    colorHex: "#2e2e2e",
    slug: "/gris/gris-fonce/",
  },
  {
    id: "79ecd335-e37e-45bd-ad4a-da2ed1e38355",
    colorName: "gris Oxford",
    colorHex: "#393d42",
    slug: "/gris/gris-Oxford/",
  },
  {
    id: "25a5c909-cb30-4aba-9d37-0663f42e2f8c",
    colorName: "rouge pastel",
    colorHex: "#FF6961",
    slug: "/rouge/rouge-pastel/",
  },
  {
    id: "0b62947f-f0cb-4454-8e81-37793c4b8b6a",
    colorName: "kalua",
    colorHex: "#b78e5b",
    slug: "/marron/kalua/",
  },
  {
    id: "9ecbd119-d9ad-416a-8b54-6d324703f84f",
    colorName: "jaune néon",
    secondName: "jaune fluorescent",
    colorHex: "#fff50a",
    slug: "/jaune/jaune-neon/",
  },
  {
    id: "ea9cd9c6-9aec-4a77-8e5a-fa728b59e489",
    colorName: "vert fluorescent",
    secondName: "vert néon",
    colorHex: "#00ff40",
    slug: "/vert/vert-fluorescent/",
  },
  {
    id: "eef5cc90-4b9d-46c5-81bc-90783e6f53f2",
    colorName: "turquoise pâle",
    colorHex: "#afeeee",
    slug: "/turquoise/turquoise-pale/",
  },
  {
    id: "9f6045ef-ec49-43f0-9b2d-53f370d976fa",
    colorName: "brique",
    secondName: "firebrick",
    colorHex: "#b22222",
    slug: "/rouge/rouge-brique/",
  },
  {
    id: "f9d35613-553b-4cb8-b7f3-adca1bedc102",
    colorName: "turquoise foncé",
    secondName: "dark turquoise",
    colorHex: "#00ced1",
    slug: "/turquoise/turquoise-fonce/",
  },
  {
    id: "aa4d9983-402a-41c5-ac6b-7f449f0801d1",
    colorName: "razzmatazz",
    colorHex: "#e3256b",
    slug: "/rose/razzmatazz/",
  },
  {
    id: "2356d08a-6c80-4980-81d8-dba2f8699612",
    colorName: "rose vif",
    colorHex: "#b20256",
    slug: "/rose/rose-vif/",
  },
  {
    id: "ab947e73-6ac6-4737-9e98-f511e1ab3d28",
    colorName: "bleu Capri",
    colorHex: "#1b5583",
    slug: "/bleu/bleu-Capri/",
  },
  {
    id: "fa6738e2-6e96-4b79-85d3-7ecbbd60e438",
    colorName: "rose fort",
    colorHex: "#b20256",
    slug: "/rose/rose-fort/",
  },
  {
    id: "c716f209-052f-42c0-9b93-a9badea9e201",
    colorName: "cccccc",
    colorHex: "#cccccc",
    slug: "/cccccc/",
  },
  {
    id: "94dc6e86-a7b4-4609-acd7-d98275bb74a9",
    colorName: "melon",
    colorHex: "#fbca7f",
    slug: "/jaune/melon/",
  },
];
