import React from "react";
import tinycolor from "tinycolor2";
import Colors from "../../../../src/components/PageColorPalette/Colors";
import chroma from "chroma-js";
import { analogousScheme } from "../../../../src/helpers/generateColorsSchemas";

function SectionColorSchemesAndShades({ colorName, colorHex, locale }) {
  const generateShade = (color, secondColor) =>
    chroma.scale([color, secondColor]).colors(6);

  const analogousColors = analogousScheme(colorHex, true);
  const triadicColors = tinycolor(colorHex)
    .triad()
    .map(t => t.toHexString());
  const tetradColors = tinycolor(colorHex)
    .tetrad()
    .map(t => t.toHexString());
  const splitComplement = tinycolor(colorHex)
    .splitcomplement()
    .map(t => t.toHexString());
  const shadeColors = generateShade(colorHex, "#000000");
  const tintsColors = generateShade(colorHex, "#ffffff");

  // {locale === "es"
  // ? ``
  // : locale === "en"
  // ? ``
  // : locale === "pt"
  // ? ``
  // : ""}

  return (
    <>
      <div>
        <h2 className="heading_color_page">
          {locale === "es"
            ? `Esquema análogo del color ${colorName}`
            : locale === "en"
            ? `Analogous scheme of the color ${colorName}`
            : locale === "pt"
            ? `Esquema análogo da cor ${colorName}`
            : locale === "fr"
            ? `Schéma analogique de la couleur ${colorName}`
            : ""}
        </h2>
        <p style={{ fontSize: "small" }}>
          {locale === "es"
            ? `Los colores análogos se obtienen a partir de los dos colores más cercanos dentro de el circulo cromático`
            : locale === "en"
            ? `Analogous colors are obtained from the two closest colors within the color wheel.`
            : locale === "pt"
            ? `As cores análogas são obtidas a partir das duas cores mais próximas dentro do círculo cromático.`
            : locale === "fr"
            ? `Les couleurs analogues sont obtenues à partir des deux couleurs les plus proches dans le cercle chromatique`
            : ""}
        </p>
        <Colors withLinkColor={false} palette={analogousColors} />
      </div>
      <div>
        <h2 className="heading_color_page">
          {locale === "es"
            ? `Esquema tríada del color ${colorName}`
            : locale === "en"
            ? `Triadic scheme of the color ${colorName}`
            : locale === "pt"
            ? `Esquema triádico da cor ${colorName}`
            : locale === "fr"
            ? `Schéma triadique de la couleur ${colorName}`
            : ""}
        </h2>
        <p style={{ fontSize: "small" }}>
          {locale === "es"
            ? `El esquema tríadico los obtenemos a partir de 3 colores igualmente separados entre sí dentro del circulo cromático`
            : locale === "en"
            ? `The triadic scheme is obtained from 3 colors equally spaced from each other within the color wheel`
            : locale === "pt"
            ? `O esquema tríadico é obtido a partir de 3 cores igualmente espaçadas entre si dentro do círculo cromático.`
            : locale === "fr"
            ? `Le schéma triadique est obtenu à partir de 3 couleurs également espacées les unes des autres dans le cercle chromatique.`
            : ""}
        </p>
        <Colors withLinkColor={false} palette={triadicColors} />
      </div>
      <div>
        <h2 className="heading_color_page">
          {locale === "es"
            ? `Esquema tetrádico del color ${colorName}`
            : locale === "en"
            ? `Tetradic scheme of the color ${colorName}`
            : locale === "pt"
            ? `Esquema tetrádico da cor ${colorName}`
            : locale === "fr"
            ? `Schéma tétradique de la couleur ${colorName}.`
            : ""}
        </h2>
        <p style={{ fontSize: "small" }}>
          {locale === "es"
            ? `Este esquema de color se obtiene usando dos pares de colores opuestos en el circulo cromático en forma rectangular.`
            : locale === "en"
            ? `This color scheme is obtained by using two pairs of opposing colors on the color wheel in a rectangular shape.`
            : locale === "pt"
            ? `Este esquema de cores é obtido usando dois pares de cores opostas no círculo cromático, formando um arranjo retangular.`
            : locale === "fr"
            ? `Ce schéma de couleur est obtenu en utilisant deux paires de couleurs opposées dans le cercle chromatique de manière rectangulaire.`
            : ""}
        </p>
        <Colors withLinkColor={false} palette={tetradColors} />
      </div>
      <div>
        <h2 className="heading_color_page">
          {locale === "es"
            ? `Esquema complementario dividido del color ${colorName}`
            : locale === "en"
            ? `Split complementary scheme of the color ${colorName}`
            : locale === "pt"
            ? `Esquema complementar dividido da cor ${colorName}`
            : locale === "fr"
            ? `Schéma complémentaire divisé de la couleur ${colorName}.`
            : ""}
        </h2>
        <p style={{ fontSize: "small" }}>
          {locale === "es"
            ? `Este esquema de color se obtiene usando dos pares de colores opuestos en el circulo cromático en forma rectangular.`
            : locale === "en"
            ? `This color scheme is obtained by using two pairs of opposing colors on the color wheel in a rectangular shape.`
            : locale === "pt"
            ? `Este esquema de cores é obtido usando dois pares de cores opostas no círculo cromático, formando uma disposição retangular.`
            : locale === "fr"
            ? `Ce schéma de couleur est obtenu en utilisant deux paires de couleurs opposées dans le cercle chromatique de manière rectangulaire.`
            : ""}
        </p>
        <Colors withLinkColor={false} palette={splitComplement} />
      </div>
      <div>
        <h2 className="heading_color_page">
          {" "}
          {locale === "es"
            ? `Tintes del color ${colorName}`
            : locale === "en"
            ? `Tints of the color ${colorName}`
            : locale === "pt"
            ? `Tons da cor ${colorName}`
            : locale === "fr"
            ? `Teintes de la couleur ${colorName}.`
            : ""}{" "}
        </h2>
        <p style={{ fontSize: "small" }}>
          {locale === "es"
            ? `Los tintes los obtenemos a partir del color ${colorName} como base hasta llegar al blanco encontrando en medio tonos de colores pastel`
            : locale === "en"
            ? `Tints are obtained from the base color ${colorName} up to reaching white, finding in between shades of pastel colors.`
            : locale === "pt"
            ? `Os tons são obtidos a partir da cor base ${colorName} até chegar ao branco, encontrando tons intermediários de cores pastel.`
            : locale === "fr"
            ? `Les teintes sont obtenues à partir de la couleur ${colorName} comme base jusqu'à atteindre le blanc, en trouvant au milieu des tons de couleurs pastel.`
            : ""}
        </p>

        <Colors withLinkColor={false} palette={tintsColors} />
      </div>
      <div>
        <h2 className="heading_color_page">
          {" "}
          {locale === "es"
            ? `Sombras del color ${colorName}`
            : locale === "en"
            ? `Shades of the color ${colorName}`
            : locale === "pt"
            ? `Sombras da cor ${colorName}`
            : locale === "fr"
            ? `Ombres de la couleur ${colorName}`
            : ""}
        </h2>
        <p style={{ fontSize: "small" }}>
          {locale === "es"
            ? `Estas sombras se obtienen a partir del color ${colorName} como base hasta llegar al negro.`
            : locale === "en"
            ? `These shades are obtained from the base color ${colorName} up to reaching black.`
            : locale === "pt"
            ? `Essas sombras são obtidas a partir da cor base ${colorName} até chegar ao preto`
            : locale === "fr"
            ? `Ces ombres sont obtenues à partir de la couleur ${colorName} comme base jusqu'à atteindre le noir.`
            : ""}
        </p>
        <Colors withLinkColor={false} palette={shadeColors} />
      </div>
    </>
  );
}

export default SectionColorSchemesAndShades;
