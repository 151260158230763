import { useEffect, useRef, useState } from "react";

function useAdManaSlotInView({ path, size, id }) {
  const [render, setRender] = useState(false);

  const container = useRef(null);

  useEffect(() => {
    const adObserver = new IntersectionObserver(onAdIntersection, {
      rootMargin: "0px 0px",
      threshold: 0.1,
    });

    function onAdIntersection(entries) {
      if (!entries || entries.length <= 0) {
        return;
      }

      if (entries[0].isIntersecting) {
        setRender(true);
        setTimeout(() => {
          const googletag = window.googletag || {};
          googletag.cmd = googletag.cmd || [];

          googletag.cmd.push(function () {
            let mapping = googletag
              .sizeMapping()
              .addSize(
                [1024, 768],
                [
                  [970, 250],
                  [728, 250],
                  [970, 90],
                  [728, 90],
                ]
              )
              .addSize([640, 480], [300, 250])
              .addSize(
                [0, 0],
                [
                  [300, 250],
                  [300, 75],
                ]
              )
              .build();

            googletag
              .defineSlot(path, size, id)
              .addService(googletag.pubads())
              .defineSizeMapping(mapping);

            googletag.pubads().enableSingleRequest();
            googletag.enableServices();
          });

          googletag.cmd.push(function () {
            googletag.display(id);
          });
        }, 500);
        adObserver.disconnect();
      }
    }

    if (window && "IntersectionObserver" in window) {
      if (container && container.current) {
        adObserver.observe(container.current);
      }
    }
  }, [container, path, size, id]);

  return [container, render];
}

export default useAdManaSlotInView;
