import React from "react";
import loadable from "@loadable/component";
// import GeneratePalette from '../../../src/components/GeneratePalette/GeneratePalette';
import Spinner from "../../../src/components/Spinner";

const GeneratePalette = loadable(
  () => import("../../../src/components/GeneratePalette/GeneratePalette"),
  {
    fallback: <Spinner spinnerGeneratePalette={true} />,
  }
);

function GeneratePalettePage() {
  return <GeneratePalette />;
}

export default GeneratePalettePage;
