import React from "react";
import { ad_wrapper } from "./styles.module.css";
import useAdManaSlotInView from "../../hooks/useAdManaSlotInView";

function AdManaWithObserver({
  path = "/22250597679/ptdc_banner_bottom",
  size = [
    [300, 250],
    [970, 250],
    [728, 250],
    [970, 90],
    [728, 90],
  ],
  id = "ptdc_banner_bottom",
}) {
  const [container, render] = useAdManaSlotInView({
    path: path,
    size: size,
    id: id,
  });

  return (
    <div ref={container} className={ad_wrapper}>
      {render ? (
        <div
          id={id}
          style={{ minWidth: "300px", minHeight: "90px", textAlign: "center" }}
        ></div>
      ) : null}
    </div>
  );
}

export default AdManaWithObserver;

{
  /* <AdManaWithObserver
  path="/22250597679/wortise_header"
  size={[
    [990, 90],
    [300, 250],
    [728, 90],
    [970, 90],
    [970, 250],
    [980, 90],
  ]}
  id="wortise_header"
/> */
}
