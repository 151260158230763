export const esAnimePalettes = [
  {
    palette: ["#F4D1AE", "#E8A565", "#E65353", "#66101F", "#1B2021"],
    paletteName: "Sakura",
    paletteDescription:
      "Esta paleta se inspira en la belleza de las flores de cerezo, con tonos cálidos y suaves.",
  },

  {
    palette: ["#B7D9F9", "#2E3192", "#F5A962", "#FCA311", "#FFFFFF"],
    paletteName: "Galaxia",
    paletteDescription:
      "Esta paleta evoca la majestuosidad del espacio y las galaxias, con colores azules y anaranjados.",
  },
  {
    palette: ["#D2FFB5", "#A5F2D9", "#8D99AE", "#514E80", "#1B2021"],
    paletteName: "Enchanted Forest",
    paletteDescription:
      "Esta paleta representa un bosque encantado, con tonos verdes y morados profundos.",
  },
  {
    palette: ["#F8AFA6", "#F9D5E5", "#C8B1B7", "#67597A", "#1B2021"],
    paletteName: "Vintage Romance",
    paletteDescription:
      "Esta paleta evoca una sensación de romanticismo y nostalgia, con tonos rosados y lavanda.",
  },

  {
    palette: ["#E8E8E8", "#F1D1B5", "#C1B4A4", "#2F2B3D", "#1B2021"],
    paletteName: "Monocromático Gris",
    paletteDescription:
      "Esta paleta utiliza distintas tonalidades de gris para crear un ambiente sobrio y elegante.",
  },

  {
    palette: ["#D1F5FF", "#A8D8EA", "#9AA4B2", "#7C3F58", "#1B2021"],
    paletteName: "Océano Profundo",
    paletteDescription:
      "Esta paleta se inspira en los tonos azules del océano, con un toque de morado oscuro.",
  },

  {
    palette: ["#FFFEF6", "#F1DFBB", "#A69E90", "#444444", "#1B2021"],
    paletteName: "Vintage Sepia",
    paletteDescription:
      "Esta paleta evoca una sensación de antigüedad y nostalgia, con tonos sepia y dorados.",
  },

  {
    palette: ["#F4D1AE", "#E8A565", "#E65353", "#66101F", "#FFFFFF"],
    paletteName: "Fuego",
    paletteDescription:
      "Esta paleta se inspira en el fuego, con tonos naranjas y rojos intensos.",
  },

  {
    palette: ["#FFE2B8", "#FFCBB8", "#FFA5A5", "#833C3D", "#1B2021"],
    paletteName: "Floral",
    paletteDescription:
      "Esta paleta evoca la belleza de las flores, con tonos cálidos y suaves.",
  },
  {
    palette: ["#FDCD44", "#F05E7B", "#6C5B7B", "#2C2E3E", "#1B2021"],
    paletteName: "Neón Retro",
    paletteDescription:
      "Esta paleta combina colores neón con tonos retro para crear un estilo vibrante y atrevido.",
  },
  {
    palette: ["#F9E0D9", "#E2A9A1", "#C16868", "#3A2E3A", "#1B2021"],
    paletteName: "Romance Gótico",
    paletteDescription:
      "Esta paleta combina colores suaves y románticos con tonos oscuros y góticos.",
  },

  {
    palette: ["#E2BACF", "#F3D3BD", "#B4A4D4", "#4D4D4D", "#1B2021"],
    paletteName: "Dulces Sueños",
    paletteDescription:
      "Esta paleta se inspira en los tonos pastel y evoca una sensación de dulzura y suavidad.",
  },

  {
    palette: ["#F6D8AE", "#F6A65B", "#F6652E", "#6A2135", "#1B2021"],
    paletteName: "Cálido y Atrevido",
    paletteDescription:
      "Esta paleta combina colores cálidos con un tono rojo intenso para crear un estilo atrevido y emocionante.",
  },

  {
    palette: ["#F4F4F4", "#F4D1AE", "#E8A565", "#66101F", "#1B2021"],
    paletteName: "Minimalista Cálido",
    paletteDescription:
      "Esta paleta utiliza colores cálidos y suaves para crear un estilo minimalista y elegante.",
  },

  {
    palette: ["#C5E5F5", "#A0D0E5", "#709BB4", "#515B63", "#1B2021"],
    paletteName: "Estilo Náutico",
    paletteDescription:
      "Esta paleta se inspira en los tonos azules del océano y combina colores claros y oscuros para crear un estilo náutico.",
  },

  {
    palette: ["#F3F3F3", "#A3A3A3", "#FFD6BA", "#FFAD9F", "#FF8C9D"],
    paletteName: "Dulce y Picante",
    paletteDescription:
      "Esta paleta combina tonos pastel con un rojo intenso para crear un estilo dulce y picante.",
  },

  {
    palette: ["#F7D9C4", "#F7A59C", "#E86F6F", "#6B2737", "#1B2021"],
    paletteName: "Retro Glam",
    paletteDescription:
      "Esta paleta se inspira en la moda retro y combina tonos pastel con un rojo intenso para crear un estilo glamoroso.",
  },

  {
    palette: ["#FDF0C9", "#FED89A", "#FCB24C", "#D9521A", "#1B2021"],
    paletteName: "Calidez del Verano",
    paletteDescription:
      "Esta paleta se inspira en los tonos cálidos del verano y combina tonos dorados con naranjas y rojos intensos.",
  },
  {
    palette: ["#FFE0B5", "#F8C291", "#D37C6B", "#6D4C41", "#1B2021"],
    paletteName: "Tonalidades Terracota",
    paletteDescription:
      "Esta paleta utiliza tonalidades de terracota y marrones para crear un ambiente cálido y acogedor.",
  },

  {
    palette: ["#D8F3DC", "#B7E4C7", "#95D5B2", "#3E3D3F", "#1B2021"],
    paletteName: "Sencillo y Elegante",
    paletteDescription:
      "Esta paleta utiliza tonos suaves y neutros para crear un estilo sencillo y elegante.",
  },
];
