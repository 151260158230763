import React, { useContext } from "react";
import useCopyToClipboard from "../../hooks/useCopyToClipboard";
import { darkOrLightColor } from "../../helpers/darkOrLightColor";
import LinkToDetailsColor from "../LinkToDetailsColor";
import { ContextLocale } from "../../context/ContextLocale";
import CopyColorMessage from "../CopyColorMessage";
import {
  color_wrapper,
  color_code,
  container_options,
  button_option,
  button_copy,
} from "../../styles/modules/color.module.css";

function Color({ color, path, withLinkColor = false }) {
  const { lang } = useContext(ContextLocale);
  const [message, showMessage, handleCopy] = useCopyToClipboard(`${color}`);

  return (
    <>
      {showMessage ? (
        <CopyColorMessage colorHex={color} message={message} />
      ) : null}

      <div
        className={color_wrapper}
        style={{ backgroundColor: color }}
        onClick={handleCopy}
      >
        {withLinkColor ? (
          <div className={container_options}>
            <div onClick={e => e.stopPropagation()}>
              <LinkToDetailsColor color={color} path={path} />
            </div>

            <button
              style={{
                boxShadow: `0px 0px 3px 0px ${darkOrLightColor(color)}`,
                color: darkOrLightColor(color),
                background: color,
              }}
              className={`${button_option} ${button_copy}`}
            >
              <svg
                className="svg-icon"
                viewBox="0 0 20 20"
                width="17px"
                height="17px"
                fill={darkOrLightColor(color)}
              >
                <path
                  d="M17.206,5.45l0.271-0.27l-4.275-4.274l-0.27,0.269V0.9H3.263c-0.314,0-0.569,0.255-0.569,0.569v17.062
          c0,0.314,0.255,0.568,0.569,0.568h13.649c0.313,0,0.569-0.254,0.569-0.568V5.45H17.206z M12.932,2.302L16.08,5.45h-3.148V2.302z
          M16.344,17.394c0,0.314-0.254,0.569-0.568,0.569H4.4c-0.314,0-0.568-0.255-0.568-0.569V2.606c0-0.314,0.254-0.568,0.568-0.568
          h7.394v4.55h4.55V17.394z"
                ></path>
              </svg>
              <strong>
                {lang === "es"
                  ? "copiar"
                  : lang === "en"
                  ? "copy"
                  : lang === "pt"
                  ? "copiar"
                  : lang === "fr"
                  ? "copier"
                  : null}
              </strong>
            </button>
          </div>
        ) : null}

        <strong
          style={{ color: darkOrLightColor(color) }}
          className={color_code}
        >
          {color}
        </strong>
      </div>
    </>
  );
}

export default Color;
