import React, { useEffect, useState } from "react";
import {
  colors_from_image_wrapper,
  image_palette_wrapper,
} from "../../../styles/modules/palette_from_image.module.css";
import ColorThief from "colorthief";
import chroma from "chroma-js";
import { MiniColor } from "../../PageColorPalette/Colors";

function ColorsFromImage({ images = [], locale }) {
  return (
    <div className={colors_from_image_wrapper}>
      {images.length > 0 ? (
        images.map(image => (
          <ImageColors locale={locale} key={image.id} image={image} />
        ))
      ) : (
        <h2>
          {locale === "es"
            ? "Por favor agrega una imagen para detectar sus colores"
            : locale === "en"
            ? "Please add an image to detect its colors"
            : locale === "pt"
            ? "Adicione uma imagem para detectar suas cores"
            : locale === "fr"
            ? "Veuillez ajouter une image pour détecter ses couleurs"
            : null}
        </h2>
      )}
    </div>
  );
}

function ImageColors({ image }) {
  const [palette, setPalette] = useState([]);

  useEffect(() => {
    const colorThief = new ColorThief();
    const img = document.createElement("img");

    img.onload = () => {
      const colors = colorThief.getPalette(img, 5);
      const formatPalette = colors.map(color =>
        chroma(`rgb(${color[0]}, ${color[1]}, ${color[2]})`).hex()
      );

      setPalette(formatPalette);
    };

    img.src = image.src;
  }, [image.src]);

  return (
    <section className={image_palette_wrapper}>
      <div className="color-palette">
        {palette.map((color, i) => (
          <MiniColor key={`${color}-${i}`} hex={color} withLinkColor={false} />
        ))}
      </div>
      <img src={image.src} style={{ width: "100%" }} alt="img palette" />
    </section>
  );
}

export default ColorsFromImage;
