import React from "react";
import { allColors } from "./allColors";
import {
  table_wrapper,
  table_head,
  table_body,
  table_card_color,
  table_card_color_info,
} from "./styles.module.css";
import TableCardColorBackground from "./TableCardColorBackground";

export default function TableOfColors({ locale }) {
  return (
    <table className={table_wrapper}>
      <thead className={table_head}>
        <tr>
          <a href="#indianred">
            🔗{" "}
            {locale === "es"
              ? "tonos rojos"
              : locale === "en"
              ? "Red shades"
              : locale === "pt"
              ? "Tons de vermelho"
              : locale === "fr"
              ? "Tons de rouge"
              : ""}
          </a>
        </tr>
        <tr>
          <a href="#steelblue">
            🔗{" "}
            {locale === "es"
              ? "tonos azules"
              : locale === "en"
              ? "Blue shades"
              : locale === "pt"
              ? "Tons de azul"
              : locale === "fr"
              ? "Tons de bleu"
              : ""}
          </a>
        </tr>
        <tr>
          <a href="#greenyellow">
            🔗{" "}
            {locale === "es"
              ? "tonos verdes"
              : locale === "en"
              ? "Green shades"
              : locale === "pt"
              ? "Tons de verde"
              : locale === "fr" // Agrega esta línea
              ? "Tons de vert" // Agrega el valor para el idioma francés
              : ""}
          </a>
        </tr>
        <tr>
          <a href="#pink">
            🔗{" "}
            {locale === "es"
              ? "tonos rosa"
              : locale === "en"
              ? "Pink shades"
              : locale === "pt"
              ? "Tons de rosa"
              : locale === "fr" // Agrega esta línea
              ? "Tons de rose" // Agrega el valor para el idioma francés
              : ""}
          </a>
        </tr>
        <tr>
          <a href="#lavender">
            🔗{" "}
            {locale === "es"
              ? "tonos violetas"
              : locale === "en"
              ? "Violet shades"
              : locale === "pt"
              ? "Tons de violeta"
              : locale === "fr" // Agrega esta línea
              ? "Tons de violet" // Agrega el valor para el idioma francés
              : ""}
          </a>
        </tr>
        <tr>
          <a href="#dimgray">
            🔗{" "}
            {locale === "es"
              ? "tonos grises"
              : locale === "en"
              ? "Gray shades"
              : locale === "pt"
              ? "Tons de cinza"
              : locale === "fr" // Agrega esta línea
              ? "Tons de gris" // Agrega el valor para el idioma francés
              : ""}
          </a>
        </tr>
      </thead>
      <tbody className={table_body}>
        {allColors.map(color => (
          <tr key={color.name} id={color.name} className={table_card_color}>
            <TableCardColorBackground locale={locale} color={color.hex} />

            <td className={table_card_color_info}>{color.name}</td>
            <td className={table_card_color_info}>{color.hex}</td>
            <td className={table_card_color_info}>{color.rgb}</td>
            <td className={table_card_color_info}>{color.hsl}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
