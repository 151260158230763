import React from "react";
import loadable from "@loadable/component";
import Spinner from "../../../src/components/Spinner";

const CreatePalette = loadable(
  () => import("../../../src/components/CrearPaleta/CreatePalette"),
  {
    fallback: <Spinner />,
  }
);

function CreatePalettePage() {
  return <CreatePalette />;
}

export default CreatePalettePage;
