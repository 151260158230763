import React from "react";
// import { modal_ad } from "../../styles/modules/modal_palette.module.css";
// import PlaceholderAd from "./PlaceholderAd";
import { ad_wrapper } from "./styles.module.css";
import useAdManaSlot from "../../hooks/useAdManaSlot";

function AdManaWithPlaceholder({
  path = "/22250597679/ptdc_banner_bottom",
  size = [
    [300, 250],
    [970, 250],
    [728, 250],
    [970, 90],
    [728, 90],
  ],
  id = "ptdc_banner_bottom",
}) {
  useAdManaSlot({
    path: path,
    size: size,
    id: id,
  });

  return (
    <div id="ad-manager-wrapper" className={ad_wrapper}>
      <div id={id} style={{ minWidth: "300px", minHeight: "90px" }}></div>
    </div>
  );
}

export default AdManaWithPlaceholder;
