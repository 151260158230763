import React, { createContext, useState, useEffect } from "react";

export const ContextLocale = createContext();

export const LanguageLocaleProvider = ({ children }) => {
  const [lang, setLang] = useState("es");

  useEffect(() => {
    document.documentElement.lang = lang;
  }, [lang]);

  return (
    <ContextLocale.Provider value={{ lang, setLang }}>
      {children}
    </ContextLocale.Provider>
  );
};
