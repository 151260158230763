import React, { useState, useEffect, useContext } from "react";
import downloadPaletteImage from "../../helpers/downloadPaletteImage";
import closeIcon from "../../images/close-icon.svg";
import { ContextLocale } from "../../context/ContextLocale";
import {
  modal_wrapper,
  modal_dialog,
  modal_header,
  modal_title,
  modal_header_utils,
  modal_body,
  button_close,
  button_download,
  input_name_image,
} from "../../styles/modules/modal.module.css";

function ModalDownload({ colorPalette, onClose }) {
  const { lang } = useContext(ContextLocale);
  const [nameImage, setNameImage] = useState("paleta");

  useEffect(() => {
    document.addEventListener("keydown", keydownHandler);
    return () => document.removeEventListener("keydown", keydownHandler);
  });

  function keydownHandler({ key }) {
    switch (key) {
      case "Escape":
        onClose();
        break;
      default:
    }
  }

  const handleChange = e => {
    setNameImage(e.target.value);
  };

  const { downloadPng } = downloadPaletteImage(colorPalette, nameImage);

  return (
    <section
      className={modal_wrapper}
      onClick={() => {
        onClose();
      }}
    >
      <div className={modal_dialog} onClick={e => e.stopPropagation()}>
        <div className={modal_header}>
          <h3 className={modal_title}>
            {lang === "es"
              ? "Escoge un nombre para tu paleta de colores"
              : lang === "en"
              ? "Choose a name for your color palette"
              : lang === "pt"
              ? "Escolha um nome para sua paleta de cores"
              : lang === "fr"
              ? "Choisissez un nom pour votre palette de couleurs"
              : null}
          </h3>
          <div className={modal_header_utils}>
            <button
              className={button_close}
              onClick={() => {
                onClose();
              }}
            >
              <img src={closeIcon} alt="close icon" height={20} width={20} />
            </button>
          </div>
        </div>
        <div className={modal_body}>
          <div>
            <input
              className={input_name_image}
              type="text"
              value={nameImage}
              onChange={handleChange}
            />
            {nameImage.length > 0 && nameImage.trim() !== "" ? (
              <button className={button_download} onClick={downloadPng}>
                {lang === "es"
                  ? "Descargar"
                  : lang === "en"
                  ? "Download"
                  : lang === "pt"
                  ? "Descarga"
                  : lang === "fr"
                  ? "Télécharger"
                  : null}
              </button>
            ) : (
              <div>
                {lang === "es"
                  ? "Por favor asigna un nombre a la paleta"
                  : lang === "en"
                  ? "Please assign a name to the palette"
                  : lang === "pt"
                  ? "Atribua um nome à paleta"
                  : lang === "fr"
                  ? "Veuillez attribuer un nom à la palette"
                  : null}
              </div>
            )}
          </div>
        </div>
        {/* <textarea
          value={`<ImagePalettePost image={props.embeddedImages.${nameImage}.gatsbyImageData} alt="${nameImage.replace(
            /_/g,
            " "
          )}" description="${nameImage.replace(
            /_/g,
            " "
          )}" withLink={false} palette={[${colorPalette.map(
            color => `"${color}"`
          )}]} /> `}
        ></textarea> */}
      </div>
    </section>
  );
}

export default ModalDownload;
