import React from "react";
import Code from "./Code";

export default function CodeSnippetColor({ colorName, colorHex, locale }) {
  const backgroundInlineHtml = `
  <div style="background:${colorHex};">  ${
    locale === "es"
      ? `Color ${colorName} como fondo de un div`
      : locale === "en"
      ? `Color ${colorName} as the background of a div.`
      : locale === "pt"
      ? `Cor ${colorName} como fundo de uma div.`
      : locale === "fr"
      ? `Couleur ${colorName} comme fond d'un div.`
      : ""
  }</div>
  `;
  const backgroundCss = `
  
  /* ${
    locale === "es"
      ? `Color ${colorName} como fondo de la etiqueta body con css`
      : locale === "en"
      ? `Color ${colorName} as the background of the body tag with CSS.`
      : locale === "pt"
      ? `Cor ${colorName} como fundo da tag body com CSS.`
      : locale === "fr"
      ? `Couleur ${colorName} comme fond de la balise body avec CSS.`
      : ""
  } */
  
  body {
    background: ${colorHex};
  }
  `;
  const colorTextInline = `
  <p style="color:${colorHex};"> ${
    locale === "es"
      ? `Color ${colorName} como color de texto.`
      : locale === "en"
      ? `Color ${colorName} as the text color.`
      : locale === "pt"
      ? `Cor ${colorName} como a cor do texto.`
      : locale === "fr"
      ? `Couleur ${colorName}`
      : ""
  }</p>
  `;
  const colorTextCss = `
  p {
    color:${colorHex} ;
  }
  `;

  return (
    <section>
      <h2 className="heading_color_page">
        {locale === "es"
          ? `Cómo implementar el color ${colorName} en html y css`
          : locale === "en"
          ? `How to implement the color ${colorName} in HTML and CSS`
          : locale === "pt"
          ? `Como implementar a cor ${colorName} em HTML e CSS.`
          : locale === "fr"
          ? `Comment implémenter la couleur ${colorName} en HTML et CSS`
          : ""}
      </h2>
      <p style={{ fontSize: "small" }}>
        {locale === "es"
          ? `A continuación tienes ejemplos de códigos para usar el ${colorName} en tu página web. Recuerda que puedes implementar el color en html usando los códigos de colores ya sea hexadecimal rgb o hsl y también directamente con el nombre de color escrito en inglés (siempre y cuando sea soportado nativamente por html)`
          : locale === "en"
          ? `Here are examples of code to use the ${colorName} on your web page. Remember that you can implement the color in HTML using color codes such as hexadecimal, RGB, or HSL, and also directly with the color name written in English (as long as it is natively supported by HTML).`
          : locale === "pt"
          ? `Aqui estão exemplos de códigos para usar a cor ${colorName} na sua página da web. Lembre-se de que você pode implementar a cor em HTML usando códigos de cor, como hexadecimal, RGB ou HSL, e também diretamente com o nome da cor escrito em inglês (desde que seja suportado nativamente pelo HTML`
          : locale === "fr"
          ? `Voici des exemples de codes pour utiliser le ${colorName} sur votre site web. N'oubliez pas que vous pouvez implémenter la couleur en HTML en utilisant les codes de couleur, que ce soit en hexadécimal, en RVB, ou en HSL, ainsi qu'en utilisant directement le nom de la couleur écrit en anglais (tant que cela est nativement pris en charge par HTML).`
          : ""}
      </p>
      <h3 className="heading_color_page">
        {locale === "es"
          ? `Color ${colorName} de fondo en html - css`
          : locale === "en"
          ? `Background color ${colorName} in HTML - CSS.`
          : locale === "pt"
          ? `Cor de fundo ${colorName} em HTML - CSS.`
          : locale === "fr"
          ? `Couleur de fond ${colorName} en HTML - CSS.`
          : ""}
      </h3>
      <p style={{ fontSize: "small" }}>
        {locale === "es"
          ? `Para poner el color de fondo puedes usar estilos en línea o también aplicarlos en una hoja css`
          : locale === "en"
          ? `To set the background color, you can use inline styles or apply them in a CSS stylesheet`
          : locale === "pt"
          ? `Para definir a cor de fundo, você pode usar estilos inline ou aplicá-los em uma folha de estilo CSS.`
          : locale === "fr"
          ? `Pour définir la couleur de fond, vous pouvez utiliser des styles en ligne ou appliquer ces styles dans un fichier de style CSS.`
          : ""}
      </p>
      <Code snippet={backgroundInlineHtml} language="html" />
      <Code snippet={backgroundCss} language="css" />
      <h3 className="heading_color_page">
        {locale === "es"
          ? `Poner el texto de color ${colorName} en html - css`
          : locale === "en"
          ? `To set the text color ${colorName} in HTML - CSS.`
          : locale === "pt"
          ? `Para definir a cor do texto ${colorName} em HTML - CSS.`
          : locale === "fr"
          ? `Pour définir la couleur du texte ${colorName} en HTML - CSS.`
          : ""}
      </h3>
      <p style={{ fontSize: "small" }}>
        {locale === "es"
          ? `Para poner el color de texto puedes usar estilos en línea o también aplicarlos en una hoja css`
          : locale === "en"
          ? `To set the text color, you can use inline styles or apply them in a CSS stylesheet.`
          : locale === "pt"
          ? `Para definir a cor do texto, você pode usar estilos inline ou aplicá-los em uma folha de estilo CSS.`
          : locale === "fr"
          ? `Pour définir la couleur du texte, vous pouvez utiliser des styles en ligne ou appliquer ces styles dans un fichier de style CSS.`
          : ""}
      </p>
      <Code snippet={colorTextInline} language="html" />
      <Code snippet={colorTextCss} language="css" />
    </section>
  );
}
