export const allColors = [
  {
    name: "indianred",
    hex: "#cd5c5c",
    rgb: "rgb(205,92,92)",
    rgba: "rgba(205,92,92,0.7)",
    hsl: "hsl(0,54%,59%)",
  },
  {
    name: "lightcoral",
    hex: "#f08080",
    rgb: "rgb(240,128,128)",
    rgba: "rgba(240,128,128,0.7)",
    hsl: "hsl(0,79%,73%)",
  },
  {
    name: "salmon",
    hex: "#fa8072",
    rgb: "rgb(250,128,114)",
    rgba: "rgba(250,128,114,0.7)",
    hsl: "hsl(7,94%,72%)",
  },
  {
    name: "darksalmon",
    hex: "#e9967a",
    rgb: "rgb(233,150,122)",
    rgba: "rgba(233,150,122,0.7)",
    hsl: "hsl(16,72%,70%)",
  },
  {
    name: "lightsalmon",
    hex: "#ffa07a",
    rgb: "rgb(255,160,122)",
    rgba: "rgba(255,160,122,0.7)",
    hsl: "hsl(18,100%,74%)",
  },
  {
    name: "crimson",
    hex: "#dc143c",
    rgb: "rgb(220,20,60)",
    rgba: "rgba(220,20,60,0.7)",
    hsl: "hsl(348,84%,48%)",
  },
  {
    name: "red",
    hex: "#ff0000",
    rgb: "rgb(255,0,0)",
    rgba: "rgba(255,0,0,0.7)",
    hsl: "hsl(0,100%,50%)",
  },
  {
    name: "firebrick",
    hex: "#b22222",
    rgb: "rgb(178,34,34)",
    rgba: "rgba(178,34,34,0.7)",
    hsl: "hsl(0,68%,42%)",
  },
  {
    name: "darkred",
    hex: "#8b0000",
    rgb: "rgb(139,0,0)",
    rgba: "rgba(139,0,0,0.7)",
    hsl: "hsl(0,100%,28%)",
  },
  {
    name: "cyan",
    hex: "#00ffff",
    rgb: "rgb(0,255,255)",
    rgba: "rgba(0,255,255,0.7)",
    hsl: "hsl(180,100%,50%)",
  },
  {
    name: "lightcyan",
    hex: "#e0ffff",
    rgb: "rgb(224,255,255)",
    rgba: "rgba(224,255,255,0.7)",
    hsl: "hsl(180,100%,94%)",
  },
  {
    name: "paleturquoise",
    hex: "#afeeee",
    rgb: "rgb(175,238,238)",
    rgba: "rgba(175,238,238,0.7)",
    hsl: "hsl(180,65%,81%)",
  },
  {
    name: "aquamarine",
    hex: "#7fffd4",
    rgb: "rgb(127,255,212)",
    rgba: "rgba(127,255,212,0.7)",
    hsl: "hsl(160,100%,75%)",
  },
  {
    name: "turquoise",
    hex: "#40e0d0",
    rgb: "rgb(64,224,208)",
    rgba: "rgba(64,224,208,0.7)",
    hsl: "hsl(174,73%,57%)",
  },
  {
    name: "mediumturquoise",
    hex: "#48d1cc",
    rgb: "rgb(72,209,204)",
    rgba: "rgba(72,209,204,0.7)",
    hsl: "hsl(178,60%,56%)",
  },
  {
    name: "darkturquoise",
    hex: "#00ced1",
    rgb: "rgb(0,206,209)",
    rgba: "rgba(0,206,209,0.7)",
    hsl: "hsl(181,100%,41%)",
  },
  {
    name: "cadetblue",
    hex: "#5f9ea0",
    rgb: "rgb(95,158,160)",
    rgba: "rgba(95,158,160,0.7)",
    hsl: "hsl(182,26%,50%)",
  },
  {
    name: "steelblue",
    hex: "#4682b4",
    rgb: "rgb(70,130,180)",
    rgba: "rgba(70,130,180,0.7)",
    hsl: "hsl(208,44%,50%)",
  },
  {
    name: "lightsteelblue",
    hex: "#b0c4de",
    rgb: "rgb(176,196,222)",
    rgba: "rgba(176,196,222,0.7)",
    hsl: "hsl(214,42%,79%)",
  },
  {
    name: "powderblue",
    hex: "#b0e0e6",
    rgb: "rgb(176,224,230)",
    rgba: "rgba(176,224,230,0.7)",
    hsl: "hsl(187,52%,80%)",
  },
  {
    name: "lightblue",
    hex: "#add8e6",
    rgb: "rgb(173,216,230)",
    rgba: "rgba(173,216,230,0.7)",
    hsl: "hsl(195,54%,80%)",
  },
  {
    name: "skyblue",
    hex: "#87ceeb",
    rgb: "rgb(135,206,235)",
    rgba: "rgba(135,206,235,0.7)",
    hsl: "hsl(198,72%,73%)",
  },
  {
    name: "lightskyblue",
    hex: "#87cefa",
    rgb: "rgb(135,206,250)",
    rgba: "rgba(135,206,250,0.7)",
    hsl: "hsl(203,92%,76%)",
  },
  {
    name: "deepskyblue",
    hex: "#00bfff",
    rgb: "rgb(0,191,255)",
    rgba: "rgba(0,191,255,0.7)",
    hsl: "hsl(196,100%,50%)",
  },
  {
    name: "dodgerblue",
    hex: "#1e90ff",
    rgb: "rgb(30,144,255)",
    rgba: "rgba(30,144,255,0.7)",
    hsl: "hsl(210,100%,56%)",
  },
  {
    name: "cornflowerblue",
    hex: "#6495ed",
    rgb: "rgb(100,149,237)",
    rgba: "rgba(100,149,237,0.7)",
    hsl: "hsl(219,80%,67%)",
  },
  {
    name: "mediumslateblue",
    hex: "#7b68ee",
    rgb: "rgb(123,104,238)",
    rgba: "rgba(123,104,238,0.7)",
    hsl: "hsl(249,80%,68%)",
  },
  {
    name: "royalblue",
    hex: "#4169e1",
    rgb: "rgb(65,105,225)",
    rgba: "rgba(65,105,225,0.7)",
    hsl: "hsl(225,73%,57%)",
  },
  {
    name: "blue",
    hex: "#0000ff",
    rgb: "rgb(0,0,255)",
    rgba: "rgba(0,0,255,0.7)",
    hsl: "hsl(240,100%,50%)",
  },
  {
    name: "mediumblue",
    hex: "#0000cd",
    rgb: "rgb(0,0,205)",
    rgba: "rgba(0,0,205,0.7)",
    hsl: "hsl(240,100%,41%)",
  },
  {
    name: "darkblue",
    hex: "#00008b",
    rgb: "rgb(0,0,139)",
    rgba: "rgba(0,0,139,0.7)",
    hsl: "hsl(240,100%,28%)",
  },
  {
    name: "navy",
    hex: "#000080",
    rgb: "rgb(0,0,128)",
    rgba: "rgba(0,0,128,0.7)",
    hsl: "hsl(240,100%,26%)",
  },
  {
    name: "midnightblue",
    hex: "#191970",
    rgb: "rgb(25,25,112)",
    rgba: "rgba(25,25,112,0.7)",
    hsl: "hsl(240,64%,27%)",
  },
  {
    name: "greenyellow",
    hex: "#adff2f",
    rgb: "rgb(173,255,47)",
    rgba: "rgba(173,255,47,0.7)",
    hsl: "hsl(84,100%,60%)",
  },
  {
    name: "chartreuse",
    hex: "#7fff00",
    rgb: "rgb(127,255,0)",
    rgba: "rgba(127,255,0,0.7)",
    hsl: "hsl(91,100%,50%)",
  },
  {
    name: "lawngreen",
    hex: "#7cfc00",
    rgb: "rgb(124,252,0)",
    rgba: "rgba(124,252,0,0.7)",
    hsl: "hsl(91,100%,50%)",
  },
  {
    name: "lime",
    hex: "#00ff00",
    rgb: "rgb(0,255,0)",
    rgba: "rgba(0,255,0,0.7)",
    hsl: "hsl(120,100%,50%)",
  },
  {
    name: "limegreen",
    hex: "#32cd32",
    rgb: "rgb(50,205,50)",
    rgba: "rgba(50,205,50,0.7)",
    hsl: "hsl(120,61%,50%)",
  },
  {
    name: "palegreen",
    hex: "#98fb98",
    rgb: "rgb(152,251,152)",
    rgba: "rgba(152,251,152,0.7)",
    hsl: "hsl(120,93%,80%)",
  },
  {
    name: "lightgreen",
    hex: "#90ee90",
    rgb: "rgb(144,238,144)",
    rgba: "rgba(144,238,144,0.7)",
    hsl: "hsl(120,74%,75%)",
  },
  {
    name: "mediumspringgreen",
    hex: "#00fa9a",
    rgb: "rgb(0,250,154)",
    rgba: "rgba(0,250,154,0.7)",
    hsl: "hsl(157,100%,50%)",
  },
  {
    name: "springgreen",
    hex: "#00ff7f",
    rgb: "rgb(0,255,127)",
    rgba: "rgba(0,255,127,0.7)",
    hsl: "hsl(150,100%,50%)",
  },
  {
    name: "mediumseagreen",
    hex: "#3cb371",
    rgb: "rgb(60,179,113)",
    rgba: "rgba(60,179,113,0.7)",
    hsl: "hsl(147,50%,47%)",
  },
  {
    name: "seagreen",
    hex: "#2e8b57",
    rgb: "rgb(46,139,87)",
    rgba: "rgba(46,139,87,0.7)",
    hsl: "hsl(147,51%,37%)",
  },
  {
    name: "forestgreen",
    hex: "#228b22",
    rgb: "rgb(34,139,34)",
    rgba: "rgba(34,139,34,0.7)",
    hsl: "hsl(120,61%,34%)",
  },
  {
    name: "green",
    hex: "#008000",
    rgb: "rgb(0,128,0)",
    rgba: "rgba(0,128,0,0.7)",
    hsl: "hsl(120,100%,26%)",
  },
  {
    name: "darkgreen",
    hex: "#006400",
    rgb: "rgb(0,100,0)",
    rgba: "rgba(0,100,0,0.7)",
    hsl: "hsl(120,100%,20%)",
  },
  {
    name: "yellowgreen",
    hex: "#9acd32",
    rgb: "rgb(154,205,50)",
    rgba: "rgba(154,205,50,0.7)",
    hsl: "hsl(80,61%,50%)",
  },
  {
    name: "olivedrab",
    hex: "#6b8e23",
    rgb: "rgb(107,142,35)",
    rgba: "rgba(107,142,35,0.7)",
    hsl: "hsl(80,61%,35%)",
  },
  {
    name: "olive",
    hex: "#808000",
    rgb: "rgb(128,128,0)",
    rgba: "rgba(128,128,0,0.7)",
    hsl: "hsl(60,100%,26%)",
  },
  {
    name: "darkolivegreen",
    hex: "#556b2f",
    rgb: "rgb(85,107,47)",
    rgba: "rgba(85,107,47,0.7)",
    hsl: "hsl(82,39%,31%)",
  },
  {
    name: "mediumaquamarine",
    hex: "#66cdaa",
    rgb: "rgb(102,205,170)",
    rgba: "rgba(102,205,170,0.7)",
    hsl: "hsl(160,51%,61%)",
  },
  {
    name: "darkseagreen",
    hex: "#8fbc8f",
    rgb: "rgb(143,188,143)",
    rgba: "rgba(143,188,143,0.7)",
    hsl: "hsl(120,26%,65%)",
  },
  {
    name: "lightseagreen",
    hex: "#20b2aa",
    rgb: "rgb(32,178,170)",
    rgba: "rgba(32,178,170,0.7)",
    hsl: "hsl(177,70%,42%)",
  },
  {
    name: "darkcyan",
    hex: "#008b8b",
    rgb: "rgb(0,139,139)",
    rgba: "rgba(0,139,139,0.7)",
    hsl: "hsl(180,100%,28%)",
  },
  {
    name: "teal",
    hex: "#008080",
    rgb: "rgb(0,128,128)",
    rgba: "rgba(0,128,128,0.7)",
    hsl: "hsl(180,100%,26%)",
  },
  {
    name: "pink",
    hex: "#ffc0cb",
    rgb: "rgb(255,192,203)",
    rgba: "rgba(255,192,203,0.7)",
    hsl: "hsl(350,100%,88%)",
  },
  {
    name: "lightpink",
    hex: "#ffb6c1",
    rgb: "rgb(255,182,193)",
    rgba: "rgba(255,182,193,0.7)",
    hsl: "hsl(351,100%,86%)",
  },
  {
    name: "hotpink",
    hex: "#ff69b4",
    rgb: "rgb(255,105,180)",
    rgba: "rgba(255,105,180,0.7)",
    hsl: "hsl(330,100%,71%)",
  },
  {
    name: "deeppink",
    hex: "#ff1493",
    rgb: "rgb(255,20,147)",
    rgba: "rgba(255,20,147,0.7)",
    hsl: "hsl(328,100%,54%)",
  },
  {
    name: "mediumvioletred",
    hex: "#c71585",
    rgb: "rgb(199,21,133)",
    rgba: "rgba(199,21,133,0.7)",
    hsl: "hsl(323,81%,44%)",
  },
  {
    name: "palevioletred",
    hex: "#db7093",
    rgb: "rgb(219,112,147)",
    rgba: "rgba(219,112,147,0.7)",
    hsl: "hsl(341,60%,65%)",
  },
  {
    name: "cornsilk",
    hex: "#fff8dc",
    rgb: "rgb(255,248,220)",
    rgba: "rgba(255,248,220,0.7)",
    hsl: "hsl(48,100%,94%)",
  },
  {
    name: "blanchedalmond",
    hex: "#ffebcd",
    rgb: "rgb(255,235,205)",
    rgba: "rgba(255,235,205,0.7)",
    hsl: "hsl(36,100%,91%)",
  },
  {
    name: "bisque",
    hex: "#ffe4c4",
    rgb: "rgb(255,228,196)",
    rgba: "rgba(255,228,196,0.7)",
    hsl: "hsl(33,100%,89%)",
  },
  {
    name: "navajowhite",
    hex: "#ffdead",
    rgb: "rgb(255,222,173)",
    rgba: "rgba(255,222,173,0.7)",
    hsl: "hsl(36,100%,84%)",
  },
  {
    name: "wheat",
    hex: "#f5deb3",
    rgb: "rgb(245,222,179)",
    rgba: "rgba(245,222,179,0.7)",
    hsl: "hsl(40,77%,84%)",
  },
  {
    name: "burlywood",
    hex: "#deb887",
    rgb: "rgb(222,184,135)",
    rgba: "rgba(222,184,135,0.7)",
    hsl: "hsl(34,57%,70%)",
  },
  {
    name: "tan",
    hex: "#d2b48c",
    rgb: "rgb(210,180,140)",
    rgba: "rgba(210,180,140,0.7)",
    hsl: "hsl(35,44%,69%)",
  },
  {
    name: "rosybrown",
    hex: "#bc8f8f",
    rgb: "rgb(188,143,143)",
    rgba: "rgba(188,143,143,0.7)",
    hsl: "hsl(0,26%,65%)",
  },
  {
    name: "sandybrown",
    hex: "#f4a460",
    rgb: "rgb(244,164,96)",
    rgba: "rgba(244,164,96,0.7)",
    hsl: "hsl(28,88%,67%)",
  },
  {
    name: "goldenrod",
    hex: "#daa520",
    rgb: "rgb(218,165,32)",
    rgba: "rgba(218,165,32,0.7)",
    hsl: "hsl(43,75%,50%)",
  },
  {
    name: "darkgoldenrod",
    hex: "#b8860b",
    rgb: "rgb(184,134,11)",
    rgba: "rgba(184,134,11,0.7)",
    hsl: "hsl(43,89%,39%)",
  },
  {
    name: "peru",
    hex: "#cd853f",
    rgb: "rgb(205,133,63)",
    rgba: "rgba(205,133,63,0.7)",
    hsl: "hsl(30,59%,53%)",
  },
  {
    name: "chocolate",
    hex: "#d2691e",
    rgb: "rgb(210,105,30)",
    rgba: "rgba(210,105,30,0.7)",
    hsl: "hsl(25,75%,48%)",
  },
  {
    name: "saddlebrown",
    hex: "#8b4513",
    rgb: "rgb(139,69,19)",
    rgba: "rgba(139,69,19,0.7)",
    hsl: "hsl(25,76%,31%)",
  },
  {
    name: "sienna",
    hex: "#a0522d",
    rgb: "rgb(160,82,45)",
    rgba: "rgba(160,82,45,0.7)",
    hsl: "hsl(20,57%,41%)",
  },
  {
    name: "brown",
    hex: "#a52a2a",
    rgb: "rgb(165,42,42)",
    rgba: "rgba(165,42,42,0.7)",
    hsl: "hsl(0,60%,41%)",
  },
  {
    name: "maroon",
    hex: "#800000",
    rgb: "rgb(128,0,0)",
    rgba: "rgba(128,0,0,0.7)",
    hsl: "hsl(0,100%,26%)",
  },
  {
    name: "lightsalmon",
    hex: "#ffa07a",
    rgb: "rgb(255,160,122)",
    rgba: "rgba(255,160,122,0.7)",
    hsl: "hsl(18,100%,74%)",
  },
  {
    name: "coral",
    hex: "#ff7f50",
    rgb: "rgb(255,127,80)",
    rgba: "rgba(255,127,80,0.7)",
    hsl: "hsl(17,100%,66%)",
  },
  {
    name: "tomato",
    hex: "#ff6347",
    rgb: "rgb(255,99,71)",
    rgba: "rgba(255,99,71,0.7)",
    hsl: "hsl(10,100%,64%)",
  },
  {
    name: "orangered",
    hex: "#ff4500",
    rgb: "rgb(255,69,0)",
    rgba: "rgba(255,69,0,0.7)",
    hsl: "hsl(17,100%,50%)",
  },
  {
    name: "darkorange",
    hex: "#ff8c00",
    rgb: "rgb(255,140,0)",
    rgba: "rgba(255,140,0,0.7)",
    hsl: "hsl(33,100%,50%)",
  },
  {
    name: "orange",
    hex: "#ffa500",
    rgb: "rgb(255,165,0)",
    rgba: "rgba(255,165,0,0.7)",
    hsl: "hsl(39,100%,50%)",
  },
  {
    name: "gold",
    hex: "#ffd700",
    rgb: "rgb(255,215,0)",
    rgba: "rgba(255,215,0,0.7)",
    hsl: "hsl(51,100%,50%)",
  },
  {
    name: "yellow",
    hex: "#ffff00",
    rgb: "rgb(255,255,0)",
    rgba: "rgba(255,255,0,0.7)",
    hsl: "hsl(60,100%,50%)",
  },
  {
    name: "lightyellow",
    hex: "#ffffe0",
    rgb: "rgb(255,255,224)",
    rgba: "rgba(255,255,224,0.7)",
    hsl: "hsl(60,100%,94%)",
  },
  {
    name: "lightgoldenrodyellow",
    hex: "#fafad2",
    rgb: "rgb(250,250,210)",
    rgba: "rgba(250,250,210,0.7)",
    hsl: "hsl(60,81%,91%)",
  },
  {
    name: "lemonchiffon",
    hex: "#fffacd",
    rgb: "rgb(255,250,205)",
    rgba: "rgba(255,250,205,0.7)",
    hsl: "hsl(54,100%,91%)",
  },
  {
    name: "papayawhip",
    hex: "#ffefd5",
    rgb: "rgb(255,239,213)",
    rgba: "rgba(255,239,213,0.7)",
    hsl: "hsl(38,100%,92%)",
  },
  {
    name: "moccasin",
    hex: "#ffe4b5",
    rgb: "rgb(255,228,181)",
    rgba: "rgba(255,228,181,0.7)",
    hsl: "hsl(39,100%,86%)",
  },
  {
    name: "peachpuff",
    hex: "#ffdab9",
    rgb: "rgb(255,218,185)",
    rgba: "rgba(255,218,185,0.7)",
    hsl: "hsl(29,100%,87%)",
  },
  {
    name: "palegoldenrod",
    hex: "#eee8aa",
    rgb: "rgb(238,232,170)",
    rgba: "rgba(238,232,170,0.7)",
    hsl: "hsl(55,67%,80%)",
  },
  {
    name: "khaki",
    hex: "#f0e68c",
    rgb: "rgb(240,230,140)",
    rgba: "rgba(240,230,140,0.7)",
    hsl: "hsl(54,77%,75%)",
  },
  {
    name: "darkkhaki",
    hex: "#bdb76b",
    rgb: "rgb(189,183,107)",
    rgba: "rgba(189,183,107,0.7)",
    hsl: "hsl(56,39%,59%)",
  },
  {
    name: "lavender",
    hex: "#e6e6fa",
    rgb: "rgb(230,230,250)",
    rgba: "rgba(230,230,250,0.7)",
    hsl: "hsl(240,67%,95%)",
  },
  {
    name: "thistle",
    hex: "#d8bfd8",
    rgb: "rgb(216,191,216)",
    rgba: "rgba(216,191,216,0.7)",
    hsl: "hsl(300,25%,80%)",
  },
  {
    name: "plum",
    hex: "#dda0dd",
    rgb: "rgb(221,160,221)",
    rgba: "rgba(221,160,221,0.7)",
    hsl: "hsl(300,48%,75%)",
  },
  {
    name: "violet",
    hex: "#ee82ee",
    rgb: "rgb(238,130,238)",
    rgba: "rgba(238,130,238,0.7)",
    hsl: "hsl(300,77%,73%)",
  },
  {
    name: "orchid",
    hex: "#da70d6",
    rgb: "rgb(218,112,214)",
    rgba: "rgba(218,112,214,0.7)",
    hsl: "hsl(303,59%,65%)",
  },
  {
    name: "fuchsia",
    hex: "#ff00ff",
    rgb: "rgb(255,0,255)",
    rgba: "rgba(255,0,255,0.7)",
    hsl: "hsl(300,100%,50%)",
  },
  {
    name: "magenta",
    hex: "#ff00ff",
    rgb: "rgb(255,0,255)",
    rgba: "rgba(255,0,255,0.7)",
    hsl: "hsl(300,100%,50%)",
  },
  {
    name: "mediumorchid",
    hex: "#ba55d3",
    rgb: "rgb(186,85,211)",
    rgba: "rgba(186,85,211,0.7)",
    hsl: "hsl(289,59%,59%)",
  },
  {
    name: "mediumpurple",
    hex: "#9370db",
    rgb: "rgb(147,112,219)",
    rgba: "rgba(147,112,219,0.7)",
    hsl: "hsl(260,60%,65%)",
  },
  {
    name: "blueviolet",
    hex: "#8a2be2",
    rgb: "rgb(138,43,226)",
    rgba: "rgba(138,43,226,0.7)",
    hsl: "hsl(272,76%,53%)",
  },
  {
    name: "darkviolet",
    hex: "#9400d3",
    rgb: "rgb(148,0,211)",
    rgba: "rgba(148,0,211,0.7)",
    hsl: "hsl(283,100%,42%)",
  },
  {
    name: "darkorchid",
    hex: "#9932cc",
    rgb: "rgb(153,50,204)",
    rgba: "rgba(153,50,204,0.7)",
    hsl: "hsl(281,61%,50%)",
  },
  {
    name: "darkmagenta",
    hex: "#8b008b",
    rgb: "rgb(139,0,139)",
    rgba: "rgba(139,0,139,0.7)",
    hsl: "hsl(300,100%,28%)",
  },
  {
    name: "purple",
    hex: "#800080",
    rgb: "rgb(128,0,128)",
    rgba: "rgba(128,0,128,0.7)",
    hsl: "hsl(300,100%,26%)",
  },
  {
    name: "indigo",
    hex: "#4b0082",
    rgb: "rgb(75,0,130)",
    rgba: "rgba(75,0,130,0.7)",
    hsl: "hsl(275,100%,26%)",
  },
  {
    name: "slateblue",
    hex: "#6a5acd",
    rgb: "rgb(106,90,205)",
    rgba: "rgba(106,90,205,0.7)",
    hsl: "hsl(249,54%,58%)",
  },
  {
    name: "darkslateblue",
    hex: "#483d8b",
    rgb: "rgb(72,61,139)",
    rgba: "rgba(72,61,139,0.7)",
    hsl: "hsl(249,39%,40%)",
  },
  {
    name: "gainsboro",
    hex: "#dcdcdc",
    rgb: "rgb(220,220,220)",
    rgba: "rgba(220,220,220,0.7)",
    hsl: "hsl(0,0%,87%)",
  },
  {
    name: "lightgrey",
    hex: "#d3d3d3",
    rgb: "rgb(211,211,211)",
    rgba: "rgba(211,211,211,0.7)",
    hsl: "hsl(0,0%,83%)",
  },
  {
    name: "silver",
    hex: "#c0c0c0",
    rgb: "rgb(192,192,192)",
    rgba: "rgba(192,192,192,0.7)",
    hsl: "hsl(0,0%,76%)",
  },
  {
    name: "darkgray",
    hex: "#a9a9a9",
    rgb: "rgb(169,169,169)",
    rgba: "rgba(169,169,169,0.7)",
    hsl: "hsl(0,0%,67%)",
  },
  {
    name: "gray",
    hex: "#808080",
    rgb: "rgb(128,128,128)",
    rgba: "rgba(128,128,128,0.7)",
    hsl: "hsl(0,0%,51%)",
  },
  {
    name: "dimgray",
    hex: "#696969",
    rgb: "rgb(105,105,105)",
    rgba: "rgba(105,105,105,0.7)",
    hsl: "hsl(0,0%,42%)",
  },
  {
    name: "lightslategray",
    hex: "#778899",
    rgb: "rgb(119,136,153)",
    rgba: "rgba(119,136,153,0.7)",
    hsl: "hsl(210,15%,54%)",
  },
  {
    name: "slategray",
    hex: "#708090",
    rgb: "rgb(112,128,144)",
    rgba: "rgba(112,128,144,0.7)",
    hsl: "hsl(210,13%,51%)",
  },
  {
    name: "darkslategray",
    hex: "#2f4f4f",
    rgb: "rgb(47,79,79)",
    rgba: "rgba(47,79,79,0.7)",
    hsl: "hsl(180,26%,25%)",
  },
  {
    name: "black",
    hex: "#000000",
    rgb: "rgb(0,0,0)",
    rgba: "rgba(0,0,0,0.7)",
    hsl: "hsl(0,0%,0%)",
  },
  {
    name: "white",
    hex: "#ffffff",
    rgb: "rgb(255,255,255)",
    rgba: "rgba(255,255,255,0.7)",
    hsl: "hsl(0,0%,100%)",
  },
  {
    name: "snow",
    hex: "#fffafa",
    rgb: "rgb(255,250,250)",
    rgba: "rgba(255,250,250,0.7)",
    hsl: "hsl(0,100%,100%)",
  },
  {
    name: "honeydew",
    hex: "#f0fff0",
    rgb: "rgb(240,255,240)",
    rgba: "rgba(240,255,240,0.7)",
    hsl: "hsl(120,100%,98%)",
  },
  {
    name: "mintcream",
    hex: "#f5fffa",
    rgb: "rgb(245,255,250)",
    rgba: "rgba(245,255,250,0.7)",
    hsl: "hsl(150,100%,99%)",
  },
  {
    name: "azure",
    hex: "#f0ffff",
    rgb: "rgb(240,255,255)",
    rgba: "rgba(240,255,255,0.7)",
    hsl: "hsl(180,100%,98%)",
  },
  {
    name: "aliceblue",
    hex: "#f0f8ff",
    rgb: "rgb(240,248,255)",
    rgba: "rgba(240,248,255,0.7)",
    hsl: "hsl(208,100%,98%)",
  },
  {
    name: "ghostwhite",
    hex: "#f8f8ff",
    rgb: "rgb(248,248,255)",
    rgba: "rgba(248,248,255,0.7)",
    hsl: "hsl(240,100%,99%)",
  },
  {
    name: "whitesmoke",
    hex: "#f5f5f5",
    rgb: "rgb(245,245,245)",
    rgba: "rgba(245,245,245,0.7)",
    hsl: "hsl(0,0%,97%)",
  },
  {
    name: "seashell",
    hex: "#fff5ee",
    rgb: "rgb(255,245,238)",
    rgba: "rgba(255,245,238,0.7)",
    hsl: "hsl(25,100%,97%)",
  },
  {
    name: "beige",
    hex: "#f5f5dc",
    rgb: "rgb(245,245,220)",
    rgba: "rgba(245,245,220,0.7)",
    hsl: "hsl(60,56%,92%)",
  },
  {
    name: "oldlace",
    hex: "#fdf5e6",
    rgb: "rgb(253,245,230)",
    rgba: "rgba(253,245,230,0.7)",
    hsl: "hsl(40,86%,95%)",
  },
  {
    name: "floralwhite",
    hex: "#fffaf0",
    rgb: "rgb(255,250,240)",
    rgba: "rgba(255,250,240,0.7)",
    hsl: "hsl(40,100%,98%)",
  },
  {
    name: "ivory",
    hex: "#fffff0",
    rgb: "rgb(255,255,240)",
    rgba: "rgba(255,255,240,0.7)",
    hsl: "hsl(60,100%,98%)",
  },
  {
    name: "antiquewhite",
    hex: "#faebd7",
    rgb: "rgb(250,235,215)",
    rgba: "rgba(250,235,215,0.7)",
    hsl: "hsl(35,78%,92%)",
  },
  {
    name: "linen",
    hex: "#faf0e6",
    rgb: "rgb(250,240,230)",
    rgba: "rgba(250,240,230,0.7)",
    hsl: "hsl(30,67%,95%)",
  },
  {
    name: "lavenderblush",
    hex: "#fff0f5",
    rgb: "rgb(255,240,245)",
    rgba: "rgba(255,240,245,0.7)",
    hsl: "hsl(340,100%,98%)",
  },
  {
    name: "mistyrose",
    hex: "#ffe4e1",
    rgb: "rgb(255,228,225)",
    rgba: "rgba(255,228,225,0.7)",
    hsl: "hsl(7,100%,95%)",
  },
];
