import { useEffect, useRef } from "react";

function useAdInView() {
  const container = useRef(null);

  useEffect(() => {
    const adObserver = new IntersectionObserver(onAdIntersection, {
      rootMargin: "0px 0px",
      threshold: 0.1,
    });

    function onAdIntersection(entries) {
      if (!entries || entries.length <= 0) {
        return;
      }

      if (entries[0].isIntersecting) {
        let adsbygoogle;

        (adsbygoogle = window.adsbygoogle || []).push({});

        adObserver.disconnect();
      }
    }

    if (window && "IntersectionObserver" in window) {
      if (container && container.current) {
        adObserver.observe(container.current);
      }
    }
  }, [container]);

  return [container];
}

export default useAdInView;
