import React from "react";
import { useDropzone } from "react-dropzone";
import {
  dropzone_wrapper,
  active,
} from "../../../styles/modules/palette_from_image.module.css";

function Dropzone({ onDrop, accept, locale }) {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
  });

  // console.log(isDragActive);

  return (
    <div
      className={`${dropzone_wrapper} ${isDragActive ? active : ""}`}
      {...getRootProps()}
    >
      <input className="dropzone-input" {...getInputProps()} />
      <div className="text-center">
        {isDragActive ? (
          <p className="dropzone-content">
            {locale === "es"
              ? "Suelte para dejar los archivos aquí"
              : locale === "en"
              ? "Release to drop files here"
              : locale === "pt"
              ? "Solte para soltar arquivos aqui"
              : locale === "fr"
              ? "Relâchez pour déposer les fichiers ici"
              : null}
          </p>
        ) : (
          <p className="dropzone-content">
            {locale === "es"
              ? "Arrastra la imagen para sacar sus colores o haz clic para buscarla en tu dispositivo."
              : locale === "en"
              ? "Drag the image to get its colors or click to find it on your device."
              : locale === "pt"
              ? "Arraste a imagem para obter suas cores ou clique para encontrá-la em seu dispositivo."
              : locale === "fr"
              ? "Faites glisser l'image pour obtenir ses couleurs ou cliquez pour la trouver sur votre appareil."
              : null}
          </p>
        )}
      </div>
    </div>
  );
}

export default Dropzone;
