import React from "react";

import { image_wrapper } from "../../../styles/modules/palette_from_image.module.css";

function Image({ images = [] }) {
  return (
    <div>
      {images.map(image => (
        <image className={image_wrapper} key={image.id} src={image.src} />
      ))}
    </div>
  );
}

export default Image;
