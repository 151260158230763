import React from "react";
import TableCodesColor from "./TableCodesColor";
import ColorComposition from "./ColorComposition";
import { wrapper_1_column } from "../../../../src/styles/modules/color_page.module.css";

function ColorCompositionSection({ colorName, colorHex, locale = "es" }) {
  const headingText =
    locale === "es"
      ? `Códigos html y css del color ${colorName}`
      : locale === "en"
      ? `HTML and CSS codes for the color ${colorName}`
      : locale === "pt"
      ? `Códigos HTML e CSS para a cor ${colorName}`
      : locale === "fr"
      ? `Codes HTML et CSS pour la couleur ${colorName}`
      : "";

  return (
    <div>
      <h2 className="heading_color_page">{headingText}</h2>
      <div className={wrapper_1_column}>
        <TableCodesColor colorRender={colorHex} locale={locale} />
        <ColorComposition
          colorName={colorName}
          colorHex={colorHex}
          locale={locale}
        />
      </div>
    </div>
  );
}

export default ColorCompositionSection;
