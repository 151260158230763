import chroma from "chroma-js";
import { convertToCmyk, convertToHsl } from "./convertColors";

export function convertRenderColor({ color }, format) {
  switch (format) {
    case "hex":
      return chroma(color).hex();
    case "rgb":
      return chroma(color).css();
    case "hsl":
      return convertToHsl(chroma(color).hsl());
    case "cmyk":
      return convertToCmyk(chroma(color).cmyk());

    default:
      return color;
  }
}
