import { useState, useEffect, useContext } from "react";
import { ContextLocale } from "../context/ContextLocale";

function useCopyToClipboard(value = "") {
  const { lang } = useContext(ContextLocale);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState("");

  const handleCopy = () => {
    const promiseClipboard = navigator.clipboard.writeText(value);

    setShowMessage(true);

    promiseClipboard
      .then(() => {
        setMessage(
          lang === "es"
            ? "Copiado!!"
            : lang === "en"
            ? "Copied!!"
            : lang === "pt"
            ? "copiado!!"
            : lang === "fr"
            ? "Copié !!"
            : null
        );
      })
      .catch(() => {
        setMessage(
          lang === "es"
            ? "Algo salió mal, inténtalo de nuevo"
            : lang === "en"
            ? "Something went wrong, try again"
            : lang === "pt"
            ? "Algo deu errado, tente novamente"
            : lang === "fr"
            ? "Quelque chose s'est mal passé, réessayez"
            : null
        );
      });
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowMessage(false);
    }, 2000);

    return () => clearTimeout(timeout);
  });

  return [message, showMessage, handleCopy];
}

export default useCopyToClipboard;
