import React, { useState } from "react";
import { randomPalette } from "./GeneratePalette/GeneratePalette";
import GridImages from "./GridImages";
import PalettePost from "./PalettePost";
import {
  more_palettes_button,
  more_palettes_button_container,
  palettes_lists_wrapper,
} from "../styles/modules/palette_post.module.css";
import { Link } from "gatsby";

function PaletteLists({ locale, isHome = true }) {
  const [palettesLists, setPaletteLists] = useState([
    ["#68da3e", "#00c6ab", "#6aa3b4", "#416864", "#223026"],
    ["#ebb7ce", "#b38471", "#5c5e36", "#1d3d33", "#0c181c"],
    ["#ef43ef", "#ff5f7c", "#e6a15c", "#9e6788", "#394651"],
    ["#ebe6ea", "#e4c5c4", "#c8ad8d", "#6f7357", "#273a2d"],
    ["#a0fb0e", "#20c67a", "#3f8880", "#9dc09d", "#fef6cd"],
    ["#f50400", "#d6a735", "#d6ebc1", "#ff8862", "#b93af8"],
    ["#d6921e", "#98b5be", "#9b207a", "#911dd1", "#7f492c"],
    ["#407a8d", "#1dc019", "#e665a2", "#b626f4", "#9783c6"],
    ["#2e8322", "#d4de3c", "#4af7cd", "#e57fd8", "#53f4ca"],
    ["#750f05", "#ee7fff", "#ec8b35", "#60a046", "#0af299"],
    ["#b31055", "#409505", "#d5e732", "#e53482", "#366623"],
    ["#97f8d8", "#24a312", "#6f33d3", "#62fa2a", "#05079d"],
    ["#4647e3", "#e1e3e1", "#0c3c68", "#ee3d42", "#aed201"],
    ["#2c1e1a", "#161bd6", "#8302e5", "#e15de7", "#79380b"],
    ["#33a25c", "#935b2b", "#a57d96", "#3833cb", "#c34457"],
  ]);

  const handleClick = () => {
    setPaletteLists(prevState => [
      ...prevState,
      randomPalette(),
      randomPalette(),
      randomPalette(),
    ]);
  };

  const textButton =
    locale === "es"
      ? "generar más paletas"
      : locale === "en"
      ? "generate more palettes"
      : locale === "pt"
      ? "gerar mais paletas"
      : locale === "fr"
      ? "générer plus de palettes"
      : null;

  const slugButton =
    locale === "es"
      ? "/generar-paleta/"
      : locale === "en"
      ? "/en/generate-palette/"
      : locale === "pt"
      ? "/pt/gerar-paleta/"
      : locale === "fr"
      ? "/fr/generer-palette/"
      : null;

  return (
    <div className={palettes_lists_wrapper}>
      <GridImages>
        {palettesLists.map((palette, i) => (
          <PalettePost key={i} palette={palette} />
        ))}
      </GridImages>
      <div className={more_palettes_button_container}>
        {isHome ? (
          <Link className={more_palettes_button} to={slugButton}>
            {textButton}
          </Link>
        ) : (
          <button className={more_palettes_button} onClick={handleClick}>
            {textButton}
          </button>
        )}
      </div>
    </div>
  );
}

export default PaletteLists;
