// extracted by mini-css-extract-plugin
export var color_box = "color_page-module--color_box--7f942";
export var color_page_ad_wrapper = "color_page-module--color_page_ad_wrapper--fc5ce";
export var color_page_breadcrumb = "color_page-module--color_page_breadcrumb--964b5";
export var color_page_breadcrumb_wrapper = "color_page-module--color_page_breadcrumb_wrapper--3d32a";
export var color_page_main = "color_page-module--color_page_main--70042";
export var color_page_wrapper = "color_page-module--color_page_wrapper--45e66";
export var second_section_color_page = "color_page-module--second_section_color_page--154ac";
export var second_section_color_page_main = "color_page-module--second_section_color_page_main--a56fb";
export var section_color_page_white = "color_page-module--section_color_page_white--6e297";
export var wrapper_1_column = "color_page-module--wrapper_1_column--4adc4";