// extracted by mini-css-extract-plugin
export var animatetop = "modal-module--animatetop--98cf3";
export var appear = "modal-module--appear--eed84";
export var button_close = "modal-module--button_close--8ef0e";
export var button_download = "modal-module--button_download--b1a8c";
export var input_name_image = "modal-module--input_name_image--0c782";
export var modal_body = "modal-module--modal_body--bb106";
export var modal_dialog = "modal-module--modal_dialog--67315";
export var modal_header = "modal-module--modal_header--6215a";
export var modal_header_utils = "modal-module--modal_header_utils--ad125";
export var modal_title = "modal-module--modal_title--68886";
export var modal_wrapper = "modal-module--modal_wrapper--b32f3";
export var slideIn = "modal-module--slide-in--0f6b1";