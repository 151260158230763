import { useState, useCallback } from "react";

function useToggle(initialValue = false) {
  const [isOpen, setIsopen] = useState(initialValue);

  const handleClick = useCallback(() => {
    setIsopen(!isOpen);
  }, [isOpen]);
  return [isOpen, handleClick];
}

export default useToggle;
