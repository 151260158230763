import React, { useContext, useEffect } from "react";
import downloadPaletteImage from "../../helpers/downloadPaletteImage";
import { modal_wrapper } from "../../styles/modules/modal.module.css";
import closeIcon from "../../images/close-icon.svg";
import { ContextLocale } from "../../context/ContextLocale";
import {
  modal_dialog,
  modal_content,
  download_button,
  modal_color,
  modal_bullet_points,
  modal_title,
  close_button,
  modal_content_palette,
} from "../../styles/modules/modal_palette.module.css";

import LinkToDetailsColor from "../LinkToDetailsColor";
import { darkOrLightColor } from "../../helpers/darkOrLightColor";
// import useCopyToClipboard from "../../hooks/useCopyToClipboard";

function PaletteModal({ palette, onClose, titlePalette }) {
  const { lang } = useContext(ContextLocale);

  // const [message, showMessage, handleCopy] = useCopyToClipboard(`${color}`);

  const bulletPoinst = {
    es: [
      { text: "Click en Detalles para ver variantes y esquemas de ese color" },
      {
        text: "Clic en el botón descargar para guardar la paleta de formato png",
      },
    ],
    en: [
      { text: "Click on Details to see variants and schemes of that color" },
      { text: "Click the download button to save the palette in png format" },
    ],
    pt: [
      { text: "Clique em Detalhes para ver variantes e esquemas dessa cor" },
      {
        text: "Clique no botão de download para salvar a paleta em formato png",
      },
    ],
    fr: [
      {
        text: "Cliquez sur Détails pour voir les variantes et les schémas de cette couleur",
      },
      {
        text: "Cliquez sur le bouton de téléchargement pour enregistrer la palette au format png",
      },
    ],
  };

  useEffect(() => {
    document.addEventListener("keydown", keydownHandler);
    return () => document.removeEventListener("keydown", keydownHandler);
  });

  function keydownHandler({ key }) {
    switch (key) {
      case "Escape":
        onClose();
        break;
      default:
    }
  }

  const closeModal = () => {
    onClose();
  };

  const { downloadPng } = downloadPaletteImage(palette, titlePalette);

  return (
    <section className={modal_wrapper} onClick={closeModal}>
      <div className={modal_dialog} onClick={e => e.stopPropagation()}>
        <button className={close_button} onClick={closeModal}>
          <img src={closeIcon} alt="close icon" height={15} width={15} />
        </button>
        <div className={modal_content}>
          <div className={modal_content_palette}>
            {palette.map(color => (
              <div className={modal_color} style={{ background: color }}>
                <div>
                  <strong style={{ color: darkOrLightColor(color) }}>
                    {color}
                  </strong>
                  {/* <LinkToDetailsColor color={color} /> */}
                </div>
              </div>
            ))}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              // alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>
              <h4 className={modal_title}>{titlePalette}</h4>
              <ul className={modal_bullet_points}>
                {bulletPoinst[lang].map(item => (
                  <li>{item.text}</li>
                ))}
              </ul>
            </div>
            <div>
              <button className={download_button} onClick={downloadPng}>
                {lang === "es"
                  ? "Descargar"
                  : lang === "en"
                  ? "Download"
                  : lang === "pt"
                  ? "Descarga"
                  : lang === "fr"
                  ? "Télécharger"
                  : null}
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PaletteModal;
