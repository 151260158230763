import React, { useEffect } from "react";
// import { modal_ad } from "../../styles/modules/modal_palette.module.css";
import PlaceholderAd from "./PlaceholderAd";
import { ad_wrapper } from "./styles.module.css";

function AdWithPlaceholder({ ins = "" }) {
  // const [renderAd, setRenderAd] = React.useState(false);
  const [renderPlaceholder, setRenderPlaceholder] = React.useState(true);

  useEffect(() => {
    setTimeout(() => {
      setRenderPlaceholder(false);
      let adsbygoogle;
      if (document.querySelector("#adWitPlaceholder")) {
        (adsbygoogle = window.adsbygoogle || []).push({});
      }
    }, 1500);
  }, []);

  return (
    <div className={ad_wrapper}>
      <div
        id="adWitPlaceholder"
        dangerouslySetInnerHTML={{ __html: ins }}
      ></div>
      {renderPlaceholder ? <PlaceholderAd /> : null}
    </div>
  );
}

export default AdWithPlaceholder;
