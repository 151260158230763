import React, { useState, useEffect, createContext } from "react";

export const ContextCookieConsent = createContext();

export const CookiesConsentProvider = ({ children }) => {
  const [show, setShow] = useState(false);

  const changeShow = () => {
    setShow(!show);
  };

  useEffect(() => {
    if (!localStorage.getItem("cookiesConsent")) {
      localStorage.setItem("cookiesConsent", true);
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("cookiesConsent") === "true") {
      setShow(localStorage.getItem("cookiesConsent") === "true" ? true : false);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("cookiesConsent", show);
  }, [show]);

  const value = { show, changeShow };

  return (
    <ContextCookieConsent.Provider value={value}>
      {children}
    </ContextCookieConsent.Provider>
  );
};
