import React from "react";
import useToggle from "../../hooks/useToggle";
import {
  question_wrapper,
  question,
  answer,
  active,
  svg_active,
} from "../../styles/modules/faqs.module.css";

function FaqItem({ item }) {
  const [isOpen, handleClick] = useToggle(false);
  return (
    <div className={question_wrapper}>
      <button className={question} onClick={handleClick}>
        <h3>{item.question}</h3>{" "}
        <svg
          className={`${isOpen && svg_active}`}
          viewBox="0 0 320 512"
          width="100"
          title="angle-down"
        >
          <path d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z" />
        </svg>
      </button>

      <p className={`${answer} ${isOpen && active}`}>{item.answer}</p>
    </div>
  );
}

export default FaqItem;
