import React from "react";
import { HexColorInput, HexColorPicker } from "react-colorful";
import MinColorCard from "./MiniColorCard";
import {
  color_picker_scheme_wrapper,
  react_colorful,
  react_colorful_input,
  react_colorful_last_control,
  title_card,
  grid_colors,
} from "../../styles/modules/color_picker_scheme.module.css";

function ColorPickerScheme({
  mainColor,
  setMainColor,
  colorScheme,
  schemeName,
  locale,
}) {
  // const { lang } = useContext(ContextLocale);

  return (
    <div className={color_picker_scheme_wrapper}>
      <div>
        <h3 className={title_card}>
          {locale === "es"
            ? "Escoge el color principal"
            : locale === "en"
            ? "Choose the main color"
            : locale === "pt"
            ? "Escolha a cor principal"
            : locale === "fr" // Agrega esta línea
            ? "Choisissez la couleur principale" // Agrega el valor para el idioma francés
            : ""}
        </h3>
        <div>
          <HexColorPicker
            className={`${title_card} ${react_colorful} ${react_colorful_last_control}`}
            color={mainColor}
            onChange={setMainColor}
          />
          <HexColorInput
            className={react_colorful_input}
            color={mainColor}
            onChange={setMainColor}
          />
        </div>
      </div>
      <div>
        <h3 className={title_card}>{schemeName}</h3>
        <div className={grid_colors}>
          {colorScheme.map((color, i) => (
            <MinColorCard key={i} color={color} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default ColorPickerScheme;
