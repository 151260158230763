import React, { useContext } from "react";
import chroma from "chroma-js";
import { darkOrLightColor } from "../helpers/darkOrLightColor";
import { ContextLocale } from "../context/ContextLocale";
import esColorsLinks from "../../coloreshtml/helpers/esColorsLinks";
import enColorsLinks from "../../coloreshtml/helpers/enColorsLinks";
import frColorsLinks from "../../coloreshtml/helpers/frColorsLinks";
import ptColorsLinks from "../../coloreshtml/helpers/ptColorsLinks";
import { link_to_details_wrapper } from "../styles/modules/utilities.module.css";
import { ColorPageModal } from "./color-page-modal";

const LinkToDetailsColor = ({ color }) => {
  const { lang } = useContext(ContextLocale);

  const matchPathLinkEs = esColorsLinks.filter(
    link => link.colorHex.toLocaleLowerCase() === color.toLocaleLowerCase()
  );

  const matchPathLinkEn = enColorsLinks.filter(
    link => link.colorHex.toLocaleLowerCase() === color.toLocaleLowerCase()
  );

  const matchPathLinkFr = frColorsLinks.filter(
    link => link.colorHex.toLocaleLowerCase() === color.toLocaleLowerCase()
  );

  const matchPathLinkPt = ptColorsLinks.filter(
    link => link.colorHex.toLocaleLowerCase() === color.toLocaleLowerCase()
  );

  const esLinkColor =
    matchPathLinkEs.length > 0
      ? `/colores-html${matchPathLinkEs[0].slug}`
      : `/colors/${chroma(color).hex().replace("#", "")}/`;
  const enLinkColor =
    matchPathLinkEn.length > 0
      ? `/en/html-colors${matchPathLinkEn[0].slug}`
      : `/en/colors/${chroma(color).hex().replace("#", "")}/`;

  const frLinkColor =
    matchPathLinkFr.length > 0
      ? `/fr/couleurs-html${matchPathLinkFr[0].slug}`
      : `/fr/colors/${chroma(color).hex().replace("#", "")}/`;

  const ptLinkColor =
    matchPathLinkPt.length > 0
      ? `/pt/cores-html${matchPathLinkPt[0].slug}`
      : `/pt/colors/${chroma(color).hex().replace("#", "")}/`;

  const linkToColor =
    lang === "en"
      ? enLinkColor
      : lang === "es"
      ? esLinkColor
      : lang === "pt"
      ? ptLinkColor
      : lang === "fr"
      ? frLinkColor
      : null;

  // const htmlColorsSlugs = [
  //   "colores-html",
  //   "html-colors",
  //   "cores-html",
  //   "couleurs-html",
  // ];

  const withModal = linkToColor.includes("/colors/");

  return (
    <>
      {withModal ? (
        <ColorPageModal color={color} />
      ) : (
        <a
          href={linkToColor}
          className={link_to_details_wrapper}
          style={{
            boxShadow: `0px 0px 3px 0px ${darkOrLightColor(color)}`,
            color: darkOrLightColor(color),
            background: "none",
            display: "block",
            width: "100%",

            border: "none",
            padding: ".5rem",
            margin: "0",
          }}
        >
          <svg
            width="16px"
            height="16px"
            viewBox="0 0 16 16"
            xmlns="http://www.w3.org/2000/svg"
            fill={darkOrLightColor(color)}
          >
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              {" "}
              <path
                d="m 12.957031 0.980469 c -0.519531 0.015625 -1.015625 0.234375 -1.375 0.605469 l -1.585937 1.585937 l -1.085938 -1.085937 c -0.097656 -0.09375 -0.226562 -0.148438 -0.363281 -0.148438 c -0.128906 0.003906 -0.253906 0.054688 -0.34375 0.148438 l -2.121094 2.121093 c -0.195312 0.195313 -0.195312 0.511719 0 0.707031 l 0.644531 0.648438 l -5.585937 5.582031 c -0.09375 0.097657 -0.144531 0.222657 -0.144531 0.355469 v 1.792969 l -0.855469 0.851562 c -0.1953125 0.195313 -0.1953125 0.515625 0 0.710938 l 1 1 c 0.195313 0.191406 0.511719 0.191406 0.707031 0 l 0.855469 -0.855469 h 1.792969 c 0.132812 0 0.257812 -0.050781 0.351562 -0.144531 l 5.585938 -5.585938 l 0.648437 0.644531 c 0.195313 0.195313 0.511719 0.195313 0.707031 0 l 2.121094 -2.121093 c 0.195313 -0.195313 0.195313 -0.511719 0 -0.707031 l -1.085937 -1.085938 l 1.585937 -1.585938 c 1.304688 -1.273437 0.367188 -3.488281 -1.453125 -3.433593 z m -5.023437 5.789062 l 1.292968 1.292969 l -2.9375 2.9375 h -2.585937 z m 0 0"
                fill={darkOrLightColor(color)}
              ></path>{" "}
            </g>
          </svg>
          {/* {lang === "es"
            ? "explorar"
            : lang === "en"
            ? "explore"
            : lang === "pt"
            ? "explorar"
            : lang === "fr"
            ? "explorer"
            : null} */}
        </a>
      )}
    </>
  );
};

export default LinkToDetailsColor;
