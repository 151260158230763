import React from "react";
import { Link } from "gatsby";

function InternalLink({ href, children }) {
  const internal = /^\/(?!\/)/.test(href);

  if (internal) {
    return (
      <>
        {href.includes("-nofollow") ? (
          <Link to={href.replace("-nofollow", "")} rel="nofollow">
            {children}
          </Link>
        ) : (
          <Link className="internal_link" to={href}>
            {children}{" "}
            <svg
              height="18"
              viewBox="0 0 18 18"
              width="21"
              xmlns="http://www.w3.org/2000/svg"
              // fill="#aaccdd"
            >
              <g
                fill="none"
                fillRule="evenodd"
                // stroke="#dd2e3b"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                transform="translate(3 4)"
              >
                <path
                  d="m4.17157288 4.87867966v-1.41421357c0-1.56209716 1.26632995-2.82842712 2.82842712-2.82842712s2.82842712 1.26632996 2.82842712 2.82842712v1.41421357m0 2.82842712v2.82842712c0 1.5620972-1.26632995 2.8284271-2.82842712 2.8284271s-2.82842712-1.2663299-2.82842712-2.8284271v-2.82842712"
                  transform="matrix(.70710678 .70710678 -.70710678 .70710678 7 -2.899495)"
                />
                <path d="m4.5 9.5 5-5" />
              </g>
            </svg>{" "}
          </Link>
        )}
      </>
    );
  } else if (href.includes("-nofollow") && !internal) {
    return (
      <a href={href.replace("-nofollow", "")} rel="nofollow">
        {children}
      </a>
    );
  } else if (!internal) {
    return <a href={href}>{children}</a>;
  }
}

export default InternalLink;
