import React from "react";
import { darkOrLightColor } from "../helpers/darkOrLightColor";
import { wrapper } from "../styles/modules/copy_color_message.module.css";

function CopyColorMessage({ colorHex, message }) {
  return (
    <div
      className={wrapper}
      style={{
        background: colorHex ? colorHex : "gray",
        color: colorHex ? darkOrLightColor(colorHex) : "var(--tertiary-color",
      }}
    >
      {message}
    </div>
  );
}

export default CopyColorMessage;
