import React, { useState } from "react";
import Colors from "../PageColorPalette/Colors";
import chroma from "chroma-js";
import {
  wrapper,
  wrapper_grid,
  gradient_result,
  settings,
  heading,
  button_ad,
  colors_controls,
  colors_scale,
} from "./styles.module.css";
import Code from "../../../coloreshtml/template/components/codeSnippetColor/Code";

const GradientGenerator = ({ locale }) => {
  const mainTitle =
    locale === "es"
      ? "Generador de Degradados (gradient) CSS: Crea Exquisitos Efectos Visuales"
      : locale === "en"
      ? "CSS Gradient Generator: Create Exquisite Visual Effects"
      : locale === "pt"
      ? "Gerador de Gradientes CSS: Crie Efeitos Visuais Exquisitos"
      : locale === "fr"
      ? "Générateur de Dégradés (gradient) CSS : Créez d'exquis effets visuels"
      : "";

  const settingsText =
    locale === "es"
      ? "Ajustes del gradiente css"
      : locale === "en"
      ? "CSS Gradient Settings"
      : locale === "pt"
      ? "Configurações do Gradiente CSS"
      : locale === "fr"
      ? "Paramètres du dégradé CSS"
      : "";

  const generatedGradientText =
    locale === "es"
      ? "Degradado generado"
      : locale === "en"
      ? "Generated Gradient"
      : locale === "pt"
      ? "Gradiente Gerado"
      : locale === "fr"
      ? "Dégradé généré"
      : "";

  const rangeColorsText =
    locale === "es"
      ? "Gama de colores css dentro del degradado"
      : locale === "en"
      ? "CSS Color Range within the Gradient"
      : locale === "pt"
      ? "Faixa de Cores CSS dentro do Degradê"
      : locale === "fr"
      ? "Gamme de couleurs CSS dans le dégradé"
      : "";

  const addColorText =
    locale === "es"
      ? "Agregar color"
      : locale === "en"
      ? "Add Color"
      : locale === "pt"
      ? "Adicionar Cor"
      : locale === "fr"
      ? "Ajouter une couleur"
      : "";

  const orientationText =
    locale === "es"
      ? "Orientación"
      : locale === "en"
      ? "Orientation"
      : locale === "pt"
      ? "Orientação"
      : locale === "fr"
      ? "Orientation"
      : "";

  const [colorStops, setColorStops] = useState([
    { color: "#d71fef", stop: 0 },
    { color: "#0083f5", stop: 100 },
  ]);

  const [colorScale, setcolorScale] = useState(
    colorStops.map(color => color.color)
  );

  // console.log(colorStops);

  const [orientation, setOrientation] = useState("to right");

  // linear-gradient, radial-gradient conic-gradient()
  const handleColorChange = (index, e) => {
    const newColorStops = [...colorStops];
    newColorStops[index].color = e.target.value;
    setColorStops(newColorStops);
    setcolorScale(newColorStops.map(color => color.color));
  };

  const handleStopChange = (index, e) => {
    const newColorStops = [...colorStops];
    newColorStops[index].stop = e.target.value;
    setColorStops(newColorStops);
  };

  const handleOrientationChange = e => {
    setOrientation(e.target.value);
  };

  const addColorStop = () => {
    const newColorStops = [...colorStops];
    newColorStops.push({ color: chroma.random().hex(), stop: 50 });
    setColorStops(newColorStops);
    setcolorScale(newColorStops.map(color => color.color));
  };

  const removeColorStop = index => {
    const newColorStops = [...colorStops];
    newColorStops.splice(index, 1);
    setColorStops(newColorStops);
    setcolorScale(newColorStops.map(color => color.color));
  };

  const gradientStyle = {
    backgroundImage: `linear-gradient(${orientation}, ${colorStops
      .map(colorStop => `${colorStop.color} ${colorStop.stop}%`)
      .join(", ")})`,
    width: "100%",
    height: "100%",
  };

  return (
    <section className={wrapper}>
      <h1>{mainTitle}</h1>
      <div className={wrapper_grid}>
        <div className={settings}>
          <h2 className={heading}>{settingsText}s</h2>
          <div>
            <label>{orientationText}: </label>
            <select value={orientation} onChange={handleOrientationChange}>
              <option value="to right">To Right</option>
              <option value="to left">To Left</option>
              <option value="to top">To Top</option>
              <option value="to bottom">To Bottom</option>
              <option value="to top right">To Top Right</option>
              <option value="to top left">To Top Left</option>
              <option value="to bottom right">To Bottom Right</option>
              <option value="to bottom left">To Bottom Left</option>
            </select>
          </div>
          {colorStops.map((colorStop, index) => (
            <div className={colors_controls} key={index}>
              <input
                type="color"
                value={colorStop.color}
                onChange={e => handleColorChange(index, e)}
                style={{
                  backgroundColor: colorStop.color,
                  borderColor: "#ccc",
                  boxShadow: "0 0 5px #ddd",
                  borderRadius: "5px",
                  outline: "none",
                  color: "white",
                }}
              />
              <input
                type="range"
                min={0}
                max={100}
                value={colorStop.stop}
                onChange={e => handleStopChange(index, e)}
              />
              <button
                style={{ border: "none" }}
                onClick={() => removeColorStop(index)}
              >
                <svg
                  fill="#ff0550"
                  width="20px"
                  height="20px"
                  viewBox="0 0 32 32"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  stroke="#ff0550"
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke="#CCCCCC"
                    strokeWidth="1.088"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <title>trash-bin</title>{" "}
                    <path d="M2.016 8q0 0.832 0.576 1.44t1.408 0.576v16q0 2.496 1.76 4.224t4.256 1.76h12q2.464 0 4.224-1.76t1.76-4.224v-16q0.832 0 1.408-0.576t0.608-1.44-0.608-1.408-1.408-0.576h-5.984q0-2.496-1.76-4.256t-4.256-1.76-4.256 1.76-1.728 4.256h-6.016q-0.832 0-1.408 0.576t-0.576 1.408zM8 26.016v-16h16v16q0 0.832-0.576 1.408t-1.408 0.576h-12q-0.832 0-1.44-0.576t-0.576-1.408zM10.016 26.016h1.984v-14.016h-1.984v14.016zM14.016 26.016h4v-14.016h-4v14.016zM14.016 6.016q0-0.832 0.576-1.408t1.408-0.608 1.408 0.608 0.608 1.408h-4zM20 26.016h2.016v-14.016h-2.016v14.016z"></path>{" "}
                  </g>
                </svg>
              </button>
            </div>
          ))}
          <button className={button_ad} onClick={addColorStop}>
            <svg
              width="26px"
              height="26px"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              fill="#00000000"
              stroke="#00000000"
            >
              <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <title></title>{" "}
                <g id="Complete">
                  {" "}
                  <g id="add-square">
                    {" "}
                    <g>
                      {" "}
                      <rect
                        data-name="--Rectangle"
                        fill="none"
                        height="20"
                        id="_--Rectangle"
                        rx="2"
                        ry="2"
                        stroke="#fff"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        strokeWidth="2"
                        width="20"
                        x="2"
                        y="2"
                      ></rect>{" "}
                      <line
                        fill="none"
                        stroke="#fff"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        strokeWidth="2"
                        x1="15.5"
                        x2="8.5"
                        y1="12"
                        y2="12"
                      ></line>{" "}
                      <line
                        fill="none"
                        stroke="#fff"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        strokeWidth="2"
                        x1="12"
                        x2="12"
                        y1="15.5"
                        y2="8.5"
                      ></line>{" "}
                    </g>{" "}
                  </g>{" "}
                </g>{" "}
              </g>
            </svg>
            {addColorText}
          </button>
        </div>
        <div>
          <h2 className={heading}>{generatedGradientText}</h2>
          <div className={gradient_result} style={gradientStyle}></div>
        </div>

        <div className={colors_scale}>
          <h2 className={heading}>{rangeColorsText} </h2>
          <Colors
            palette={chroma.scale(colorScale).mode("lch").colors(7)}
            withLinkColor={false}
          />
        </div>
      </div>
      <Code
        snippet={`.element{
  background-image: ${gradientStyle.backgroundImage};
}`}
        language="css"
      />
    </section>
  );
};

export default GradientGenerator;
