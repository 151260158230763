import React from "react";
import { MDXProvider } from "@mdx-js/react";
import { FormatColorProvider } from "./src/context/ContextFormatColor";
import { CookiesConsentProvider } from "./src/context/ContextCookieConsent";
import ImagePalettePost from "./src/components/ImagePalettePost";
import GridImages from "./src/components/GridImages";
import InternalLink from "./src/components/InternalLink";
import ColorPickerScheme from "./src/components/PostColorScheme/ColorPickerScheme";
import PalettePost from "./src/components/PalettePost";
import PaletteLists from "./src/components/PaletteLists";
import { LanguageLocaleProvider } from "./src/context/ContextLocale";
import "./src/styles/globals.css";
import Faqs from "./src/components/Faqs/Faqs";
import AdWithObserver from "./src/components/Ads/AdWithObserver";
import AdManaWithObserver from "./src/components/Ads/AdManaWithObserver";
import AdWithPlaceholder from "./src/components/Ads/AdWithPlaceholder";
import AdManaWithPlaceholder from "./src/components/Ads/AdManaWithPlaceHolder";
import LinkToTopicNavCluster from "./src/components/LinkToTopicNavCluster";

const shortCodes = {
  a: ({ href, children }) => (
    <InternalLink href={href}>{children}</InternalLink>
  ),
  GridImages,
  ImagePalettePost,
  ColorPickerScheme,
  PalettePost,
  PaletteLists,
  Faqs,
  AdWithObserver,
  AdManaWithObserver,
  AdWithPlaceholder,
  AdManaWithPlaceholder,
  LinkToTopicNavCluster,
};

export const wrapRootElement = ({ element }) => (
  <MDXProvider components={shortCodes}>
    <LanguageLocaleProvider>
      <CookiesConsentProvider>
        <FormatColorProvider> {element}</FormatColorProvider>
      </CookiesConsentProvider>
    </LanguageLocaleProvider>
  </MDXProvider>
);

export const onRouteUpdate = ({ prevLocation }) => {
  const googletag = window.googletag || {};
  googletag.cmd = googletag.cmd || [];

  const refreshAllSlots = () => {
    googletag.cmd.push(function () {
      googletag.pubads().refresh();
    });
  };

  if (prevLocation !== null) {
    setTimeout(() => {
      refreshAllSlots();
    }, 1500);
  }
  //
};
