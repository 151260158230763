export const enArmyGreenPalettes = [
  {
    title: "Monochromatic",
    palette: ["#4B5320", "#6B8E23", "#556B2F", "#8FBC8F", "#708090"],
    description: "A monochromatic palette based on army green.",
    imageName: "monochromatic_army_green",
  },
  {
    title: "Analogous",
    palette: ["#4B5320", "#6B8E23", "#808000", "#556B2F", "#8FBC8F"],
    description:
      "An analogous palette with colors adjacent to army green on the color wheel.",
    imageName: "analogous_army_green",
  },
  {
    title: "Complementary",
    palette: ["#4B5320", "#8B0000", "#6B8E23", "#00008B", "#556B2F"],
    description:
      "A palette featuring army green and its complementary color, red-brown.",
    imageName: "complementary_army_green",
  },
  {
    title: "Triadic",
    palette: ["#4B5320", "#00008B", "#8B0000", "#556B2F", "#8B008B"],
    description: "A triadic palette featuring army green, blue, and red.",
    imageName: "triadic_army_green",
  },
  {
    title: "Deep Forest Greens with Hints of Olive",
    palette: ["#454B1B", "#3B4221", "#4E5325", "#6B7132", "#788153"],
    description: "Rich, earthy tones reminiscent of dense woodland foliage.",
    imageName: "deep_forest_olive",
  },
  {
    title: "Classic Military Camouflage",
    palette: ["#454B1B", "#3B4221", "#6B7132", "#9B9C5E", "#AAB37D"],
    description: "Iconic hues used in traditional army camouflage patterns.",
    imageName: "classic_camouflage",
  },
  {
    title: "Urban Jungle Greens with Grey Accents",
    palette: ["#454B1B", "#3B4221", "#5E6752", "#858D81", "#A7ACA4"],
    description: "Muted greens mingling with urban concrete-inspired grays.",
    imageName: "urban_jungle_greys",
  },
  {
    title: "Earthy Browns and Greens for Outdoors",
    palette: ["#454B1B", "#3B4221", "#6B5C42", "#8C7E65", "#A69C85"],
    description:
      "Natural tones ideal for outdoor and adventure-themed designs.",
    imageName: "earthy_browns_outdoors",
  },
  {
    title: "Military-Inspired Khaki and Olive Tones",
    palette: ["#454B1B", "#3B4221", "#827D5E", "#AAA587", "#CAC4AB"],
    description:
      "Classic khaki and olive shades commonly seen in army uniforms.",
    imageName: "army_khaki_olive",
  },
  {
    title: "Safari Greens with Sandy Accents",
    palette: ["#454B1B", "#3B4221", "#91855E", "#B4A982", "#CCC29C"],
    description:
      "Earthy greens paired with sandy tones reminiscent of savannah landscapes.",
    imageName: "safari_greens_sandy",
  },
  {
    title: "Vintage Military Surplus Palette",
    palette: ["#454B1B", "#3B4221", "#7C7B5D", "#A5A68A", "#C4C4B1"],
    description:
      "Weathered greens and browns evocative of vintage military gear.",
    imageName: "vintage_surplus",
  },
  {
    title: "Olive Drab with Urban Industrial Greys",
    palette: ["#454B1B", "#3B4221", "#696B6C", "#8E9294", "#B9BDBC"],
    description:
      "army greens paired with urban industrial grays for a modern twist.",
    imageName: "olive_drab_industrial_greys",
  },
  {
    title: "Camo-inspired Greens with Desert Tan",
    palette: ["#454B1B", "#3B4221", "#847D5E", "#B3A887", "#D6CCAC"],
    description: "Camouflage greens paired with desert-inspired tan hues.",
    imageName: "camo_desert_tan",
  },
  {
    title: "Mossy Greens with Earthy Browns",
    palette: ["#454B1B", "#3B4221", "#6B684E", "#908E7A", "#ADA996"],
    description: "Lush mossy greens combined with earthy brown undertones.",
    imageName: "mossy_greens_browns",
  },
  {
    title: "Military Olive with Dark Slate Accents",
    palette: ["#454B1B", "#3B4221", "#5C5F5E", "#848887", "#A7ACAB"],
    description:
      "Rich olive greens paired with dark slate hues for a sophisticated look.",
    imageName: "army_olive_slate",
  },
  {
    title: "Woodland Greens with Touches of Bark",
    palette: ["#454B1B", "#3B4221", "#5E5F52", "#868A7C", "#ADB2A3"],
    description:
      "Green tones reminiscent of woodland foliage with hints of bark brown.",
    imageName: "woodland_greens_bark",
  },
  {
    title: "Jungle Greens with Mossy Accents",
    palette: ["#454B1B", "#3B4221", "#54624F", "#778577", "#A2AFA5"],
    description: "Deep jungle greens complemented by mossy undertones.",
    imageName: "jungle_greens_mossy",
  },
  {
    title: "Military Greens with Dusty Sand",
    palette: ["#454B1B", "#3B4221", "#8E8364", "#B1A78B", "#D1CDB2"],
    description:
      "Classic military greens paired with dusty desert sand shades.",
    imageName: "military_greens_sand",
  },
  {
    title: "Forest Greens with Mossy Underbrush",
    palette: ["#454B1B", "#3B4221", "#4E5E41", "#758C6F", "#97AA92"],
    description:
      "Greens reminiscent of a forest floor, with hints of mossy underbrush.",
    imageName: "forest_greens_mossy",
  },
  {
    title: "Classic Olive Greens with Taupe",
    palette: ["#454B1B", "#3B4221", "#7D7D64", "#A6A68A", "#C5C4B0"],
    description: "Timeless olive greens complemented by neutral taupe shades.",
    imageName: "classic_olive_taupe",
  },
  {
    title: "Rustic Greens with Weathered Wood",
    palette: ["#454B1B", "#3B4221", "#756C4F", "#9C9175", "#BFB69A"],
    description:
      "Rustic greens paired with the warmth of weathered wood tones.",
    imageName: "rustic_greens_wood",
  },
];

export const esArmyGreenPalettes = [
  {
    title: "Monocromático",
    palette: ["#4B5320", "#6B8E23", "#556B2F", "#8FBC8F", "#708090"],
    description: "Una paleta monocromática basada en verde militar.",
    imageName: "monochromatic_army_green",
  },
  {
    title: "Análogo",
    palette: ["#4B5320", "#6B8E23", "#808000", "#556B2F", "#8FBC8F"],
    description:
      "Una paleta análoga con colores adyacentes al verde militar en el círculo cromático.",
    imageName: "analogous_army_green",
  },
  {
    title: "Complementario",
    palette: ["#4B5320", "#8B0000", "#6B8E23", "#00008B", "#556B2F"],
    description:
      "Una paleta que presenta verde militar y su color complementario, marrón rojizo.",
    imageName: "complementary_army_green",
  },
  {
    title: "Tríada",
    palette: ["#4B5320", "#00008B", "#8B0000", "#556B2F", "#8B008B"],
    description: "Una paleta tríada con verde militar, azul y rojo.",
    imageName: "triadic_army_green",
  },
  {
    title: "Verdes Profundos del Bosque con Toques de Oliva",
    palette: ["#454B1B", "#3B4221", "#4E5325", "#6B7132", "#788153"],
    description:
      "Tonos ricos y terrosos reminiscentes de la densa vegetación del bosque.",
    imageName: "deep_forest_olive",
  },
  {
    title: "Clásico Camuflaje Militar",
    palette: ["#454B1B", "#3B4221", "#6B7132", "#9B9C5E", "#AAB37D"],
    description:
      "Tonos icónicos utilizados en los patrones tradicionales de camuflaje militar.",
    imageName: "classic_camouflage",
  },
  {
    title: "Verdes de la Jungla Urbana con Acentos Grises",
    palette: ["#454B1B", "#3B4221", "#5E6752", "#858D81", "#A7ACA4"],
    description:
      "Verdes apagados mezclados con grises inspirados en el concreto urbano.",
    imageName: "urban_jungle_greys",
  },
  {
    title: "Marrones Terrosos y Verdes para Exteriores",
    palette: ["#454B1B", "#3B4221", "#6B5C42", "#8C7E65", "#A69C85"],
    description:
      "Tonos naturales ideales para diseños al aire libre y temáticos de aventuras.",
    imageName: "earthy_browns_outdoors",
  },
  {
    title: "Tonos de Caqui y Oliva Inspirados en lo Militar",
    palette: ["#454B1B", "#3B4221", "#827D5E", "#AAA587", "#CAC4AB"],
    description:
      "Tonos clásicos de caqui y oliva comúnmente vistos en uniformes militares.",
    imageName: "army_khaki_olive",
  },
  {
    title: "Verdes de Safari con Acentos Arenosos",
    palette: ["#454B1B", "#3B4221", "#91855E", "#B4A982", "#CCC29C"],
    description:
      "Verdes terrosos combinados con tonos arenosos que recuerdan a los paisajes de la sabana.",
    imageName: "safari_greens_sandy",
  },
  {
    title: "Paleta de Sobrante Militar Vintage",
    palette: ["#454B1B", "#3B4221", "#7C7B5D", "#A5A68A", "#C4C4B1"],
    description:
      "Verdes y marrones desgastados evocativos del equipo militar vintage.",
    imageName: "vintage_surplus",
  },
  {
    title: "Oliva Drab con Grises Industriales Urbanos",
    palette: ["#454B1B", "#3B4221", "#696B6C", "#8E9294", "#B9BDBC"],
    description:
      "Verdes militares combinados con grises industriales urbanos para un toque moderno.",
    imageName: "olive_drab_industrial_greys",
  },
  {
    title: "Verdes Inspirados en el Camuflaje con Bronceado del Desierto",
    palette: ["#454B1B", "#3B4221", "#847D5E", "#B3A887", "#D6CCAC"],
    description:
      "Verdes de camuflaje combinados con tonos bronceados inspirados en el desierto.",
    imageName: "camo_desert_tan",
  },
  {
    title: "Verdes Musgosos con Tonos Marrones Terrosos",
    palette: ["#454B1B", "#3B4221", "#6B684E", "#908E7A", "#ADA996"],
    description: "Verdes musgosos combinados con tonos marrones terrosos.",
    imageName: "mossy_greens_browns",
  },
  {
    title: "Verdes Militares con Acentos de Pizarra Oscura",
    palette: ["#454B1B", "#3B4221", "#5C5F5E", "#848887", "#A7ACAB"],
    description:
      "Verdes oliva oscuros combinados con tonos de pizarra oscura para un aspecto sofisticado.",
    imageName: "army_olive_slate",
  },
  {
    title: "Verdes del Bosque con Matices de Matorral Musgoso",
    palette: ["#454B1B", "#3B4221", "#4E5E41", "#758C6F", "#97AA92"],
    description:
      "Verdes reminiscentes del suelo del bosque, con toques de matorral musgoso.",
    imageName: "forest_greens_mossy",
  },
  {
    title: "Verdes Oliva Clásicos con Gris Topo",
    palette: ["#454B1B", "#3B4221", "#7D7D64", "#A6A68A", "#C5C4B0"],
    description:
      "Verdes oliva atemporales complementados con tonos neutros de gris topo.",
    imageName: "classic_olive_taupe",
  },
  {
    title: "Verdes Rústicos con Madera Desgastada",
    palette: ["#454B1B", "#3B4221", "#756C4F", "#9C9175", "#BFB69A"],
    description:
      "Verdes rústicos combinados con la calidez de los tonos de madera desgastada.",
    imageName: "rustic_greens_wood",
  },
];

export const ptArmyGreenPalettes = [
  {
    title: "Monocromático",
    palette: ["#4B5320", "#6B8E23", "#556B2F", "#8FBC8F", "#708090"],
    description: "Uma paleta monocromática baseada em verde militar.",
    imageName: "monochromatic_army_green",
  },
  {
    title: "Analógico",
    palette: ["#4B5320", "#6B8E23", "#808000", "#556B2F", "#8FBC8F"],
    description:
      "Uma paleta análoga com cores adjacentes ao verde militar no círculo cromático.",
    imageName: "analogous_army_green",
  },
  {
    title: "Complementar",
    palette: ["#4B5320", "#8B0000", "#6B8E23", "#00008B", "#556B2F"],
    description:
      "Uma paleta que apresenta verde militar e sua cor complementar, marrom-avermelhado.",
    imageName: "complementary_army_green",
  },
  {
    title: "Triádico",
    palette: ["#4B5320", "#00008B", "#8B0000", "#556B2F", "#8B008B"],
    description: "Uma paleta triádica com verde militar, azul e vermelho.",
    imageName: "triadic_army_green",
  },
  {
    title: "Verdes Profundos da Floresta com Toques de Oliva",
    palette: ["#454B1B", "#3B4221", "#4E5325", "#6B7132", "#788153"],
    description:
      "Tons ricos e terrosos reminiscentes da densa folhagem da floresta.",
    imageName: "deep_forest_olive",
  },
  {
    title: "Camuflagem Militar Clássica",
    palette: ["#454B1B", "#3B4221", "#6B7132", "#9B9C5E", "#AAB37D"],
    description:
      "Tons icônicos usados em padrões tradicionais de camuflagem militar.",
    imageName: "classic_camouflage",
  },
  {
    title: "Verdes da Selva Urbana com Acentos Cinzas",
    palette: ["#454B1B", "#3B4221", "#5E6752", "#858D81", "#A7ACA4"],
    description:
      "Verdes suaves misturados com tons de cinza inspirados no concreto urbano.",
    imageName: "urban_jungle_greys",
  },
  {
    title: "Marrons Terrosos e Verdes para Exteriores",
    palette: ["#454B1B", "#3B4221", "#6B5C42", "#8C7E65", "#A69C85"],
    description:
      "Tons naturais ideais para designs ao ar livre e temáticos de aventura.",
    imageName: "earthy_browns_outdoors",
  },
  {
    title: "Tons de Caqui e Oliva Inspirados na Milicia",
    palette: ["#454B1B", "#3B4221", "#827D5E", "#AAA587", "#CAC4AB"],
    description:
      "Tons clássicos de caqui e oliva comumente vistos em uniformes militares.",
    imageName: "army_khaki_olive",
  },
  {
    title: "Verdes de Safari com Acentos Arenosos",
    palette: ["#454B1B", "#3B4221", "#91855E", "#B4A982", "#CCC29C"],
    description:
      "Verdes terrosos combinados com tons arenosos que lembram paisagens de savana.",
    imageName: "safari_greens_sandy",
  },
  {
    title: "Paleta de Sobras Militares Vintage",
    palette: ["#454B1B", "#3B4221", "#7C7B5D", "#A5A68A", "#C4C4B1"],
    description:
      "Verdes e marrons desgastados evocativos do equipamento militar vintage.",
    imageName: "vintage_surplus",
  },
  {
    title: "Oliva Drab com Cinzas Industriais Urbanos",
    palette: ["#454B1B", "#3B4221", "#696B6C", "#8E9294", "#B9BDBC"],
    description:
      "Verdes militares combinados com cinzas industriais urbanos para um toque moderno.",
    imageName: "olive_drab_industrial_greys",
  },
  {
    title: "Verdes Inspirados na Camuflagem com Bronzeado do Deserto",
    palette: ["#454B1B", "#3B4221", "#847D5E", "#B3A887", "#D6CCAC"],
    description:
      "Verdes de camuflagem combinados com tons bronzeados inspirados no deserto.",
    imageName: "camo_desert_tan",
  },
  {
    title: "Verdes Musgosos com Tons Marrons Terrosos",
    palette: ["#454B1B", "#3B4221", "#6B684E", "#908E7A", "#ADA996"],
    description: "Verdes musgosos combinados com tons marrons terrosos.",
    imageName: "mossy_greens_browns",
  },
  {
    title: "Verdes Militares com Acentos de Ardósia Escura",
    palette: ["#454B1B", "#3B4221", "#5C5F5E", "#848887", "#A7ACAB"],
    description:
      "Verdes oliva escuros combinados com tons de ardósia escura para um visual sofisticado.",
    imageName: "army_olive_slate",
  },
  {
    title: "Verdes do Bosque com Toques de Arbusto Musgoso",
    palette: ["#454B1B", "#3B4221", "#4E5E41", "#758C6F", "#97AA92"],
    description:
      "Verdes reminiscentes do chão da floresta, com toques de arbusto musgoso.",
    imageName: "forest_greens_mossy",
  },
  {
    title: "Verdes Oliva Clássicos com Taupe",
    palette: ["#454B1B", "#3B4221", "#7D7D64", "#A6A68A", "#C5C4B0"],
    description:
      "Verdes oliva atemporais complementados por tons neutros de taupe.",
    imageName: "classic_olive_taupe",
  },
  {
    title: "Verdes Rústicos com Madeira Desgastada",
    palette: ["#454B1B", "#3B4221", "#756C4F", "#9C9175", "#BFB69A"],
    description:
      "Verdes rústicos combinados com o calor dos tons de madeira desgastada.",
    imageName: "rustic_greens_wood",
  },
];

export const frArmyGreenPalettes = [
  {
    title: "Monochromatique",
    palette: ["#4B5320", "#6B8E23", "#556B2F", "#8FBC8F", "#708090"],
    description: "Une palette monochromatique basée sur le vert armée.",
    imageName: "monochromatic_army_green",
  },
  {
    title: "Analogique",
    palette: ["#4B5320", "#6B8E23", "#808000", "#556B2F", "#8FBC8F"],
    description:
      "Une palette analogique avec des couleurs adjacentes au vert armée sur le cercle chromatique.",
    imageName: "analogous_army_green",
  },
  {
    title: "Complémentaire",
    palette: ["#4B5320", "#8B0000", "#6B8E23", "#00008B", "#556B2F"],
    description:
      "Une palette présentant le vert armée et sa couleur complémentaire, brun-rouge.",
    imageName: "complementary_army_green",
  },
  {
    title: "Triadique",
    palette: ["#4B5320", "#00008B", "#8B0000", "#556B2F", "#8B008B"],
    description:
      "Une palette triadique avec du vert armée, du bleu et du rouge.",
    imageName: "triadic_army_green",
  },
  {
    title: "Verts de la Forêt Profonde avec des Notes d'Olive",
    palette: ["#454B1B", "#3B4221", "#4E5325", "#6B7132", "#788153"],
    description:
      "Tons riches et terreux évoquant la dense végétation de la forêt.",
    imageName: "deep_forest_olive",
  },
  {
    title: "Camouflage Militaire Classique",
    palette: ["#454B1B", "#3B4221", "#6B7132", "#9B9C5E", "#AAB37D"],
    description:
      "Teintes emblématiques utilisées dans les motifs de camouflage militaire traditionnel.",
    imageName: "classic_camouflage",
  },
  {
    title: "Verts de la Jungle Urbaine avec des Accents Gris",
    palette: ["#454B1B", "#3B4221", "#5E6752", "#858D81", "#A7ACA4"],
    description:
      "Verts atténués se mêlant à des gris inspirés du béton urbain.",
    imageName: "urban_jungle_greys",
  },
  {
    title: "Marrons Terreux et Verts pour l'Extérieur",
    palette: ["#454B1B", "#3B4221", "#6B5C42", "#8C7E65", "#A69C85"],
    description:
      "Tons naturels idéaux pour les designs d'extérieur et thématiques d'aventure.",
    imageName: "earthy_browns_outdoors",
  },
  {
    title: "Tons Kaki et Olive Inspirés de l'Armée",
    palette: ["#454B1B", "#3B4221", "#827D5E", "#AAA587", "#CAC4AB"],
    description:
      "Teintes classiques de kaki et d'olive couramment observées dans les uniformes militaires.",
    imageName: "army_khaki_olive",
  },
  {
    title: "Verts de Safari avec des Accents Sable",
    palette: ["#454B1B", "#3B4221", "#91855E", "#B4A982", "#CCC29C"],
    description:
      "Verts terreux associés à des tons sableux évoquant les paysages de savane.",
    imageName: "safari_greens_sandy",
  },
  {
    title: "Palette de Surplus Militaire Vintage",
    palette: ["#454B1B", "#3B4221", "#7C7B5D", "#A5A68A", "#C4C4B1"],
    description:
      "Verts et marrons vieillis évoquant l'équipement militaire vintage.",
    imageName: "vintage_surplus",
  },
  {
    title: "Vert Olive avec des Accents de Gris Industriel Urbain",
    palette: ["#454B1B", "#3B4221", "#696B6C", "#8E9294", "#B9BDBC"],
    description:
      "Verts armée associés à des gris industriels urbains pour une touche moderne.",
    imageName: "olive_drab_industrial_greys",
  },
  {
    title: "Verts Inspirés du Camouflage avec Tan du Désert",
    palette: ["#454B1B", "#3B4221", "#847D5E", "#B3A887", "#D6CCAC"],
    description:
      "Verts de camouflage associés à des tons bruns inspirés du désert.",
    imageName: "camo_desert_tan",
  },
  {
    title: "Verts Moussus avec des Tons Terreux Marrons",
    palette: ["#454B1B", "#3B4221", "#6B684E", "#908E7A", "#ADA996"],
    description: "Verts moussus combinés avec des tons terreux marrons.",
    imageName: "mossy_greens_browns",
  },
  {
    title: "Verts Militaires avec des Accents de Gris Ardoise Foncée",
    palette: ["#454B1B", "#3B4221", "#5C5F5E", "#848887", "#A7ACAB"],
    description:
      "Verts olive riches associés à des tons d'ardoise foncée pour un look sophistiqué.",
    imageName: "army_olive_slate",
  },
  {
    title: "Verts de la Forêt avec des Sous-Bois Moussus",
    palette: ["#454B1B", "#3B4221", "#4E5E41", "#758C6F", "#97AA92"],
    description:
      "Verts évoquant le sol d'une forêt, avec des notes de sous-bois moussus.",
    imageName: "forest_greens_mossy",
  },
  {
    title: "Verts Olive Classiques avec du Taupe",
    palette: ["#454B1B", "#3B4221", "#7D7D64", "#A6A68A", "#C5C4B0"],
    description:
      "Verts olive intemporels complétés par des tons neutres de taupe.",
    imageName: "classic_olive_taupe",
  },
  {
    title: "Verts Rustiques avec du Bois Vieilli",
    palette: ["#454B1B", "#3B4221", "#756C4F", "#9C9175", "#BFB69A"],
    description:
      "Verts rustiques associés à la chaleur des tons de bois vieilli.",
    imageName: "rustic_greens_wood",
  },
];
