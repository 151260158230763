import React from "react";
import {
  wrapper_1_column,
  color_box,
} from "../../../../src/styles/modules/color_page.module.css";
import { darkOrLightColor } from "../../../../src/helpers/darkOrLightColor";
import tinycolor from "tinycolor2";
import { GatsbyImage } from "gatsby-plugin-image";

function IntroColor({ colorName, colorHex, withImage, image, locale = "es" }) {
  const complementColor = tinycolor(colorHex).complement().toHexString();
  const h1Text =
    locale === "es"
      ? `Códigos html del color ${colorName} - hexadecimal + rgb`
      : locale === "pt"
      ? `Códigos HTML de cores para ${colorName} - hexadecimal + RGB.`
      : locale === "en"
      ? `HTML color codes for ${colorName} - hexadecimal + RGB`
      : locale === "fr"
      ? `codes HTML de la couleur ${colorName} - hexadécimal + RGB`
      : null;

  const introP =
    locale === "es"
      ? `Descubre todos los códigos html del color ${colorName} - hexadecimal,
  rgb, hsl, hsv y cmyk, además de diferentes combinaciones, esquemas,
  temperaturas tonos y tipos de color ${colorName}.`
      : locale === "pt"
      ? `Descubra todos os códigos HTML da cor ${colorName} - hexadecimal, RGB, HSL, HSV e CMYK, além de diferentes combinações, esquemas, temperaturas, tons e tipos de cor ${colorName}.
  `
      : locale === "en"
      ? `Discover all HTML codes for the color ${colorName} - hexadecimal,
      rgb, hsl, hsv, and cmyk, as well as different combinations, schemes,
      temperatures, hues, and types of ${colorName} color.`
      : locale === "fr"
      ? `Découvrez tous les codes HTML pour la couleur ${colorName} - hexadécimal,
      rgb, hsl, hsv et cmyk, ainsi que différentes combinaisons, schémas,
      températures, nuances et types de couleur ${colorName}.`
      : null;

  const headingComplement =
    locale === "es"
      ? `Color Complementario del ${colorName}:`
      : locale === "pt"
      ? `Cor complementar do ${colorName}:`
      : locale === "en"
      ? `Complementary color of ${colorName}:`
      : locale === "fr"
      ? `Couleur complémentaire de ${colorName} :`
      : null;

  const pComplement =
    locale === "es"
      ? `El color complementario de un color es el opuesto de este en la
rueda de colores (circulo cromático).`
      : locale === "pt"
      ? `A cor complementar de uma cor é o oposto dela no círculo cromático`
      : locale === "en"
      ? `The complementary color of a color is the opposite of it on the color wheel (chromatic circle)`
      : locale === "fr"
      ? `La couleur complémentaire d'une couleur est l'opposée de celle-ci sur la roue des couleurs (cercle chromatique)`
      : null;

  return (
    <div>
      <h1 className="heading_color_page">{h1Text}</h1>{" "}
      <p style={{ fontSize: "small" }}>{introP}</p>
      <div className={wrapper_1_column}>
        <div
          className={color_box}
          style={{
            backgroundColor: colorHex,
            position: "relative",
          }}
        >
          <h3 style={{ color: darkOrLightColor(colorHex) }}>{colorHex}</h3>
          {withImage ? (
            <GatsbyImage
              image={image}
              imgStyle={{ borderRadius: 10 }}
              style={{
                opacity: "0",
                zIndex: "-1",
                position: "absolute",
                top: 0,
                left: 0,
              }}
              alt={`códigos del color ${colorName}`}
            />
          ) : null}
        </div>
        <div>
          <h2 className="heading_color_page">{headingComplement}</h2>
          <p style={{ fontSize: "small" }}>{pComplement}</p>
          <div
            className={color_box}
            style={{
              backgroundColor: complementColor,
            }}
          >
            <h3 style={{ color: darkOrLightColor(complementColor) }}>
              {complementColor}
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IntroColor;
