import chroma from "chroma-js";
import React, { useState } from "react";
import { HexColorInput, HexColorPicker } from "react-colorful";
import { convertRenderColor } from "../../../helpers/convertRenderColor";
import { darkOrLightColor } from "../../../helpers/darkOrLightColor";
import {
  mix_colors_wrapper,
  mix_tool_wrapper,
  mix_color_picker_wrapper,
  mix_color_title,
  color_picker_wrapper,
  mix_color_result,
  input_hex_color,
  react_colorful,
} from "../../../styles/modules/mix_colors.module.css";

import Colors from "../../PageColorPalette/Colors";
// import AdWithPlaceholder from "../../Ads/AdWithPlaceholder";
// import AdManaWithPlaceholder from "../../Ads/AdManaWithPlaceHolder";

function MixColors({ locale }) {
  const [hexColor1, setHexColor1] = useState("#3b8cde");
  const [hexColor2, setHexColor2] = useState("#dd00ff");
  const colorResult = chroma.mix(hexColor1, hexColor2);

  return (
    <>
      <section className={mix_colors_wrapper}>
        <h1 className={mix_color_title}>
          {locale === "es"
            ? "Mezclar colores online"
            : locale === "en"
            ? "mix colors online"
            : locale === "pt"
            ? "Misturar cores online"
            : locale === "fr"
            ? "Mélanger des couleurs en ligne"
            : null}
        </h1>

        <div className={mix_tool_wrapper}>
          <div>
            <h2>
              {locale === "es"
                ? "Colores a combinar"
                : locale === "en"
                ? "Colors to combine"
                : locale === "pt"
                ? "Cores para combinar"
                : locale === "fr"
                ? "Couleurs à combiner"
                : null}
            </h2>
            <div className={mix_color_picker_wrapper}>
              <div
                className={color_picker_wrapper}
                style={{ backgroundColor: hexColor1 }}
                color={hexColor1}
              >
                <HexColorPicker
                  className={react_colorful}
                  color={hexColor1}
                  onChange={setHexColor1}
                />
                <HexColorInput
                  className={input_hex_color}
                  color={hexColor1}
                  onChange={setHexColor1}
                />
              </div>
              <div
                className={color_picker_wrapper}
                style={{ backgroundColor: hexColor2 }}
                color={hexColor2}
              >
                <HexColorPicker
                  className={react_colorful}
                  color={hexColor2}
                  onChange={setHexColor2}
                />
                <HexColorInput
                  className={input_hex_color}
                  color={hexColor2}
                  onChange={setHexColor2}
                />
              </div>
            </div>
          </div>
          <div>
            <h2>
              {locale === "es"
                ? "Escala generada a partir de la combinación"
                : locale === "en"
                ? "Scale generated from the combination"
                : locale === "pt"
                ? "Escala gerada a partir da combinação"
                : locale === "fr"
                ? "Échelle générée à partir de la combinaison"
                : null}
            </h2>
            <div>
              <Colors
                palette={chroma
                  .scale([hexColor1, hexColor2])
                  .mode("lch")
                  .colors(7)}
                withLinkColor={false}
              />
            </div>
          </div>
          <div>
            <h2>
              {locale === "es"
                ? "Resultado de la mezcla"
                : locale === "en"
                ? "Mix Result"
                : locale === "pt"
                ? "Resultado da Mistura"
                : locale === "fr"
                ? "Résultat du mélange"
                : null}
            </h2>
            <div
              className={mix_color_result}
              style={{
                backgroundColor: colorResult,
                color: darkOrLightColor(colorResult),
              }}
              color={colorResult}
            >
              <strong>{`${colorResult}`}</strong>
            </div>
            <ul style={{ listStyle: "none" }}>
              <li className="code-result">Hexadecimal: {`${colorResult}`}</li>
              <li className="code-result">
                Rgb:
                {convertRenderColor({ color: colorResult }, "rgb")}{" "}
              </li>
              <li className="code-result">
                hsl: {convertRenderColor({ color: colorResult }, "hsl")}{" "}
              </li>
              <li className="code-result">
                cmyk : {convertRenderColor({ color: colorResult }, "cmyk")}
              </li>
            </ul>
          </div>
        </div>
      </section>
      {/* <AdManaWithPlaceholder
        path="/22250597679/wortise_InArticle_1"
        size={[
          [300, 250],
          [728, 90],
          [728, 250],
        ]}
        id="InArticle_1"
      /> */}
    </>
  );
}

export default MixColors;
