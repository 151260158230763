export const esOrangePalettes = [
  {
    palette: ["#e28000", "#ff9800", "#ffc340", "#FCA311", "#FFFFFF"],
    paletteName: "Calidez de otoño",
    paletteDescription:
      "Una paleta de colores cálidos y vibrantes que evoca los tonos del otoño, perfecta para sitios web de moda y estilo de vida.",
  },

  {
    palette: ["#e28000", "#ff9800", "#ffc340", "#BA4A00", "#2C2B2B"],
    paletteName: "Contraste intenso",
    paletteDescription:
      "Una paleta de colores que combina naranja intenso y negro, creando un contraste intenso y llamativo, ideal para sitios web de arte y diseño.",
  },

  {
    palette: ["#e28000", "#ff9800", "#ffc340", "#F9D7A0", "#F4F4F4"],
    paletteName: "Amanecer dorado",
    paletteDescription:
      "Una paleta de colores suaves y cálidos que evoca la calidez del sol de la mañana, perfecta para sitios web de bienestar y cuidado personal.",
  },

  {
    palette: ["#e28000", "#ff9800", "#ffc340", "#B1B695", "#F9E0A1"],
    paletteName: "Tonos naturales",
    paletteDescription:
      "Una paleta de colores que combina naranja y verde suave, creando una atmósfera natural y relajante, ideal para sitios web de ecología y sostenibilidad.",
  },

  {
    palette: ["#e28000", "#ff9800", "#ffc340", "#5C5C5C", "#FFFFFF"],
    paletteName: "Elegancia minimalista",
    paletteDescription:
      "Una paleta de colores que combina naranja y gris, creando un ambiente minimalista y elegante, perfecto para sitios web de negocios y finanzas.",
  },

  {
    palette: ["#e28000", "#ff9800", "#ffc340", "#1B1B1B", "#F0F0F0"],
    paletteName: "Tecnología avanzada",
    paletteDescription:
      "Una paleta de colores que combina naranja y negro, creando un ambiente futurista y tecnológico, ideal para sitios web de ciencia y tecnología.",
  },

  {
    palette: ["#e28000", "#ff9800", "#ffc340", "#4D4D4D", "#EFEFEF"],
    paletteName: "Contraste elegante",
    paletteDescription:
      "Una paleta de colores que combina naranja y gris oscuro, creando un contraste elegante y sofisticado, perfecto para sitios web de lujo y moda.",
  },

  {
    palette: ["#e28000", "#ff9800", "#ffc340", "#008080", "#FFFFFF"],
    paletteName: "Contraste vibrante",
    paletteDescription:
      "Una paleta de colores que combina naranja y turquesa, creando un contraste vibrante y atractivo, ideal para sitios web de entretenimiento y eventos.",
  },
];
