import { Canvg } from "canvg";
import chroma from "chroma-js";
import { saveAs } from "file-saver";

const downloadPaletteImage = (colorPalette, nameImage = "Paleta de color") => {
  if (typeof window !== undefined) {
    if (colorPalette.length === 5) {
      const canv = document.createElement("canvas");
      canv.id = "canv";
      const ctx = canv.getContext("2d");

      const makeRects = () => {
        let rects = "";
        let xPosition = 0;
        for (let i = 0; i < colorPalette.length; i++) {
          rects += `
          <rect
            x="0"
            y="${xPosition}%"
            width="100%"
            height="20%"
            opacity="1"
            fill="${colorPalette[i]}"
          />
  
          <text x="5%" y="${xPosition + 10}%"
           style="font-family: Times New Roman;
            font-size : 44;
            fill       : ${
              chroma(colorPalette[i]).luminance() < 0.5 ? "#fff" : "#505050"
            };"
            >
          ${colorPalette[i]} 
          </text>
        `;

          xPosition = xPosition + 20;
        }
        return rects;
      };

      //
      const makeSVG = () => {
        //w = 500, h= 386
        const w = 1000;
        const h = 1500;
        const svg = `<svg width="${w}" height="${h}" id="svg" xmlns="http://www.w3.org/2000/svg">
      <text x="2%" y="95%"
        font-family="Verdana"
        font-size="20"
        style="font-family: Times New Roman;
        font-size  : 26;
        fill       : #000;
        "
        
        >
        https://paletadecolores.online
      </text>
        <rect x="0" y="0" width="${w}" height="${h}" fill="#fff" fill-opacity="1"/>
  
        ${makeRects()}
      </svg>`;
        window.globalSVGStore = svg;
        return svg;
      };

      const setup = () => {
        const v = Canvg.fromString(ctx, makeSVG());
        v.start();
      };

      setup();
      window.onresize = setup;

      function download(filename, text) {
        var pom = document.createElement("a");
        pom.setAttribute(
          "href",
          "data:text/plain;charset=utf-8," + encodeURIComponent(text)
        );
        pom.setAttribute("download", filename);

        if (document.createEvent) {
          var event = document.createEvent("MouseEvents");
          event.initEvent("click", true, true);
          pom.dispatchEvent(event);
        } else {
          pom.click();
        }
      }

      const downloadSvg = () => download("palette.svg", window.globalSVGStore);

      const downloadPng = () => {
        canv.toBlob(function (blob) {
          saveAs(blob, `${nameImage}.png`);
        });
      };

      return { downloadPng, downloadSvg };
    } else {
      // return;
      return { downloadPng: () => console.log("click") };
    }
  }
};

export default downloadPaletteImage;
