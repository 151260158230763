import React from "react";
import BackgroundContrastChecker from "./BackgroundContrastChecker";
import TextContrastChecker from "./TextContrastChecker";
import { wrapper } from "./styles.module.css";

// import { wrapper } from '../styles/modules/color_contrast_checker.module.scss';

export default function ColorContrastCheckerInPost({
  colorName,
  colorHex,
  locale = "es",
}) {
  return (
    <div>
      <h2 className="heading_color_page">
        {locale === "es"
          ? `Verificar el contraste del color ${colorName}`
          : locale === "en"
          ? `Check the contrast of the color ${colorName}.`
          : locale === "pt"
          ? `Verificar o contraste da cor ${colorName}.`
          : locale === "fr"
          ? `Vérifier le contraste de la couleur ${colorName}.`
          : ""}
      </h2>
      <p style={{ fontSize: "small" }}>
        {locale === "es"
          ? `A continuación puedes validar la relación de contraste del color ${colorName} ya sea usándolo como color de fondo o de texto.`
          : locale === "en"
          ? `You can now validate the contrast relationship of the color ${colorName}, whether using it as a background color or as a text color.`
          : locale === "pt"
          ? `Agora você pode validar a relação de contraste da cor ${colorName}, seja usando-a como cor de fundo ou como cor de texto.`
          : locale === "fr"
          ? `Vous pouvez ensuite valider la relation de contraste de la couleur ${colorName} que ce soit en l'utilisant comme couleur de fond ou de texte.`
          : ""}
      </p>
      <div className={wrapper}>
        <BackgroundContrastChecker
          locale={locale}
          colorName={colorName}
          colorHex={colorHex}
        />
        <TextContrastChecker
          locale={locale}
          colorName={colorName}
          colorHex={colorHex}
        />
      </div>
    </div>
  );
}
