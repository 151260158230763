import React from "react";
import useAdInView from "../../hooks/useAdInView";
// import { modal_ad } from "../../styles/modules/modal_palette.module.css";

function AdWithObserver({ ins = "" }) {
  const [container] = useAdInView();

  return <div ref={container} dangerouslySetInnerHTML={{ __html: ins }}></div>;
}

export default AdWithObserver;

{
  /* <AdWithObserver ins={`<!-- ptdc_tools -->
  <ins class="adsbygoogle"
     style="display:block"
     data-ad-client="ca-pub-3833126112632805"
     data-ad-slot="8415130030"
     data-ad-format="auto"
     data-full-width-responsive="true"></ins>`}
/> */
}

{
  /* <AdWithObserver ins={`<!-- ptdc-tone -->
  <ins class="adsbygoogle"
     style="display:block"
     data-ad-client="ca-pub-3833126112632805"
     data-ad-slot="1043804843"
     data-ad-format="auto"
     data-full-width-responsive="true"></ins>`}
/> */
}
