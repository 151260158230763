import React, { useContext } from "react";
import {
  placeholder,
  animated_background,
  loading_text,
} from "../../styles/modules/placeholder_ad.module.css";

import { ContextLocale } from "../../context/ContextLocale";

function PlaceholderAd() {
  const { lang } = useContext(ContextLocale);

  const loadingText =
    lang === "es" ? `...` : lang === "en" ? `...` : lang === "pt" ? `...` : "";

  return (
    <div style={{ width: "100%", height: 200 }}>
      <div className={placeholder}>
        <div className={animated_background}></div>
        <div className={loading_text}>{loadingText}</div>
      </div>
    </div>
  );
}

export default PlaceholderAd;
