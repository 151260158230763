import React, { useContext } from "react";
// import SelectDropdown from "../SelectDropdown";
import useToggle from "../../hooks/useToggle";
import ModalDownload from "../Modals/ModalDownload";
// import { link_button_with_color } from "../../styles/modules/utilities.module.css";
// import { darkOrLightColor } from "../../helpers/darkOrLightColor";
import { ContextLocale } from "../../context/ContextLocale";
import {
  toolbar_wrapper,
  button_toolbar,
} from "../../styles/modules/toolbar_generate_palette.module.css";
import { Link } from "gatsby";

function ToolBar({ colorPalette, newPalette }) {
  const { lang } = useContext(ContextLocale);
  const [isOpen, handleClick] = useToggle(false);
  return (
    <>
      {isOpen ? (
        <ModalDownload colorPalette={colorPalette} onClose={handleClick} />
      ) : null}

      <div className={toolbar_wrapper}>
        <button className={button_toolbar} onClick={newPalette}>
          {lang === "es"
            ? "Nueva paleta"
            : lang === "en"
            ? "New palette"
            : lang === "pt"
            ? "nova paleta"
            : lang === "fr"
            ? "Nouvelle palette"
            : null}
        </button>

        <button className={button_toolbar} onClick={handleClick}>
          {lang === "es"
            ? "Descargar"
            : lang === "en"
            ? "Download"
            : lang === "pt"
            ? "Descarga"
            : lang === "fr"
            ? "Télécharger"
            : null}
        </button>

        {/* <SelectDropdown color={colorPalette[2]} /> */}

        <Link
          className={button_toolbar}
          to={
            lang === "es"
              ? "/crear-paleta/"
              : lang === "en"
              ? "/en/create-palette/"
              : lang === "pt"
              ? "/pt/criar-paleta/"
              : lang === "fr"
              ? "/fr/creer-palette/"
              : null
          }
        >
          {lang === "es"
            ? "Crear paleta"
            : lang === "en"
            ? "Create palette"
            : lang === "pt"
            ? "Criar paleta"
            : lang === "fr"
            ? "Créer palette"
            : null}
        </Link>

        <Link
          className={button_toolbar}
          to={
            lang === "es"
              ? "/obtener-colores-de-imagen/"
              : lang === "en"
              ? "/en/get-colors-from-image/"
              : lang === "pt"
              ? "/pt/obter-cores-da-imagem/"
              : lang === "fr"
              ? "/fr/extraire-les-couleurs-dune-image/"
              : null
          }
        >
          {lang === "es"
            ? "color de imagen"
            : lang === "en"
            ? "image color"
            : lang === "pt"
            ? "cor da imagem"
            : lang === "fr"
            ? "couleurs d' image"
            : null}
        </Link>
        <Link
          className={button_toolbar}
          to={
            lang === "es"
              ? "/mezclar-colores/"
              : lang === "en"
              ? "/en/mix-colors/"
              : lang === "pt"
              ? "/pt/misturar-cores/"
              : lang === "fr"
              ? "/fr/melanger-couleurs/"
              : null
          }
        >
          {lang === "es"
            ? "mezclar color"
            : lang === "en"
            ? "mix colours"
            : lang === "pt"
            ? "misturar cores"
            : lang === "fr"
            ? "Mélanger couleurs"
            : null}
        </Link>
      </div>
    </>
  );
}

export default ToolBar;
