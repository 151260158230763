import chroma from "chroma-js";
import { convertToHsl } from "./convertColors";

import tinycolor from "tinycolor2";

export function generateColorsSChemes(hexColor, scheme) {
  const objectSchemes = {
    monochromatic: monochromaticScheme(hexColor),
    complementary: complementaryScheme(hexColor),
    analogous: analogousScheme(hexColor),
    triad: triadicScheme(hexColor),
    splitComplement: tinycolor(hexColor)
      .splitcomplement()
      .map(t => t.toHexString),
  };

  return objectSchemes[scheme];
}

function generateShadesOfColors(hex, numColors, brighten, darken) {
  const getRange = hexColor => {
    const end = chroma(hexColor).brighten(brighten).hex();

    return [chroma(hexColor).darken(darken).hex(), hexColor, end];
  };

  const getScale = (hexColor, numberOfColors) => {
    return chroma.scale(getRange(hexColor)).mode("lab").colors(numberOfColors);
  };

  return getScale(hex, numColors);
}

export function monochromaticScheme(hex, numColors = 5) {
  return generateShadesOfColors(hex, numColors, 2.3, 2.2);
}

export function complementaryScheme(hex) {
  const color = chroma(hex).hsl();
  const complemtaryColor = [color[0] + 180, color[1], color[2], color[3]];
  const hsl1 = convertToHsl(color);
  const hsl2 = convertToHsl(complemtaryColor);

  return [
    ...generateShadesOfColors(chroma(hsl1).hex(), 3, 0.5, 0.3),
    ...generateShadesOfColors(chroma(hsl2).hex(), 2, 0.5, 0.2),
  ];
}

export function splitComplementScheme(hex) {
  const color = chroma(hex).hsl();
  const splitColor1 = [color[0] + 150, color[1], color[2], color[3]];
  const splitColor2 = [color[0] + 220, color[1], color[2], color[3]];

  const hsl1 = convertToHsl(color);
  const hsl2 = convertToHsl(splitColor1);
  const hsl3 = convertToHsl(splitColor2);

  return [chroma(hsl1).hex(), chroma(hsl2).hex(), chroma(hsl3).hex()];
}

export function analogousScheme(hex, with3Colors) {
  const color = chroma(hex).hsl();
  const firstColor = [
    color[0] + 30 > 360 ? color[0] + 30 - 360 : color[0] + 30,
    color[1],
    color[2],
    color[3],
  ];
  const secondColor = [
    color[0] + 330 > 360 ? color[0] + 330 - 360 : color[0] + 330,
    color[1],
    color[2],
    color[3],
  ];
  const hsl1 = convertToHsl(color);
  const hsl2 = convertToHsl(firstColor);
  const hsl3 = convertToHsl(secondColor);

  if (with3Colors) {
    return [
      chroma(hsl1).hex(),
      ...generateShadesOfColors(chroma(hsl2).hex(), 1, 0, 0),
      ...generateShadesOfColors(chroma(hsl3).hex(), 1, 0, 0),
    ];
  } else {
    return [
      ...generateShadesOfColors(chroma(hsl1).hex(), 2, 0.2, 0.2),
      ...generateShadesOfColors(chroma(hsl2).hex(), 2, 0.2, 0.2),
      ...generateShadesOfColors(chroma(hsl3).hex(), 1, 0.2, 0.2),
    ];
  }
}

export function triadicScheme(hex, with3Colors) {
  const color = chroma(hex).hsl();
  const firstColor = [
    color[0] + 120 > 360 ? color[0] + 120 - 360 : color[0] + 120,
    color[1],
    color[2],
    color[3],
  ];
  const secondColor = [
    color[0] + 240 > 360 ? color[0] + 240 - 360 : color[0] + 240,
    color[1],
    color[2],
    color[3],
  ];
  const hsl1 = convertToHsl(color);
  const hsl2 = convertToHsl(firstColor);
  const hsl3 = convertToHsl(secondColor);
  if (with3Colors) {
    return [chroma(hsl1).hex(), chroma(hsl2).hex(), chroma(hsl3).hex()];
  } else {
    return [
      ...generateShadesOfColors(chroma(hsl1).hex(), 2, 0.3, 0.2),
      ...generateShadesOfColors(chroma(hsl2).hex(), 2, 0.3, 0.2),
      ...generateShadesOfColors(chroma(hsl3).hex(), 1, 0.2, 0.2),
    ];
  }
}
