import React from "react";
import useCopyToClipboard from "../../hooks/useCopyToClipboard";
import { darkOrLightColor } from "../../helpers/darkOrLightColor";
import LinkToDetailsColor from "../LinkToDetailsColor";

import CopyColorMessage from "../CopyColorMessage";
import {
  colors_wrapper,
  mini_color_wrapper,
  code_color_wrapper,
} from "../../styles/modules/colors.module.css";

function Colors({ palette, withLinkColor = true }) {
  return (
    <div className={colors_wrapper}>
      {palette.map(color => (
        <MiniColor withLinkColor={withLinkColor} key={color} hex={color} />
      ))}
    </div>
  );
}

export function MiniColor({ hex, withLinkColor = true }) {
  const [message, showMessage, handleCopy] = useCopyToClipboard(`${hex}`);

  return (
    <>
      {showMessage ? (
        <CopyColorMessage colorHex={hex} message={message} />
      ) : null}
      <div
        className={mini_color_wrapper}
        style={{ background: hex, color: darkOrLightColor(hex) }}
        onClick={handleCopy}
      >
        <button style={{ background: hex }} title="copiar" className="copy">
          <svg
            className="svg-icon"
            viewBox="0 0 20 20"
            width="20px"
            height="20px"
            fill={darkOrLightColor(hex)}
          >
            <path
              d="M17.206,5.45l0.271-0.27l-4.275-4.274l-0.27,0.269V0.9H3.263c-0.314,0-0.569,0.255-0.569,0.569v17.062
          c0,0.314,0.255,0.568,0.569,0.568h13.649c0.313,0,0.569-0.254,0.569-0.568V5.45H17.206z M12.932,2.302L16.08,5.45h-3.148V2.302z
          M16.344,17.394c0,0.314-0.254,0.569-0.568,0.569H4.4c-0.314,0-0.568-0.255-0.568-0.569V2.606c0-0.314,0.254-0.568,0.568-0.568
          h7.394v4.55h4.55V17.394z"
            ></path>
          </svg>
        </button>
        <div className={code_color_wrapper}>
          <span>{hex}</span>
          {withLinkColor ? (
            <div onClick={e => e.stopPropagation()}>
              <LinkToDetailsColor color={hex} />
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}

export default Colors;
