import React from "react";
import chroma from "chroma-js";
// import FAQJsonLd from './SEO/FAQJsonLd';

const colorCompositionsText = (locale, colorName, rgb, cmyk, hsl, hsv) => {
  switch (locale) {
    case "es":
      return [
        {
          title: `¿Cómo es el color ${colorName} en rgb?`,
          description: `El color ${colorName} en formato RGB esta compuesto por  (${rgb[0]} en el canal rojo), por (${rgb[1]} en el canal verde) y (${rgb[2]} en el azul)`,
        },
        {
          title: `¿Cómo es el color ${colorName} en cmyk?`,
          description: `Esta compuesto por (${cmyk[0]}%) de cian, (${cmyk[1]}%) de magenta, (${cmyk[2]}%) de yellow / amarillo, (${cmyk[3]}%) de key / negro.`,
        },
        {
          title: `¿Cúal es la composición del color ${colorName} en hsl?`,
          description: ` Esta compuesto por (${hsl[0]}°) de hue , (${hsl[1]}%) de saturation y (${hsl[2]}%) de lightness.`,
        },
        {
          title: `¿Cómo es el color ${colorName} en formato hsv?`,
          description: `el color ${colorName} en formato hsv esta compuesto por (${hsv[0]}°) de hue , (${hsv[1]}%) de saturation y (${hsv[2]}%) de value.`,
        },
      ];
    case "en":
      return [
        {
          title: `What is the ${colorName} color in RGB format?`,
          description: `The ${colorName} color in RGB format is composed of (${rgb[0]} in the red channel), (${rgb[1]} in the green channel), and (${rgb[2]} in the blue channel).`,
        },
        {
          title: `What is the ${colorName} color in CMYK format?`,
          description: `It is composed of (${cmyk[0]}%) cyan, (${cmyk[1]}%) magenta, (${cmyk[2]}%) yellow, and (${cmyk[3]}%) black / key.`,
        },
        {
          title: `What is the composition of the ${colorName} color in HSL?`,
          description: `It is composed of (${hsl[0]}°) hue, (${hsl[1]}%) saturation, and (${hsl[2]}%) lightness.`,
        },
        {
          title: `What is the ${colorName} color in HSV format?`,
          description: `The ${colorName} color in HSV format is composed of (${hsv[0]}°) hue, (${hsv[1]}%) saturation, and (${hsv[2]}%) value.`,
        },
      ];
    case "pt":
      return [
        {
          title: `Qual é a cor ${colorName} no formato RGB?`,
          description: `A cor ${colorName} no formato RGB é composta por (${rgb[0]} no canal vermelho), (${rgb[1]} no canal verde) e (${rgb[2]} no canal azul).`,
        },
        {
          title: `Qual é a cor ${colorName} no formato CMYK?`,
          description: `Ela é composta por (${cmyk[0]}%) de ciano, (${cmyk[1]}%) de magenta, (${cmyk[2]}%) de amarelo e (${cmyk[3]}%) de preto / chave.`,
        },
        {
          title: `Qual é a composição da cor ${colorName} no formato HSL?`,
          description: `Ela é composta por (${hsl[0]}°) de matiz, (${hsl[1]}%) de saturação e (${hsl[2]}%) de luminosidade.`,
        },
        {
          title: `Qual é a cor ${colorName} no formato HSV?`,
          description: `A cor ${colorName} no formato HSV é composta por (${hsv[0]}°) de matiz, (${hsv[1]}%) de saturação e (${hsv[2]}%) de valor.`,
        },
      ];
    case "fr":
      return [
        {
          title: `Quelle est la couleur ${colorName} en RVB ?`,
          description: `La couleur ${colorName} au format RVB est composée de (${rgb[0]} dans le canal rouge), de (${rgb[1]} dans le canal vert) et de (${rgb[2]} en bleu)`,
        },
        {
          title: `Quelle est la couleur ${colorName} en CMJN ?`,
          description: `Elle est composée de (${cmyk[0]}%) de cyan, de (${cmyk[1]}%) de magenta, de (${cmyk[2]}%) de jaune et de (${cmyk[3]}%) de noir.`,
        },
        {
          title: `Quelle est la composition de la couleur ${colorName} en HSL ?`,
          description: `Elle est composée de (${hsl[0]}°) de teinte, de (${hsl[1]}%) de saturation et de (${hsl[2]}%) de luminosité.`,
        },
        {
          title: `Comment est la couleur ${colorName} en format HSV ?`,
          description: `La couleur ${colorName} au format HSV est composée de (${hsv[0]}°) de teinte, de (${hsv[1]}%) de saturation et de (${hsv[2]}%) de valeur.`,
        },
      ];

    default:
      break;
  }
};

export default function ColorComposition({ colorName, colorHex, locale }) {
  const rgb = chroma(colorHex).rgb();
  const cmyk = chroma(colorHex)
    .cmyk()
    .map(item => Math.ceil(item * 100));
  const hsl = chroma(colorHex)
    .hsl()
    .map((item, i) => (i !== 0 ? Math.ceil(item * 100) : Math.ceil(item)));
  const hsv = chroma(colorHex)
    .hsv()
    .map((item, i) => (i !== 0 ? Math.ceil(item * 100) : Math.ceil(item)));

  const colorCompositions = colorCompositionsText(
    locale,
    colorName,
    rgb,
    cmyk,
    hsl,
    hsv
  );

  return (
    <section>
      {/* <FAQJsonLd faqList={colorCompositions} /> */}
      {colorCompositions.map(item => (
        <details key={item.title}>
          <summary>
            <h3
              style={{
                display: "inline-block",
                padding: "0",
                margin: "1rem 0",
                fontSize: "1rem",
              }}
            >
              {item.title}
            </h3>
          </summary>

          <p style={{ maxWidth: "600px", margin: ".5rem", fontSize: "small" }}>
            {item.description}
          </p>
        </details>
      ))}
    </section>
  );
}
