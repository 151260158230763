import React from "react";
import { darkOrLightColor } from "../helpers/darkOrLightColor";
import { link_topic } from "../styles/modules/link_to_topic_nav_cluster.module.css";

export default function LinkToTopicNavCluster({
  anchor,
  link,
  backgroundColor,
}) {
  return (
    <a
      className={link_topic}
      href={link}
      style={{
        backgroundColor: backgroundColor,
        color: darkOrLightColor(backgroundColor),
      }}
    >
      {anchor}{" "}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="icon icon-tabler icon-tabler-click"
        width="34"
        height="34"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
        <path d="M3 12l3 0"></path>
        <path d="M12 3l0 3"></path>
        <path d="M7.8 7.8l-2.2 -2.2"></path>
        <path d="M16.2 7.8l2.2 -2.2"></path>
        <path d="M7.8 16.2l-2.2 2.2"></path>
        <path d="M12 12l9 3l-4 2l-2 4l-3 -9"></path>
      </svg>
    </a>
  );
}
