import React from "react";
import parse, { domToReact } from "html-react-parser";
import { GatsbyImage } from "gatsby-plugin-image";
import useToggle from "../hooks/useToggle";
import PaletteModal from "./Modals/PaletteModal";
import snarkdown from "snarkdown";
import InternalLink from "./InternalLink";
import {
  image_palette_post_wrapper,
  gatsby_image_wrapper,
  palette_codes,
  figcaption_palette,
} from "../styles/modules/image_palette_post.module.css";

// import HexCodeMiniPalette from "./HexCodeMiniPalette";
import { MiniColor } from "./PalettePost";

function ImagePalettePost({
  palette,
  image,
  alt,
  description = "",
  paletteDescription,
  withLink = false,
}) {
  const [isOpen, handleClick] = useToggle(false);

  const descriptionPalette = snarkdown(description);

  const replaceLink = node => {
    if (node.name === "a") {
      return (
        node.children.length > 0 && (
          <InternalLink href={node.attribs.href}>
            {domToReact(node.children)}
          </InternalLink>
        )
      );
    }
  };

  return (
    <>
      {isOpen ? (
        <PaletteModal
          onClose={handleClick}
          palette={palette}
          titlePalette={alt}
        />
      ) : null}

      <div className={image_palette_post_wrapper}>
        <GatsbyImage className={gatsby_image_wrapper} image={image} alt={alt} />
        {/* <div className={palette_codes}>
          {palette.map(hexCode => (
            <HexCodeMiniPalette color={hexCode} />
          ))}
        </div> */}
        <div className={palette_codes}>
          {palette.map(hexCode => (
            <MiniColor color={hexCode} />
          ))}
          <button
            style={{
              position: "absolute",
              bottom: "0rem",
              right: "0rem",
              background: "none",
              border: "none",
              cursor: "pointer",
              backgroundColor: "white",
            }}
            type="button"
            title="download"
            onClick={() => {
              handleClick();
            }}
          >
            <svg
              height="24"
              viewBox="0 -18 512.00016 512"
              width="27"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
            >
              <linearGradient
                id="linear0"
                gradientUnits="userSpaceOnUse"
                x1="0"
                x2="512"
                y1="238.00008"
                y2="238.00008"
              >
                <stop offset="0" stop-color="#2196f3" />
                <stop offset=".0208" stop-color="#53d4ff" />
                <stop offset=".2931" stop-color="#4ab0fe" />
                <stop offset=".5538" stop-color="#3cbdfe" />
                <stop offset=".7956" stop-color="#4ab0fe" />
                <stop offset="1" stop-color="#4facfe" />
              </linearGradient>
              <path
                d="m432 0h-352c-44.113281 0-80 35.886719-80 80v280c0 44.113281 35.886719 80 80 80h214c11.046875 0 20-8.953125 20-20s-8.953125-20-20-20h-14.664062l-45.984376-59.65625 145.144532-184.617188 98.175781 117.121094c5.402344 6.445313 14.261719 8.824219 22.164063 5.949219 7.902343-2.875 13.164062-10.386719 13.164062-18.796875v-180c0-44.113281-35.886719-80-80-80zm40 205.007812-78.671875-93.855468c-3.871094-4.617188-9.609375-7.242188-15.640625-7.148438-6.023438.09375-11.683594 2.898438-15.410156 7.636719l-154.117188 196.023437-52.320312-67.875c-3.785156-4.910156-9.636719-7.789062-15.839844-7.789062-.003906 0-.007812 0-.011719 0-6.203125.003906-12.058593 2.886719-15.839843 7.804688l-44.015626 57.222656c-6.734374 8.753906-5.097656 21.3125 3.65625 28.046875 8.757813 6.734375 21.316407 5.09375 28.050782-3.660157l28.175781-36.632812 88.816406 115.21875h-148.832031c-22.054688 0-40-17.945312-40-40v-280c0-22.054688 17.945312-40 40-40h352c22.054688 0 40 17.945312 40 40zm-332-133.007812c-33.085938 0-60 26.917969-60 60 0 33.085938 26.914062 60 60 60s60-26.914062 60-60c0-33.082031-26.914062-60-60-60zm0 80c-11.027344 0-20-8.972656-20-20s8.972656-20 20-20 20 8.972656 20 20-8.972656 20-20 20zm365.910156 241.628906c7.9375 7.683594 8.144532 20.34375.460938 28.28125l-37.894532 39.148438c-.058593.058594-.117187.121094-.175781.179687-9.453125 9.519531-22.027343 14.761719-35.410156 14.761719-13.339844 0-25.882813-5.210938-35.320313-14.675781l-38.613281-38.085938c-7.863281-7.757812-7.953125-20.417969-.195312-28.28125 7.753906-7.867187 20.417969-7.953125 28.285156-.195312l25.84375 25.492187v-112.253906c0-11.046875 8.957031-20 20-20 11.046875 0 20 8.953125 20 20v111.644531l24.738281-25.554687c7.683594-7.9375 20.347656-8.140625 28.28125-.460938zm0 0"
                fill="url(#linear0)"
              />
            </svg>
          </button>
        </div>
        <div className={figcaption_palette} onClick={e => e.stopPropagation()}>
          {withLink ? (
            <strong>
              {parse(descriptionPalette, { replace: replaceLink })}
            </strong>
          ) : (
            <strong>{description ? description : alt} </strong>
          )}
          {paletteDescription ? <p>{paletteDescription}</p> : null}
        </div>
      </div>
    </>
  );
}

export default ImagePalettePost;
