import React, { useReducer, useCallback, createContext } from "react";

export const ContextFormatColor = createContext();

const initialState = {
  format: "hex",
};

const reducer = (state, action) => {
  if (action.type === "CHANGE_FORMAT") {
    return { format: action.payload };
  }

  return state;
};

export const FormatColorProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const ChangeFormat = useCallback(
    format => {
      dispatch({
        type: "CHANGE_FORMAT",
        payload: format,
      });
    },
    [dispatch]
  );

  const value = { format: state.format, ChangeFormat };

  return (
    <ContextFormatColor.Provider value={value}>
      {children}
    </ContextFormatColor.Provider>
  );
};
