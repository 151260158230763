import { useEffect } from "react";

const useAdManaSlot = ({ path, size, id }) => {
  useEffect(() => {
    const googletag = window.googletag || {};
    googletag.cmd = googletag.cmd || [];

    googletag.cmd.push(function () {
      let mapping = googletag
        .sizeMapping()
        .addSize(
          [1024, 768],
          [
            [970, 250],
            [728, 250],
            [970, 90],
            [728, 90],
          ]
        )
        .addSize([640, 480], [300, 250])
        .addSize(
          [0, 0],
          [
            [300, 250],
            [300, 75],
          ]
        )
        .build();

      googletag
        .defineSlot(path, size, id)
        .addService(googletag.pubads())
        .defineSizeMapping(mapping);

      googletag.pubads().enableSingleRequest();
      googletag.enableServices();
    });

    googletag.cmd.push(function () {
      googletag.display(id);
    });
  }, [path, size, id]);
};

export default useAdManaSlot;
