export const enBronzePalettes = [
  {
    title: "Monochromatic Bronze",
    palette: ["#CD7F32", "#8B4513", "#634b32", "#3f301a", "#271f0d"],
    description:
      "An elegant monochromatic palette that captures the richness and warmth of bronze in subtle and deep tones.",
    imageName: "bronze_monochromatic",
  },
  {
    title: "Bronze Sunset",
    palette: ["#CD7F32", "#FFD700", "#FFA500", "#FF6347", "#8B4513"],
    description: "A warm palette inspired by the colors of a bronze sunset.",
    imageName: "bronze_sunset",
  },
  {
    title: "Bronze Forest",
    palette: ["#CD7F32", "#556B2F", "#8FBC8F", "#2E8B57", "#32CD32"],
    description: "Earthy tones reminiscent of a bronze forest in autumn.",
    imageName: "bronze_forest",
  },
  {
    title: "Vintage Bronze",
    palette: ["#CD7F32", "#8B4513", "#696969", "#BDB76B", "#DAA520"],
    description: "A vintage-inspired palette with rich bronze hues.",
    imageName: "vintage_bronze",
  },
  {
    title: "Bronze Ocean",
    palette: ["#CD7F32", "#4682B4", "#87CEEB", "#87CEFA", "#4169E1"],
    description:
      "Cool blue tones paired with warm bronze for a serene oceanic feel.",
    imageName: "bronze_ocean",
  },
  {
    title: "Golden Bronze",
    palette: ["#CD7F32", "#FFD700", "#FFDF00", "#DAA520", "#B8860B"],
    description: "Shimmering gold accents complementing deep bronze hues.",
    imageName: "golden_bronze",
  },
  {
    title: "Autumn Bronze",
    palette: ["#CD7F32", "#8B4513", "#D2691E", "#FF7F50", "#FFA07A"],
    description:
      "Rich autumnal shades ranging from deep bronze to burnt orange.",
    imageName: "autumn_bronze",
  },
  {
    title: "Bronze Glow",
    palette: ["#CD7F32", "#FFD700", "#F0E68C", "#FF8C00", "#FF4500"],
    description: "Warm tones with a golden glow reminiscent of bronze.",
    imageName: "bronze_glow",
  },
  {
    title: "Bronze Meadows",
    palette: ["#CD7F32", "#8B4513", "#32CD32", "#556B2F", "#2E8B57"],
    description:
      "Earthy browns and greens evoking a tranquil meadow in bronze hues.",
    imageName: "bronze_meadows",
  },
  {
    title: "Bronze Elegance",
    palette: ["#CD7F32", "#8B4513", "#5F9EA0", "#778899", "#4682B4"],
    description:
      "Sophisticated shades of bronze and teal for a touch of elegance.",
    imageName: "bronze_elegance",
  },
  {
    title: "Rustic Bronze",
    palette: ["#CD7F32", "#8B4513", "#A0522D", "#696969", "#B8860B"],
    description: "Earthy tones with rustic charm, reminiscent of aged bronze.",
    imageName: "rustic_bronze",
  },
  {
    title: "Bronze Harmony",
    palette: ["#CD7F32", "#8B4513", "#B8860B", "#DAA520", "#FFD700"],
    description: "A harmonious blend of warm bronze and gold tones.",
    imageName: "bronze_harmony",
  },
  {
    title: "Bronze Mist",
    palette: ["#CD7F32", "#8B4513", "#D2B48C", "#BC8F8F", "#696969"],
    description:
      "Soft, misty hues with a bronze undertone for a subtle warmth.",
    imageName: "bronze_mist",
  },
  {
    title: "Bronze Dreams",
    palette: ["#CD7F32", "#D2691E", "#FF8C00", "#FF4500", "#B22222"],
    description: "Vibrant hues inspired by the fiery glow of bronze dreams.",
    imageName: "bronze_dreams",
  },
  {
    title: "Bronze Serenity",
    palette: ["#CD7F32", "#696969", "#B0C4DE", "#778899", "#B8860B"],
    description:
      "Tranquil blues paired with warm bronze for a serene atmosphere.",
    imageName: "bronze_serenity",
  },
  {
    title: "Bronze Chic",
    palette: ["#CD7F32", "#8B4513", "#D2691E", "#A0522D", "#B8860B"],
    description:
      "Chic and sophisticated shades of bronze for a stylish palette.",
    imageName: "bronze_chic",
  },
];

export const esBronzePalettes = [
  {
    title: "Bronce Monocromático",
    palette: ["#CD7F32", "#8B4513", "#634b32", "#3f301a", "#271f0d"],
    description:
      "Una elegante paleta monocromática que captura la riqueza y calidez del bronce en tonos sutiles y profundos.",
    imageName: "bronze_monochromatic",
  },
  {
    title: "Atardecer de Bronce",
    palette: ["#CD7F32", "#FFD700", "#FFA500", "#FF6347", "#8B4513"],
    description:
      "Una paleta cálida inspirada en los colores de un atardecer de bronce.",
    imageName: "bronze_sunset",
  },
  {
    title: "Bosque de Bronce",
    palette: ["#CD7F32", "#556B2F", "#8FBC8F", "#2E8B57", "#32CD32"],
    description: "Tonos terrosos que recuerdan a un bosque de bronce en otoño.",
    imageName: "bronze_forest",
  },
  {
    title: "Bronce Vintage",
    palette: ["#CD7F32", "#8B4513", "#696969", "#BDB76B", "#DAA520"],
    description: "Una paleta de inspiración vintage con ricos tonos de bronce.",
    imageName: "vintage_bronze",
  },
  {
    title: "Océano de Bronce",
    palette: ["#CD7F32", "#4682B4", "#87CEEB", "#87CEFA", "#4169E1"],
    description:
      "Tonos azules frescos combinados con bronce cálido para una sensación oceánica serena.",
    imageName: "bronze_ocean",
  },
  {
    title: "Bronce Dorado",
    palette: ["#CD7F32", "#FFD700", "#FFDF00", "#DAA520", "#B8860B"],
    description:
      "Acentos dorados brillantes que complementan los profundos tonos de bronce.",
    imageName: "golden_bronze",
  },
  {
    title: "Bronce Otoñal",
    palette: ["#CD7F32", "#8B4513", "#D2691E", "#FF7F50", "#FFA07A"],
    description:
      "Tonos otoñales ricos que van desde el bronce profundo hasta el naranja quemado.",
    imageName: "autumn_bronze",
  },
  {
    title: "Resplandor de Bronce",
    palette: ["#CD7F32", "#FFD700", "#F0E68C", "#FF8C00", "#FF4500"],
    description:
      "Tonos cálidos con un resplandor dorado que recuerda al bronce.",
    imageName: "bronze_glow",
  },
  {
    title: "Praderas de Bronce",
    palette: ["#CD7F32", "#8B4513", "#32CD32", "#556B2F", "#2E8B57"],
    description:
      "Marrones terrosos y verdes que evocan un prado tranquilo en tonos de bronce.",
    imageName: "bronze_meadows",
  },
  {
    title: "Elegancia de Bronce",
    palette: ["#CD7F32", "#8B4513", "#5F9EA0", "#778899", "#4682B4"],
    description:
      "Tonos sofisticados de bronce y verde azulado para un toque de elegancia.",
    imageName: "bronze_elegance",
  },
  {
    title: "Bronce Rústico",
    palette: ["#CD7F32", "#8B4513", "#A0522D", "#696969", "#B8860B"],
    description:
      "Tonos terrosos con encanto rústico, evocando al bronce envejecido.",
    imageName: "rustic_bronze",
  },
  {
    title: "Armonía de Bronce",
    palette: ["#CD7F32", "#8B4513", "#B8860B", "#DAA520", "#FFD700"],
    description: "Una mezcla armoniosa de tonos cálidos de bronce y dorado.",
    imageName: "bronze_harmony",
  },
  {
    title: "Niebla de Bronce",
    palette: ["#CD7F32", "#8B4513", "#D2B48C", "#BC8F8F", "#696969"],
    description:
      "Tonos suaves y brumosos con un subtono de bronce para un calor sutil.",
    imageName: "bronze_mist",
  },
  {
    title: "Sueños de Bronce",
    palette: ["#CD7F32", "#D2691E", "#FF8C00", "#FF4500", "#B22222"],
    description:
      "Tonos vibrantes inspirados en el resplandor ardiente de los sueños de bronce.",
    imageName: "bronze_dreams",
  },
  {
    title: "Serenidad de Bronce",
    palette: ["#CD7F32", "#696969", "#B0C4DE", "#778899", "#B8860B"],
    description:
      "Azules tranquilos combinados con bronce cálido para una atmósfera serena.",
    imageName: "bronze_serenity",
  },
  {
    title: "Chic de Bronce",
    palette: ["#CD7F32", "#8B4513", "#D2691E", "#A0522D", "#B8860B"],
    description:
      "Tonos chic y sofisticados de bronce para una paleta elegante.",
    imageName: "bronze_chic",
  },
];

export const ptBronzePalettes = [
  {
    title: "Bronze Monocromático",
    palette: ["#CD7F32", "#8B4513", "#634b32", "#3f301a", "#271f0d"],
    description:
      "Uma elegante paleta monocromática que captura a riqueza e o calor do bronze em tons sutis e profundos.",
    imageName: "bronze_monochromatic",
  },
  {
    title: "Pôr do Sol em Bronze",
    palette: ["#CD7F32", "#FFD700", "#FFA500", "#FF6347", "#8B4513"],
    description:
      "Uma paleta quente inspirada nas cores de um pôr do sol em bronze.",
    imageName: "bronze_sunset",
  },
  {
    title: "Floresta de Bronze",
    palette: ["#CD7F32", "#556B2F", "#8FBC8F", "#2E8B57", "#32CD32"],
    description: "Tons terrosos que lembram uma floresta de bronze no outono.",
    imageName: "bronze_forest",
  },
  {
    title: "Bronze Vintage",
    palette: ["#CD7F32", "#8B4513", "#696969", "#BDB76B", "#DAA520"],
    description: "Uma paleta inspirada no vintage com tons ricos de bronze.",
    imageName: "vintage_bronze",
  },
  {
    title: "Oceano de Bronze",
    palette: ["#CD7F32", "#4682B4", "#87CEEB", "#87CEFA", "#4169E1"],
    description:
      "Tons azuis frescos combinados com bronze quente para uma sensação oceânica serena.",
    imageName: "bronze_ocean",
  },
  {
    title: "Bronze Dourado",
    palette: ["#CD7F32", "#FFD700", "#FFDF00", "#DAA520", "#B8860B"],
    description:
      "Acentos dourados cintilantes que complementam os tons profundos de bronze.",
    imageName: "golden_bronze",
  },
  {
    title: "Bronze Outonal",
    palette: ["#CD7F32", "#8B4513", "#D2691E", "#FF7F50", "#FFA07A"],
    description:
      "Tons outonais ricos que vão desde o bronze profundo até o laranja queimado.",
    imageName: "autumn_bronze",
  },
  {
    title: "Brilho de Bronze",
    palette: ["#CD7F32", "#FFD700", "#F0E68C", "#FF8C00", "#FF4500"],
    description: "Tons quentes com um brilho dourado que lembra o bronze.",
    imageName: "bronze_glow",
  },
  {
    title: "Pradarias de Bronze",
    palette: ["#CD7F32", "#8B4513", "#32CD32", "#556B2F", "#2E8B57"],
    description:
      "Marrons terrosos e verdes que evocam um campo tranquilo em tons de bronze.",
    imageName: "bronze_meadows",
  },
  {
    title: "Elegância de Bronze",
    palette: ["#CD7F32", "#8B4513", "#5F9EA0", "#778899", "#4682B4"],
    description:
      "Tons sofisticados de bronze e azul esverdeado para um toque de elegância.",
    imageName: "bronze_elegance",
  },
  {
    title: "Bronze Rústico",
    palette: ["#CD7F32", "#8B4513", "#A0522D", "#696969", "#B8860B"],
    description:
      "Tons terrosos com charme rústico, evocando bronze envelhecido.",
    imageName: "rustic_bronze",
  },
  {
    title: "Harmonia de Bronze",
    palette: ["#CD7F32", "#8B4513", "#B8860B", "#DAA520", "#FFD700"],
    description: "Uma mistura harmoniosa de tons quentes de bronze e dourado.",
    imageName: "bronze_harmony",
  },
  {
    title: "Névoa de Bronze",
    palette: ["#CD7F32", "#8B4513", "#D2B48C", "#BC8F8F", "#696969"],
    description:
      "Tons suaves e enevoados com um subtom de bronze para um calor sutil.",
    imageName: "bronze_mist",
  },
  {
    title: "Sonhos de Bronze",
    palette: ["#CD7F32", "#D2691E", "#FF8C00", "#FF4500", "#B22222"],
    description:
      "Tons vibrantes inspirados no brilho ardente dos sonhos de bronze.",
    imageName: "bronze_dreams",
  },
  {
    title: "Serenidade de Bronze",
    palette: ["#CD7F32", "#696969", "#B0C4DE", "#778899", "#B8860B"],
    description:
      "Azuis tranquilos combinados com bronze quente para uma atmosfera serena.",
    imageName: "bronze_serenity",
  },
  {
    title: "Chique de Bronze",
    palette: ["#CD7F32", "#8B4513", "#D2691E", "#A0522D", "#B8860B"],
    description:
      "Tons chiques e sofisticados de bronze para uma paleta elegante.",
    imageName: "bronze_chic",
  },
];

export const frBronzePalettes = [
  {
    title: "Bronze Monochromatique",
    palette: ["#CD7F32", "#8B4513", "#634b32", "#3f301a", "#271f0d"],
    description:
      "Une élégante palette monochromatique qui capture la richesse et la chaleur du bronze dans des tons subtils et profonds.",
    imageName: "bronze_monochromatic",
  },
  {
    title: "Coucher de Soleil en Bronze",
    palette: ["#CD7F32", "#FFD700", "#FFA500", "#FF6347", "#8B4513"],
    description:
      "Une palette chaude inspirée par les couleurs d'un coucher de soleil en bronze.",
    imageName: "bronze_sunset",
  },
  {
    title: "Forêt de Bronze",
    palette: ["#CD7F32", "#556B2F", "#8FBC8F", "#2E8B57", "#32CD32"],
    description: "Des tons terreux rappelant une forêt de bronze en automne.",
    imageName: "bronze_forest",
  },
  {
    title: "Bronze Vintage",
    palette: ["#CD7F32", "#8B4513", "#696969", "#BDB76B", "#DAA520"],
    description:
      "Une palette inspirée du vintage avec des nuances riches de bronze.",
    imageName: "vintage_bronze",
  },
  {
    title: "Océan de Bronze",
    palette: ["#CD7F32", "#4682B4", "#87CEEB", "#87CEFA", "#4169E1"],
    description:
      "Des tons bleus frais associés à un bronze chaud pour une ambiance océanique sereine.",
    imageName: "bronze_ocean",
  },
  {
    title: "Bronze Doré",
    palette: ["#CD7F32", "#FFD700", "#FFDF00", "#DAA520", "#B8860B"],
    description:
      "Des accents dorés scintillants qui complètent les teintes profondes de bronze.",
    imageName: "golden_bronze",
  },
  {
    title: "Bronze Automnal",
    palette: ["#CD7F32", "#8B4513", "#D2691E", "#FF7F50", "#FFA07A"],
    description:
      "Des nuances automnales riches allant du bronze profond à l'orange brûlé.",
    imageName: "autumn_bronze",
  },
  {
    title: "Éclat de Bronze",
    palette: ["#CD7F32", "#FFD700", "#F0E68C", "#FF8C00", "#FF4500"],
    description: "Des tons chauds avec un éclat doré rappelant le bronze.",
    imageName: "bronze_glow",
  },
  {
    title: "Prairies de Bronze",
    palette: ["#CD7F32", "#8B4513", "#32CD32", "#556B2F", "#2E8B57"],
    description:
      "Des bruns terreux et des verts évoquant une prairie tranquille aux teintes de bronze.",
    imageName: "bronze_meadows",
  },
  {
    title: "Élégance de Bronze",
    palette: ["#CD7F32", "#8B4513", "#5F9EA0", "#778899", "#4682B4"],
    description:
      "Des nuances sophistiquées de bronze et de bleu-vert pour une touche d'élégance.",
    imageName: "bronze_elegance",
  },
  {
    title: "Bronze Rustique",
    palette: ["#CD7F32", "#8B4513", "#A0522D", "#696969", "#B8860B"],
    description:
      "Des tons terreux avec un charme rustique, évoquant le bronze vieilli.",
    imageName: "rustic_bronze",
  },
  {
    title: "Harmonie de Bronze",
    palette: ["#CD7F32", "#8B4513", "#B8860B", "#DAA520", "#FFD700"],
    description: "Un mélange harmonieux de tons chauds de bronze et d'or.",
    imageName: "bronze_harmony",
  },
  {
    title: "Brume de Bronze",
    palette: ["#CD7F32", "#8B4513", "#D2B48C", "#BC8F8F", "#696969"],
    description:
      "Des tons doux et brumeux avec un sous-ton de bronze pour une chaleur subtile.",
    imageName: "bronze_mist",
  },
  {
    title: "Rêves de Bronze",
    palette: ["#CD7F32", "#D2691E", "#FF8C00", "#FF4500", "#B22222"],
    description:
      "Des teintes vibrantes inspirées de l'éclat ardent des rêves de bronze.",
    imageName: "bronze_dreams",
  },
  {
    title: "Sérénité de Bronze",
    palette: ["#CD7F32", "#696969", "#B0C4DE", "#778899", "#B8860B"],
    description:
      "Des bleus tranquilles associés à un bronze chaud pour une atmosphère sereine.",
    imageName: "bronze_serenity",
  },
  {
    title: "Chic de Bronze",
    palette: ["#CD7F32", "#8B4513", "#D2691E", "#A0522D", "#B8860B"],
    description:
      "Des nuances chics et sophistiquées de bronze pour une palette élégante.",
    imageName: "bronze_chic",
  },
];
