import { useState } from "react";
import tinycolor from "tinycolor2";
import {
  analogousScheme,
  monochromaticScheme,
  splitComplementScheme,
  triadicScheme,
} from "../helpers/generateColorsSchemas";

const schemeColor = (scheme, color) => {
  switch (scheme) {
    case "complement":
      return [color, tinycolor(color).complement().toHexString()];
    case "split":
      return splitComplementScheme(color);
    case "monocromathic":
      return monochromaticScheme(color, 8);
    case "analogous":
      return analogousScheme(color, true);
    case "triadic":
      return triadicScheme(color, true);
    default:
      break;
  }
};

function useColorScheme(initialColor = "", scheme) {
  const [mainColor, setMainColor] = useState(initialColor);

  const colorScheme = schemeColor(scheme, mainColor);

  return [mainColor, setMainColor, colorScheme];
}

export default useColorScheme;
