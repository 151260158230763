import React, { useContext } from "react";
import {
  spinner_wrapper,
  spinner,
} from ".././styles/modules/spinner.module.css";
import { ContextLocale } from "../context/ContextLocale";

function Spinner({ spinnerGeneratePalette = false }) {
  const { lang } = useContext(ContextLocale);

  const textRender =
    lang === "es"
      ? "Cargando herramienta para generar y crear tus paletas de colores"
      : lang === "en"
      ? "Loading tool to generate and create your color palettes"
      : lang === "pt"
      ? "Ferramenta de carregamento para gerar e criar suas paletas de cores"
      : null;
  return (
    <div className={spinner_wrapper}>
      {spinnerGeneratePalette ? (
        <>
          <h2>{textRender}</h2>
          <div className={spinner}></div>
        </>
      ) : (
        <div className={spinner}></div>
      )}
    </div>
  );
}

export default Spinner;
