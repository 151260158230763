import React from "react";
import Colors from "../../../../src/components/PageColorPalette/Colors";
import { monochromaticScheme } from "../../../../src/helpers/generateColorsSchemas";

function MonocromathicSection({ colorName, colorHex, locale }) {
  const monocromathicScheme = monochromaticScheme(colorHex, 8);
  const headingText =
    locale === "es"
      ? `Esquema monocromático del color ${colorName}`
      : locale === "en"
      ? `Monochromatic scheme of the color ${colorName}`
      : locale === "pt"
      ? `Esquema monocromático da cor ${colorName}`
      : locale === "fr"
      ? `Schéma monochromatique de la couleur ${colorName}.`
      : "";
  const pText =
    locale === "es"
      ? `Este esquema tiene como base el color ${colorName} y esta compuesto por diferentes matices, tonalidades y sombras tanto oscuras como claras.`
      : locale === "en"
      ? `This scheme is based on the color ${colorName} and is composed of different hues, tones, and shades, both dark and light.`
      : locale === "pt"
      ? `Este esquema é baseado na cor ${colorName} e é composto por diferentes matizes, tons e sombras, tanto escuras quanto claras.`
      : locale === "fr"
      ? `Ce schéma a comme base la couleur ${colorName} et est composé de différentes nuances, tonalités et ombres, aussi bien sombres que claires`
      : "";
  return (
    <div>
      <h2 className="heading_color_page">{headingText}</h2>
      <Colors withLinkColor={false} palette={monocromathicScheme} />
      <p style={{ fontSize: "small" }}>{pText}</p>
    </div>
  );
}

export default MonocromathicSection;
