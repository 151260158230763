export const esDiningRoomPalettes = [
  {
    palette: ["#F1D1B5", "#D49A89", "#A2706A", "#573A34", "#2B2E4A"],
    paletteName: "Rustic Charm",
    paletteDescription:
      "Esta paleta cálida y terrosa crea un ambiente acogedor y rústico para disfrutar de comidas con familia y amigos.",
  },

  {
    palette: ["#FFF9C2", "#FFD95A", "#FFA41C", "#D46A14", "#8B420A"],
    paletteName: "Sunny Days",
    paletteDescription:
      "Esta paleta alegre y vibrante evoca la calidez y el brillo de un día de verano para disfrutar de comidas frescas y relajantes.",
  },

  {
    palette: ["#F9D1C9", "#D98880", "#C0392B", "#6E2C00", "#1B1B1B"],
    paletteName: "Red Wine",
    paletteDescription:
      "Esta paleta oscura y elegante crea un ambiente sofisticado y cálido para disfrutar de comidas gourmet y vino tinto.",
  },

  {
    palette: ["#D1E8E2", "#A3C5A8", "#6D8E6F", "#454E58", "#1D1E22"],
    paletteName: "Forest Retreat",
    paletteDescription:
      "Esta paleta tranquila y natural evoca la belleza y la serenidad de un bosque para disfrutar de comidas relajantes y enriquecedoras.",
  },

  {
    palette: ["#D6EAF8", "#7FB3D5", "#2980B9", "#1B4F72", "#1B2631"],
    paletteName: "Ocean Breeze",
    paletteDescription:
      "Esta paleta fresca y brillante evoca la tranquilidad y la frescura del mar para disfrutar de comidas ligeras y saludables.",
  },

  {
    palette: ["#F8C471", "#E59866", "#DC7633", "#A93226", "#641E16"],
    paletteName: "Spicy Fiesta",
    paletteDescription:
      "Esta paleta picante y divertida evoca la energía y el sabor de una fiesta mexicana para disfrutar de comidas llenas de sabor.",
  },

  {
    palette: ["#E6B0AA", "#C0392B", "#922B21", "#641E16", "#1B1B1B"],
    paletteName: "Intense Passion",
    paletteDescription:
      "Esta paleta apasionada y dramática crea un ambiente intenso y romántico para disfrutar de comidas especiales a la luz de las velas.",
  },

  {
    palette: ["#F9E79F", "#F4D03F", "#EB984E", "#6E2C00", "#1B1B1B"],
    paletteName: "Golden Harvest",
    paletteDescription:
      "Esta paleta cálida y dorada evoca la belleza y la abundancia de una cosecha para disfrutar de comidas reconfortantes y llenas de sabor.",
  },
  {
    palette: ["#D1C4E9", "#9575CD", "#5E35B1", "#311B92", "#1A237E"],
    paletteName: "Purple Haze",
    paletteDescription:
      "Esta paleta misteriosa y sofisticada evoca la energía y la pasión del rock clásico para disfrutar de comidas con estilo.",
  },

  {
    palette: ["#E8DAEF", "#D2B4DE", "#9B59B6", "#6C3483", "#1B2631"],
    paletteName: "Lavender Dreams",
    paletteDescription:
      "Esta paleta suave y relajante evoca la belleza y la serenidad de un jardín de lavanda para disfrutar de comidas tranquilas y enriquecedoras.",
  },

  {
    palette: ["#FADBD8", "#F5B7B1", "#EC7063", "#922B21", "#641E16"],
    paletteName: "Romantic Rose",
    paletteDescription:
      "Esta paleta romántica y femenina crea un ambiente dulce y apasionado para disfrutar de comidas íntimas y románticas.",
  },

  {
    palette: ["#D6DBDF", "#85929E", "#34495E", "#2C3E50", "#1B2631"],
    paletteName: "Industrial Chic",
    paletteDescription:
      "Esta paleta moderna y urbana evoca la elegancia y la simplicidad del diseño industrial para disfrutar de comidas con estilo.",
  },

  {
    palette: ["#FDEDEC", "#F5B7B1", "#E74C3C", "#922B21", "#641E16"],
    paletteName: "Candy Cane",
    paletteDescription:
      "Esta paleta dulce y festiva evoca la alegría y la energía de la Navidad para disfrutar de comidas en familia y amigos.",
  },

  {
    palette: ["#D5F5E3", "#ABEBC6", "#58D68D", "#27AE60", "#1B2631"],
    paletteName: "Fresh Greens",
    paletteDescription:
      "Esta paleta fresca y saludable evoca la vitalidad y la energía de la naturaleza para disfrutar de comidas ligeras y saludables.",
  },

  {
    palette: ["#F2D7D5", "#B88A8A", "#8B3A3A", "#5A1F1F", "#1B1B1B"],
    paletteName: "Vintage Charm",
    paletteDescription:
      "Esta paleta suave y romántica evoca la elegancia y el encanto del pasado para disfrutar de comidas con estilo y nostalgia.",
  },
];
