import React from "react";
import FaqItem from "./FaqItem";

function Faqs({
  title = "Preguntas frecuentes",
  items = [{ question: "Pregunta 1", answer: "Respuesta 1" }],
}) {
  // const faqJsonLdData = {
  //   "@context": "https://schema.org",
  //   "@type": "FAQPage",
  //   mainEntity: items.map(item => {
  //     return {
  //       "@type": "Question",
  //       name: item.question,
  //       acceptedAnswer: {
  //         "@type": "Answer",
  //         text: item.answer,
  //       },
  //     };
  //   }),
  // };

  // <script
  //       type="application/ld+json"
  //       dangerouslySetInnerHTML={{ __html: JSON.stringify(faqJsonLdData) }}
  //     />

  return (
    <section style={{ maxWidth: "1224px", margin: "0 auto" }}>
      <h2>{title}</h2>
      <div>
        {items.map(item => (
          <FaqItem item={item} />
        ))}
      </div>
    </section>
  );
}

export default Faqs;
