import React from "react";
import chroma from "chroma-js";

import {
  convertToCmyk,
  convertToHsl,
  convertToHsv,
} from "../../../../src/helpers/convertColors";
import {
  div_table_container,
  table_container,
  table_row,
  th,
} from "../../../../src/styles/modules/table_codes_color.module.css";

const TableCodesColor = ({ colorRender, locale = "es" }) => {
  const formatsColors = [
    {
      format: "hex",
      color: chroma(colorRender).hex(),
    },
    {
      format: "rgb",
      color: chroma(colorRender).css(),
    },
    {
      format: "hsl",
      color: convertToHsl(chroma(colorRender).hsl()),
    },
    {
      format: "cmyk",
      color: convertToCmyk(chroma(colorRender).cmyk()),
    },
    {
      format: "hsv",
      color: convertToHsv(chroma(colorRender).hsv()),
    },
  ];

  return (
    <section>
      <div className={div_table_container}>
        <table className={table_container}>
          <thead>
            <tr className={table_row}>
              <th className={th}>
                {locale === "es"
                  ? "formato"
                  : locale === "en"
                  ? "format"
                  : locale === "pt"
                  ? "formato"
                  : locale === "fr"
                  ? "format"
                  : null}
              </th>
              <th className={th}>
                {locale === "es"
                  ? "código"
                  : locale === "en"
                  ? "code"
                  : locale === "pt"
                  ? "código"
                  : locale === "fr"
                  ? "code"
                  : null}
              </th>
            </tr>
          </thead>
          <tbody>
            {formatsColors.map(item => (
              <tr key={item.color} className={table_row}>
                <td style={{ color: "gray" }}>{item.format}</td>
                <td style={{ color: "gray" }}>{item.color}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default TableCodesColor;
