import React, { useCallback, useState } from "react";
import Dropzone from "./Dropzone";
import ColorsFromImage from "./ColorsFromImage";
import Image from "./Image";
import {
  palette_from_image_wrapper,
  dropd_zone_and_palette_wrapper,
} from "../../../styles/modules/palette_from_image.module.css";
// import AdWithPlaceholder from "../../Ads/AdWithPlaceholder";

function PaletteFromImage({ locale }) {
  const [images, setImages] = useState([]);

  const onDrop = useCallback(acceptedFiles => {
    acceptedFiles.map(file => {
      const reader = new FileReader();
      reader.onload = function (e) {
        setImages(prevState => [
          { id: Math.random(), src: e.target.result },

          // ...prevState,
        ]);
      };
      reader.readAsDataURL(file);
      return file;
    });
  }, []);

  return (
    <section className={palette_from_image_wrapper}>
      <h1>
        {locale === "es"
          ? "Obtener paleta de colores de una imagen online"
          : locale === "en"
          ? "Get color palette of an image online"
          : locale === "pt"
          ? "Obter paleta de cores de uma imagem online"
          : locale === "fr"
          ? "générateur de palette de couleurs à partir d'une image"
          : null}
      </h1>
      <div className={dropd_zone_and_palette_wrapper}>
        <div>
          <Dropzone onDrop={onDrop} accept={"image/*"} locale={locale} />
          <Image images={images} />
        </div>

        <ColorsFromImage locale={locale} images={images} />
      </div>
      {/* <AdWithPlaceholder
        ins={`<ins class="adsbygoogle"
     style="display:block"
     data-ad-format="autorelaxed"
     data-ad-client="ca-pub-3833126112632805"
     data-ad-slot="9954662162"></ins>`}
      /> */}
    </section>
  );
}

export default PaletteFromImage;
