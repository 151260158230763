export const esVintagePalette = [
  {
    palette: ["#ffcccb", "#add8e6", "#90ee90", "#ffdead", "#f0e68c"],
    paletteName: "Dulces recuerdos",
    paletteDescription:
      "Esta paleta de colores pastel evoca la nostalgia de los dulces recuerdos de antaño, con tonos suaves y delicados que transmiten una sensación de calma y serenidad.",
  },

  {
    palette: ["#704214", "#b8906f", "#a67d5c", "#dbc6b1", "#e8d8c7"],
    paletteName: "Tonalidades sepia",
    paletteDescription:
      "Inspirada en las fotografías antiguas, esta paleta de colores sepia recrea la cálida y nostálgica atmósfera del pasado, con tonos terrosos y suaves que evocan un sentido de tradición y elegancia.",
  },

  {
    palette: ["#ff6347", "#ffd700", "#8a2be2", "#ff4500", "#40e0d0"],
    paletteName: "Estilo retro",
    paletteDescription:
      "Con colores brillantes y audaces, esta paleta de colores retro captura la energía y la vibración de las décadas pasadas, con tonos llamativos y contrastantes que evocan una sensación de diversión y aventura.",
  },

  {
    palette: ["#f08080", "#ffcccb", "#e6e6fa", "#ffd700", "#dcdcdc"],
    paletteName: "Romance vintage",
    paletteDescription:
      "Esta paleta de colores vintage romántica presenta tonos suaves y delicados que evocan la elegancia y la sofisticación de la época pasada, con colores cálidos y dulces que transmiten una sensación de romance y nostalgia.",
  },

  {
    palette: ["#8b4513", "#8fbc8f", "#ff4500", "#ffa07a", "#daa520"],
    paletteName: "Vibrante bohemia",
    paletteDescription:
      "Inspirada en el estilo bohemio de los años 60 y 70, esta paleta de colores presenta tonos ricos y vibrantes que evocan la libertad, la creatividad y la espiritualidad de la época, con colores terrosos y llamativos que se combinan en armonía.",
  },

  {
    palette: ["#b8860b", "#8b008b", "#cd5c5c", "#4169e1", "#ff1493"],
    paletteName: "Glamour art deco",
    paletteDescription:
      "Esta paleta de colores está inspirada en el estilo art deco de los años 20 y 30, con tonos ricos y lujosos que evocan el glamour y la opulencia de la época, con colores oscuros y metálicos que transmiten una sensación de elegancia y sofisticación.",
  },
  {
    palette: ["#fadede", "#f0e5e5", "#c0c0c0", "#8b4513", "#8b008b"],
    paletteName: "Encanto shabby chic",
    paletteDescription:
      "Esta paleta de colores está inspirada en el estilo shabby chic, con tonos suaves y desgastados que evocan la sensación de lo antiguo y lo vintage, con colores empolvados y pálidos que transmiten una sensación de encanto y nostalgia.",
  },
  {
    palette: ["#800000", "#800080", "#a0522d", "#4b0082", "#f4a460"],
    paletteName: "Elegancia victoriana",
    paletteDescription:
      "Esta paleta de colores se basa en la época victoriana, con tonos oscuros y ricos que evocan la opulencia y la elegancia de la época, con colores profundos y sofisticados que transmiten una sensación de clase y refinamiento.",
  },

  {
    palette: ["#ff69b4", "#66cdaa", "#ffd700", "#9370db", "#ffa07a"],
    paletteName: "Retro pastel",
    paletteDescription:
      "Esta paleta de colores combina la nostalgia retro con tonos pastel suaves y dulces, evocando la sensación de los años pasados con colores brillantes y suaves que transmiten una sensación de diversión y frescura.",
  },
];
