import React from "react";
import Highlight, { defaultProps } from "prism-react-renderer";
import { pre } from "./styles.module.css";

export default function Code({ snippet, language }) {
  return (
    <Highlight {...defaultProps} code={snippet} language={language}>
      {({ className, style, tokens, getLineProps, getTokenProps }) => (
        <pre className={pre} style={style}>
          {tokens.map((line, i) => (
            <div key={i} {...getLineProps({ line, key: i })}>
              {line.map((token, key) => (
                <span key={key} {...getTokenProps({ token, key })} />
              ))}
            </div>
          ))}
        </pre>
      )}
    </Highlight>
  );
}
