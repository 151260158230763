// extracted by mini-css-extract-plugin
export var animatetop = "modal_palette-module--animatetop--90979";
export var close_button = "modal_palette-module--close_button--adcba";
export var download_button = "modal_palette-module--download_button--1d974";
export var modal_ad = "modal_palette-module--modal_ad--e7bca";
export var modal_bullet_points = "modal_palette-module--modal_bullet_points--b8f74";
export var modal_color = "modal_palette-module--modal_color--07e60";
export var modal_content = "modal_palette-module--modal_content--90841";
export var modal_content_palette = "modal_palette-module--modal_content_palette--bc6ef";
export var modal_dialog = "modal_palette-module--modal_dialog--b1bbb";
export var modal_title = "modal_palette-module--modal_title--924c1";
export var slideIn = "modal_palette-module--slide-in--afd82";