import React from "react";
import useColorScheme from "../hooks/useColorScheme";
import { grid } from "../components/Modals/PaletteModal";
import ColorPickerScheme from "./PostColorScheme/ColorPickerScheme";
import Colors from "./PageColorPalette/Colors";
import chroma from "chroma-js";
import AdWithPlaceholder from "./Ads/AdWithPlaceholder";

function SchemesColorsPage({
  color = "#0080ff",
  schemeName = "complement",
  titleScheme = "Esquema complementario",
  tittleForShades,
  locale,
}) {
  const [mainColor, setMainColor, colorScheme] = useColorScheme(
    color,
    schemeName
  );

  return (
    <>
      <ColorPickerScheme
        mainColor={mainColor}
        setMainColor={setMainColor}
        colorScheme={colorScheme}
        schemeName={titleScheme}
        locale={locale}
      />
      <AdWithPlaceholder
        ins={`<!-- ptdc-tone -->
  <ins class="adsbygoogle"
     style="display:block"
     data-ad-client="ca-pub-3833126112632805"
     data-ad-slot="1043804843"
     data-ad-format="auto"
     data-full-width-responsive="true"></ins>`}
      />
      <h2>{tittleForShades}</h2>
      <div className={grid}>
        <Colors
          palette={chroma
            .scale(colorScheme.map(color => color))
            .mode("lch")
            .colors(10)}
        />
      </div>
    </>
  );
}

export default SchemesColorsPage;
