export const esInstagramPalettes = [
  {
    palette: ["#F4CDA5", "#F16B6F", "#F1A158", "#80CFA9", "#DDBF93"],
    paletteName: "Verano Tropical",
    paletteDescription:
      "Tonos cálidos y vibrantes inspirados en la naturaleza tropical para una estética veraniega.",
  },

  {
    palette: ["#F063B8", "#4F4FDA", "#F3B562", "#36BCF7", "#F06666"],
    paletteName: "Vibrante Juvenil",
    paletteDescription:
      "Colores audaces y juveniles que llaman la atención y añaden energía a tu perfil de Instagram.",
  },

  {
    palette: ["#000000", "#E6E6E6", "#8F8F8F", "#F7F7F7", "#DADADA"],
    paletteName: "Minimalista Elegante",
    paletteDescription:
      "Tonos neutros y elegantes para una estética minimalista y sofisticada en tu perfil de Instagram.",
  },

  {
    palette: ["#6BAE76", "#C6DAB7", "#EFEFD0", "#F5D68E", "#E6B655"],
    paletteName: "Naturaleza Serena",
    paletteDescription:
      "Colores suaves y relajantes inspirados en la naturaleza para una estética serena y tranquila.",
  },

  {
    palette: ["#FFC06B", "#FF8361", "#FF5E5E", "#FF9C6B", "#FFA26B"],
    paletteName: "Tonalidad Cálida",
    paletteDescription:
      "Tonos cálidos y vibrantes que evocan la calidez del sol para una estética acogedora y enérgica.",
  },

  {
    palette: ["#A4DFFA", "#F9F1C1", "#FADCD9", "#FAD8C0", "#FEF0C1"],
    paletteName: "Playa Relajada",
    paletteDescription:
      "Colores inspirados en la playa y el mar para una estética relajada y fresca en tu perfil de Instagram.",
  },

  {
    palette: ["#FDD6B4", "#FDCDA4", "#B6E5C1", "#FFCBB4", "#FFD8B4"],
    paletteName: "Primavera Fresca",
    paletteDescription:
      "Tonos frescos y suaves que evocan la belleza de la primavera para una estética fresca y rejuvenecedora.",
  },

  {
    palette: ["#E5A773", "#CC745B", "#C18E7D", "#D6B28C", "#EFD4A4"],
    paletteName: "Otoño Cálido",
    paletteDescription:
      "Colores cálidos y terrosos que evocan la belleza del otoño para una estética cálida y acogedora en tu perfil de Instagram.",
  },

  {
    palette: ["#BCCED9", "#E0E6F0", "#9AA9B9", "#D3DCE6", "#F0F4F8"],
    paletteName: "Invernal Elegante",
    paletteDescription:
      "Tonos fríos y elegantes que evocan la pureza y la tranquilidad del invierno para una estética sofisticada.",
  },

  {
    palette: ["#FEB2B2", "#F6D6D6", "#FBD4D4", "#FCD7D7", "#FBEAEA"],
    paletteName: "Romántica Dulce",
    paletteDescription:
      "Colores suaves y románticos que evocan la dulzura y la ternura del amor para una estética romántica en tu perfil de Instagram.",
  },

  {
    palette: ["#EF476F", "#FFD166", "#06D6A0", "#118AB2", "#073B4C"],
    paletteName: "Moda Urbana",
    paletteDescription:
      "Colores audaces y modernos inspirados en la moda urbana para una estética urbana y vanguardista en tu perfil de Instagram.",
  },

  {
    palette: ["#FFCDB2", "#F9B5AC", "#E17B77", "#DFBFAE", "#E8CBA4"],
    paletteName: "Vintage Retro",
    paletteDescription:
      "Tonos nostálgicos y retro que evocan la estética vintage para una estética clásica y atemporal en tu perfil de Instagram.",
  },

  {
    palette: ["#F44336", "#FFC107", "#4CAF50", "#03A9F4", "#9C27B0"],
    paletteName: "Deportiva Energética",
    paletteDescription:
      "Colores enérgicos y dinámicos inspirados en el deporte y la actividad física para una estética activa y deportiva en tu perfil de Instagram.",
  },

  {
    palette: ["#00C2CB", "#FCF300", "#F88400", "#FA6400", "#F00000"],
    paletteName: "Tecnológica Futurista",
    paletteDescription:
      "Colores brillantes y futuristas inspirados en la tecnología y la innovación para una estética moderna y vanguardista en tu perfil de Instagram.",
  },

  {
    palette: ["#FF0000", "#FFA500", "#FFFF00", "#008000", "#0000FF"],
    paletteName: "Arcoíris Alegre",
    paletteDescription:
      "Colores vivos y alegres que evocan la belleza de un arcoíris para una estética colorida y llena de alegría en tu perfil de Instagram.",
  },

  {
    palette: ["#222831", "#393E46", "#4E525D", "#7F848E", "#D9D0DD"],
    paletteName: "Monocromática Elegante",
    paletteDescription:
      "Tonos oscuros y elegantes en una paleta monocromática para una estética sofisticada y minimalista en tu perfil de Instagram.",
  },

  {
    palette: ["#F8E16C", "#FEAA3A", "#F36E21", "#F14B24", "#008080"],
    paletteName: "Playa Veraniega",
    paletteDescription:
      "Colores cálidos y vibrantes que evocan la playa y el verano para una estética fresca y playera en tu perfil de Instagram.",
  },

  {
    palette: ["#B2D8D8", "#D6EFD6", "#EFEFEF", "#C9C9A7", "#A7A788"],
    paletteName: "Naturaleza Serena",
    paletteDescription:
      "Tonos suaves y serenos inspirados en la naturaleza para una estética relajante y armoniosa en tu perfil de Instagram.",
  },
];
