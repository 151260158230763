import chroma from "chroma-js";
import React, { useState } from "react";
import { HexColorPicker } from "react-colorful";
import { HexColorInput } from "react-colorful";

export default function BackgroundContrastChecker({
  colorHex,
  colorName,
  locale,
}) {
  const [color, setColor] = useState("#ffffff");
  const contrast = chroma.contrast(colorHex, color);

  return (
    <div
      style={{
        display: "flex",
        gap: "1rem",
        alignItems: "center",
        flexWrap: "wrap",
      }}
    >
      <div>
        <strong style={{ margin: "1rem 0", display: "block" }}>
          {locale === "es"
            ? `Cambiar el color del texto`
            : locale === "en"
            ? `Change the text color.`
            : locale === "pt"
            ? `Alterar a cor do texto.`
            : locale === "fr"
            ? `Changer la couleur du texte.`
            : ""}
        </strong>
        <div>
          <HexColorPicker color={color} onChange={setColor} />
          <HexColorInput color={color} onChange={setColor} />
        </div>

        <div style={{ display: "flex", alignItems: "center", margin: "0 0" }}>
          <strong>
            {locale === "es"
              ? `Relación de contraste: ${contrast.toFixed(2)}`
              : locale === "en"
              ? `Contrast ratio: ${contrast.toFixed(2)}`
              : locale === "pt"
              ? `Relação de contraste: ${contrast.toFixed(2)}`
              : locale === "fr"
              ? `Relation de contraste: ${contrast.toFixed(2)}`
              : ""}
          </strong>

          <svg
            fill={contrast > 4.6 ? "green" : "red"}
            height="24"
            viewBox="0 0 20 24"
            width="34"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="m4 21h1v-13h-1c-1.104 0-2 .896-2 2v9c0 1.104.896 2 2 2zm16-13h-7l1.122-3.368c.432-1.295-.532-2.632-1.897-2.632h-.225l-5 5.438v13.562h11l3.912-8.596c.025-.113.064-.29.088-.404v-2c0-1.104-.896-2-2-2z" />
          </svg>
        </div>
      </div>

      <div
        style={{
          backgroundColor: colorHex,
          height: "210px",
          width: 200,
          borderRadius: 8,
          padding: "1rem",
        }}
      >
        <p style={{ color: color }}>
          <small>
            {locale === "es"
              ? `Prueba de contrast ratio del color ${colorName} como fondo`
              : locale === "en"
              ? `Contrast ratio test of the color ${colorName} as a background`
              : locale === "pt"
              ? `Teste de relação de contraste da cor ${colorName} como fundo`
              : locale === "fr"
              ? `Test du rapport de contraste de la couleur ${colorName} en tant que fond.`
              : ""}
          </small>
        </p>
      </div>
    </div>
  );
}
