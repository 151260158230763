function convertToHsl(colorArr = []) {
  let [h, s, l] = colorArr;

  const hue = Number(h) ? Math.ceil(h) : 0;
  const saturation = Math.ceil(s * 100);
  const lightness = Math.ceil(l * 100);

  return `hsl(${hue},${saturation}%,${lightness}%)`;
}

function convertToCmyk(colorArr = []) {
  let [c, m, y, k] = colorArr;

  const cian = Math.ceil(c * 100);
  const magenta = Math.ceil(m * 100);
  const yellow = Math.ceil(y * 100);
  const key = Math.ceil(k * 100);
  return `cmyk(${cian}, ${magenta}, ${yellow}, ${key})`;
}

function convertToHsv(colorArr = []) {
  let [h, s, v] = colorArr;

  const hue = Number(h) ? Math.ceil(h) : 0;
  const saturation = Math.ceil(s * 100);
  const value = Math.ceil(v * 100);

  return `hsv(${hue}, ${saturation}%, ${value}%)`;
}

export { convertToHsl, convertToCmyk, convertToHsv };
