export const esColdPalettes = [
  {
    palette: ["#84DCC6", "#C7F9F1", "#F0FFF3", "#D5D8DC", "#2B2D42"],
    paletteName: "Aurora boreal",
    paletteDescription:
      "Esta paleta inspirada en las luces del norte combina tonos verdes y azules fríos con toques de gris y blanco para crear un ambiente tranquilo y relajante.",
  },

  {
    palette: ["#E8F0FF", "#B0C7FF", "#8F8FFF", "#5758BB", "#35355E"],
    paletteName: "Gélida",
    paletteDescription:
      "Esta paleta utiliza tonos azules y morados para crear una sensación de frescura y pureza. Los tonos más oscuros se combinan con tonos más claros para crear contraste y equilibrio.",
  },

  {
    palette: ["#66A5AD", "#C4DFE6", "#EBF5EE", "#E8E8E8", "#2B2D42"],
    paletteName: "Océano profundo",
    paletteDescription:
      "Esta paleta inspirada en el mar utiliza tonos azules y verdes suaves para crear una sensación de calma y serenidad. El gris claro y el blanco agregan equilibrio y neutralidad.",
  },

  {
    palette: ["#D8E8F0", "#C4D1DC", "#A7B6C7", "#7F99B2", "#40577E"],
    paletteName: "Invernal",
    paletteDescription:
      "Esta paleta utiliza tonos grises y azules fríos para crear una sensación de invierno. Los tonos más claros se combinan con tonos más oscuros para crear profundidad y contraste.",
  },

  {
    palette: ["#E9F2F9", "#B9D1EA", "#8CACC5", "#547AA8", "#2E3E5C"],
    paletteName: "Polar",
    paletteDescription:
      "Esta paleta utiliza tonos azules suaves y blancos para crear una sensación de nieve y hielo. El tono azul más oscuro se utiliza para agregar profundidad y contraste.",
  },

  {
    palette: ["#93A8AC", "#D7E1E5", "#F7F7F7", "#F2994A", "#212121"],
    paletteName: "Misteriosa",
    paletteDescription:
      "Esta paleta utiliza tonos grises y blancos para crear una sensación de misterio y elegancia. El tono naranja se utiliza para agregar un toque de contraste y energía.",
  },
  {
    palette: ["#E8F1F2", "#B9D9DC", "#8ABCCF", "#638EAF", "#4A4E69"],
    paletteName: "Glaciar",
    paletteDescription:
      "Esta paleta utiliza tonos azules y grises fríos para crear una sensación de hielo y frescura. El tono más oscuro se utiliza para agregar profundidad y contraste, mientras que el tono más claro aporta luminosidad y equilibrio.",
  },

  {
    palette: ["#E1F1F2", "#C6D5D8", "#AAB9BD", "#8EA0A4", "#6F868A"],
    paletteName: "Neblina",
    paletteDescription:
      "Esta paleta utiliza tonos azules y grises suaves para crear una sensación de neblina y bruma. El tono más oscuro se utiliza para agregar profundidad y contraste, mientras que el tono más claro aporta luminosidad y equilibrio.",
  },

  {
    palette: ["#CAD2C5", "#E0E3C0", "#F2F2C9", "#F9D9C2", "#D8A7B1"],
    paletteName: "Cráter",
    paletteDescription:
      "Esta paleta utiliza tonos verdes y amarillos suaves para crear una sensación de paisajes desérticos. El tono rosa se utiliza para agregar un toque de contraste y energía.",
  },
];
