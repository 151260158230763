export const esGoldPalettes = [
  {
    palette: ["#efb810", "#f7d99c", "#e8a344", "#d97a1f", "#a83504"],
    paletteName: "Cálida y energética",
    paletteDescription:
      "Esta paleta es perfecta para diseños que requieren una sensación de calidez y energía. El amarillo dorado se combina con tonos naranjas y rojizos para crear una paleta cálida y acogedora.",
  },
  {
    palette: ["#efb810", "#d1aa7e", "#a98652", "#745129", "#3d2c0f"],
    paletteName: "Elegante y sofisticada",
    paletteDescription:
      "Esta paleta utiliza tonos neutros para resaltar la elegancia del color dorado. Es perfecta para diseños que requieren un toque sofisticado y refinado.",
  },
  {
    palette: ["#efb810", "#f7d382", "#a2b7c0", "#547c8c", "#2c3e50"],
    paletteName: "Retro y nostálgica",
    paletteDescription:
      "Esta paleta utiliza tonos azules y grises para crear un ambiente nostálgico y retro. El dorado añade un toque de glamour y sofisticación a la paleta.",
  },
  {
    palette: ["#efb810", "#f7b956", "#c68447", "#7f4622", "#44220c"],
    paletteName: "Cálida y otoñal",
    paletteDescription:
      "Esta paleta utiliza tonos cálidos y terrosos para crear una sensación de otoño. El dorado se combina con tonos marrones y naranjas para crear una paleta cálida y acogedora.",
  },

  {
    palette: ["#efb810", "#f7e1b5", "#c2d1d9", "#6b8fa6", "#2b4f6e"],
    paletteName: "Relajante y costera",
    paletteDescription:
      "Esta paleta utiliza tonos azules y grises para crear una sensación de playa y mar. El dorado se combina con tonos claros y relajantes para crear una paleta costera y relajante.",
  },
  {
    palette: ["#efb810", "#f7d59b", "#c68c53", "#7f512e", "#421b0e"],
    paletteName: "Lujo y exclusividad",
    paletteDescription:
      "Esta paleta utiliza tonos marrones y tostados para crear una sensación de lujo y exclusividad. El dorado añade un toque de glamour y sofisticación a la paleta.",
  },
];
