export const esApuntesPalettes = [
  {
    palette: ["#F8C9B9", "#F2836B", "#7E6551", "#3D3B47", "#FFFFFF"],
    paletteName: "Autumn Leaves",
    paletteDescription:
      "Esta paleta evoca los colores cálidos y ricos de las hojas de otoño, ideal para tomar apuntes en la temporada de caída.",
  },

  {
    palette: ["#F5CBA7", "#F0B27A", "#8E44AD", "#2C3E50", "#FFFFFF"],
    paletteName: "Candy Crush",
    paletteDescription:
      "Esta paleta es dulce y vibrante como un juego de dulces. Perfecto para tomar notas de una forma divertida y colorida.",
  },

  {
    palette: ["#D6EAF8", "#7FB3D5", "#2980B9", "#1C2833", "#FFFFFF"],
    paletteName: "Ocean Breeze",
    paletteDescription:
      "Esta paleta fresca y tranquila evoca la serenidad y la calma del océano, perfecta para tomar apuntes relajantes.",
  },

  {
    palette: ["#F7DC6F", "#F5B041", "#D35400", "#2C3E50", "#FFFFFF"],
    paletteName: "Summer Fun",
    paletteDescription:
      "Esta paleta es vibrante y alegre como un día de verano. Perfecto para tomar apuntes en un ambiente lleno de energía.",
  },

  {
    palette: ["#FADBD8", "#F5B7B1", "#EC7063", "#922B21", "#FFFFFF"],
    paletteName: "Cherry Blossom",
    paletteDescription:
      "Esta paleta romántica y femenina evoca la belleza de los cerezos en flor, perfecta para tomar notas en primavera.",
  },

  {
    palette: ["#D5F5E3", "#ABEBC6", "#58D68D", "#27AE60", "#FFFFFF"],
    paletteName: "Minty Fresh",
    paletteDescription:
      "Esta paleta fresca y saludable evoca la vitalidad y la energía de la naturaleza, perfecta para tomar apuntes sobre bienestar y salud.",
  },

  {
    palette: ["#F0E68C", "#BDB76B", "#6E2C00", "#2C3E50", "#FFFFFF"],
    paletteName: "Golden Age",
    paletteDescription:
      "Esta paleta evoca la sofisticación y la elegancia de la era dorada del cine, perfecta para tomar apuntes sobre arte y cultura.",
  },

  {
    palette: ["#D6DBDF", "#85929E", "#34495E", "#2C3E50", "#FFFFFF"],
    paletteName: "Slate Grey",
    paletteDescription:
      "Esta paleta elegante y sobria es perfecta para tomar apuntes de negocios o estudios formales.",
  },

  {
    palette: ["#EBDEF0", "#A569BD", "#8E44AD", "#2C3E50", "#FFFFFF"],
    paletteName: "Purple Haze",
    paletteDescription:
      "Esta paleta misteriosa y sofisticada evoca la energía y la pasión del rock clásico, perfecta para tomar apuntes con actitud.",
  },
  {
    palette: ["#E8DAEF", "#D2B4DE", "#9B59B6", "#2C3E50", "#FFFFFF"],
    paletteName: "Lavender Fields",
    paletteDescription:
      "Esta paleta evoca la belleza y la fragancia de los campos de lavanda, perfecta para tomar apuntes relajantes y aromáticos.",
  },

  {
    palette: ["#D5F5E3", "#ABEBC6", "#7DCEA0", "#27AE60", "#FFFFFF"],
    paletteName: "Green Tea",
    paletteDescription:
      "Esta paleta suave y fresca evoca la tranquilidad y la armonía de la cultura del té, perfecta para tomar apuntes sobre filosofía o meditación.",
  },

  {
    palette: ["#E6B0AA", "#D98880", "#A93226", "#2C3E50", "#FFFFFF"],
    paletteName: "Red Alert",
    paletteDescription:
      "Esta paleta vibrante y enérgica evoca la emoción y la intensidad de una alarma roja, perfecta para tomar apuntes en situaciones de emergencia.",
  },

  {
    palette: ["#D7DBDD", "#ABB2B9", "#4C4C4C", "#2C3E50", "#FFFFFF"],
    paletteName: "Black and White",
    paletteDescription:
      "Esta paleta clásica y atemporal es perfecta para tomar apuntes sobrios y elegantes en cualquier ocasión.",
  },

  {
    palette: ["#F2D7D5", "#F0B8B1", "#9C640C", "#2C3E50", "#FFFFFF"],
    paletteName: "Copper Tones",
    paletteDescription:
      "Esta paleta cálida y brillante evoca la belleza del cobre y es perfecta para tomar apuntes sobre joyería o decoración.",
  },

  {
    palette: ["#FDEDEC", "#F5B7B1", "#E74C3C", "#2C3E50", "#FFFFFF"],
    paletteName: "Raspberry Sorbet",
    paletteDescription:
      "Esta paleta dulce y deliciosa evoca la suavidad y la cremosidad de un helado de frutas, perfecta para tomar apuntes sobre gastronomía.",
  },

  {
    palette: ["#F8C471", "#F0B27A", "#E67E22", "#2C3E50", "#FFFFFF"],
    paletteName: "Peachy Keen",
    paletteDescription:
      "Esta paleta suave y delicada evoca la dulzura y la suavidad del durazno, perfecta para tomar apuntes sobre moda o belleza.",
  },
];
