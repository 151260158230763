export const esDoubleBedroomsPalettes = [
  {
    palette: ["#1E272E", "#D0D3D4", "#ECF0F1", "#95A5A6", "#FFFFFF"],
    paletteName: "Minimalist Zen",
    paletteDescription:
      "Esta paleta suave y minimalista evoca la calma y la serenidad de la cultura zen, perfecta para un dormitorio matrimonial moderno y relajante.",
  },

  {
    palette: ["#FFFFFF", "#FFC300", "#C70039", "#581845", "#000000"],
    paletteName: "Black, White and Gold",
    paletteDescription:
      "Esta paleta elegante y sofisticada combina los clásicos colores blanco y negro con un toque de oro, perfecta para un dormitorio matrimonial moderno y lujoso.",
  },

  {
    palette: ["#F7DC6F", "#F39C12", "#E74C3C", "#34495E", "#FFFFFF"],
    paletteName: "Warm Tones",
    paletteDescription:
      "Esta paleta cálida y acogedora evoca la calidez y la comodidad del hogar, perfecta para un dormitorio matrimonial moderno y confortable.",
  },

  {
    palette: ["#FFFFFF", "#FFCDD2", "#EF9A9A", "#E57373", "#B71C1C"],
    paletteName: "Shades of Pink",
    paletteDescription:
      "Esta paleta suave y femenina combina diferentes tonos de rosa, perfecta para un dormitorio matrimonial moderno y romántico.",
  },

  {
    palette: ["#212F3D", "#C8D6E5", "#EAF2F8", "#AAB7B8", "#FFFFFF"],
    paletteName: "Cozy Blues",
    paletteDescription:
      "Esta paleta acogedora y relajante combina diferentes tonos de azul, perfecta para un dormitorio matrimonial moderno y tranquilo.",
  },

  {
    palette: ["#F8C471", "#F0B27A", "#E67E22", "#2C3E50", "#FFFFFF"],
    paletteName: "Warm Orange",
    paletteDescription:
      "Esta paleta cálida y vibrante combina diferentes tonos de naranja, perfecta para un dormitorio matrimonial moderno y lleno de energía.",
  },

  {
    palette: ["#FFFFFF", "#A2D9CE", "#73C6B6", "#4D9F9F", "#5B5B5B"],
    paletteName: "Minty Fresh",
    paletteDescription:
      "Esta paleta fresca y relajante combina diferentes tonos de menta, perfecta para un dormitorio matrimonial moderno y refrescante.",
  },

  {
    palette: ["#2C3E50", "#F4D03F", "#F5B041", "#F7DC6F", "#FFFFFF"],
    paletteName: "Navy and Yellow",
    paletteDescription:
      "Esta paleta elegante y vibrante combina el clásico color azul marino con un toque de amarillo, perfecta para un dormitorio matrimonial moderno y lleno de personalidad.",
  },
  {
    palette: ["#353535", "#F5F5F5", "#FFB900", "#008080", "#FF5733"],
    paletteName: "Tonos Terrosos Modernos",
    paletteDescription:
      "Esta paleta presenta tonos cálidos y terrosos de marrón y verde, con un toque de naranja. Perfecta para un diseño de dormitorio moderno y acogedor.",
  },
  {
    palette: ["#F8E9A1", "#DB5A42", "#7D1935", "#0E103D", "#F5F5F5"],
    paletteName: "Romántico y Dramático",
    paletteDescription:
      "Esta paleta presenta tonos de rojo, rosa y azul oscuro para un ambiente romántico y dramático. Perfecta para una pareja apasionada.",
  },
  {
    palette: ["#586BA4", "#FFFFFF", "#F88F79", "#F7C8A8", "#5C5C5C"],
    paletteName: "Neutros Modernos",
    paletteDescription:
      "Esta paleta presenta tonos neutros de gris, blanco y beige, con un toque de rosa y naranja. Perfecta para un diseño de dormitorio moderno y elegante.",
  },
  {
    palette: ["#31394D", "#E8E8E8", "#D88A8A", "#ECA8A8", "#F6D1C1"],
    paletteName: "Clásico Moderno",
    paletteDescription:
      "Esta paleta presenta tonos clásicos de azul y blanco, con un toque de rosa y naranja. Perfecta para un diseño de dormitorio moderno y atemporal.",
  },
  {
    palette: ["#392F5A", "#8C4B7E", "#F2B2A7", "#F2E1C1", "#F2C57C"],
    paletteName: "Tonos Joya Modernos",
    paletteDescription:
      "Esta paleta presenta tonos joya profundos de púrpura y rosa, con un toque de amarillo y beige. Perfecta para un diseño de dormitorio moderno y glamoroso.",
  },
  {
    palette: ["#414042", "#E6E8E8", "#F2B885", "#F2D3B3", "#D94C4C"],
    paletteName: "Tonos Tierra y Rojo",
    paletteDescription:
      "Esta paleta presenta tonos terrosos de marrón y beige, con un toque de rojo intenso. Perfecta para un diseño de dormitorio moderno y acogedor.",
  },
  {
    palette: ["#212121", "#FFFFFF", "#F44336", "#607D8B", "#FFC107"],
    paletteName: "Minimalista Moderno",
    paletteDescription:
      "Esta paleta presenta tonos minimalistas de negro, blanco y gris, con un toque de amarillo brillante. Perfecta para un diseño de dormitorio moderno y elegante.",
  },
];
