export const esSlideshowPalettes = [
  {
    palette: ["#D2691E", "#8B4513", "#FFA07A", "#F4A460", "#DEB887"],
    paletteName: "Paleta Otoñal",
    paletteDescription:
      "Esta cálida paleta de colores incluye tonos tierra como el marrón, naranja y amarillo, perfecta para presentaciones relacionadas con temas de naturaleza, viajes o estilo de vida.",
  },

  {
    palette: ["#FFFFFF", "#F8F8F8", "#E8E8E8", "#DADADA", "#C2C2C2"],
    paletteName: "Paleta Minimalista",
    paletteDescription:
      "Esta paleta de colores neutros y suaves, compuesta principalmente de grises y blancos, es ideal para presentaciones con un enfoque minimalista y elegante, adecuada para temas de negocios o profesionales.",
  },

  {
    palette: ["#FF0000", "#FFD700", "#00FF00", "#1E90FF", "#FF00FF"],
    paletteName: "Paleta Vibrante",
    paletteDescription:
      "Esta paleta de colores intensos y brillantes, que incluye colores como el rojo, amarillo, verde, azul y rosa, es perfecta para presentaciones enérgicas y creativas, ideales para proyectos de diseño, arte o moda.",
  },

  {
    palette: ["#FFB74D", "#F06292", "#7986CB", "#4DB6AC", "#A1887F"],
    paletteName: "Paleta Playful",
    paletteDescription:
      "Esta paleta de colores divertidos y juveniles, con tonos como el naranja, rosa, morado, verde y marrón, es perfecta para presentaciones con un enfoque lúdico y creativo, adecuada para proyectos dirigidos a un público joven.",
  },

  {
    palette: ["#00796B", "#4DB6AC", "#009688", "#26A69A", "#00897B"],
    paletteName: "Paleta Profesional",
    paletteDescription:
      "Esta paleta de colores sofisticados y elegantes, compuesta principalmente de tonos verdes y turquesas, es ideal para presentaciones de negocios o corporativas, adecuada para proyectos que requieran un aspecto profesional y serio.",
  },

  {
    palette: ["#2196F3", "#03A9F4", "#00BCD4", "#009688", "#4CAF50"],
    paletteName: "Paleta Tecnológica",
    paletteDescription:
      "Esta paleta de colores frescos y modernos, que incluye tonos azules y verdes, es perfecta para presentaciones relacionadas con temas de tecnología, innovación o ciencia, adecuada para proyectos que busquen transmitir un enfoque vanguardista y tecnológico.",
  },

  {
    palette: ["#F44336", "#FFC107", "#FF9800", "#FF5722", "#8BC34A"],
    paletteName: "Paleta de Verano",
    paletteDescription:
      "Esta paleta de colores vibrantes y refrescantes, con tonos como el rojo, amarillo, naranja y verde, es perfecta para presentaciones con temáticas veraniegas, vacacionales o relacionadas con el aire libre, ideal para proyectos que busquen transmitir una sensación de alegría y diversión.",
  },

  {
    palette: ["#4A4E69", "#F8F1E5", "#D9BBA9", "#B8977E", "#8F755D"],
    paletteName: "Paleta Elegante",
    paletteDescription:
      "Esta paleta de colores refinados y sofisticados, compuesta principalmente de tonos neutros como el gris, beige y marrón claro, es perfecta para presentaciones con un enfoque elegante y clásico, adecuada para proyectos que requieran un aspecto refinado y sobrio.",
  },

  {
    palette: ["#FFCDD2", "#E1BEE7", "#D1C4E9", "#B3E5FC", "#C8E6C9"],
    paletteName: "Paleta Pastel",
    paletteDescription:
      "Esta paleta de colores suaves y delicados, con tonos como el rosa, lila, azul claro y verde claro, es ideal para presentaciones con un estilo dulce y romántico, adecuada para proyectos relacionados con moda, belleza o estilo de vida.",
  },

  {
    palette: ["#FF5722", "#E91E63", "#9C27B0", "#3F51B5", "#03A9F4"],
    paletteName: "Paleta Creativa",
    paletteDescription:
      "Esta paleta de colores audaces y creativos, que incluye tonos como el naranja, rosa, morado, azul y turquesa, es perfecta para presentaciones con un enfoque creativo y artístico, ideal para proyectos de diseño, arte o cultura.",
  },

  {
    palette: ["#FF9800", "#FFEB3B", "#8BC34A", "#009688", "#00BCD4"],
    paletteName: "Paleta Retro",
    paletteDescription:
      "Esta paleta de colores nostálgicos y vintage, con tonos como el naranja, amarillo, verde y turquesa, es perfecta para presentaciones con un estilo retro y con inspiración en décadas pasadas, ideal para proyectos relacionados con moda, música o cultura pop.",
  },

  {
    palette: ["#212121", "#F9A825", "#F57F17", "#E53935", "#FFFFFF"],
    paletteName: "Paleta de Contraste",
    paletteDescription:
      "Esta paleta de colores de alto contraste, que combina tonos oscuros como el negro y gris con tonos brillantes como el amarillo y rojo, es perfecta para presentaciones que buscan un impacto visual fuerte y audaz, ideal para proyectos que quieran destacar y llamar la atención.",
  },

  {
    palette: ["#90A4AE", "#B0BEC5", "#CFD8DC", "#ECEFF1", "#FFFFFF"],
    paletteName: "Paleta Relajante",
    paletteDescription:
      "Esta paleta de colores suaves y relajantes, compuesta principalmente de tonos azules y grises, es perfecta para presentaciones con un enfoque tranquilo y sereno, adecuada para proyectos relacionados con bienestar, meditación o salud mental.",
  },

  {
    palette: ["#FFFFFF", "#F5F5F5", "#E0E0E0", "#BDBDBD", "#757575"],
    paletteName: "Paleta Minimalista",
    paletteDescription:
      "Esta paleta de colores minimalistas, compuesta principalmente de tonos blancos, grises y neutros, es perfecta para presentaciones con un estilo limpio y simplificado, ideal para proyectos que busquen transmitir una sensación de orden, claridad y minimalismo.",
  },

  {
    palette: ["#FF4081", "#FF9800", "#8BC34A", "#3F51B5", "#9C27B0"],
    paletteName: "Paleta Juvenil",
    paletteDescription:
      "Esta paleta de colores enérgicos y vibrantes, con tonos como el rosa, naranja, verde y morado, es perfecta para presentaciones con un estilo juvenil y dinámico, adecuada para proyectos relacionados con la juventud, música o cultura urbana.",
  },

  {
    palette: ["#00796B", "#009688", "#03A9F4", "#1976D2", "#3F51B5"],
    paletteName: "Paleta Profesional",
    paletteDescription:
      "Esta paleta de colores sobrios y profesionales, compuesta principalmente de tonos azules y verdes, es perfecta para presentaciones con un enfoque serio y corporativo, ideal para proyectos relacionados con negocios, finanzas o tecnología.",
  },
];
